/* eslint-disable import/prefer-default-export */

export const OFFER_ADDITIONAL_TERMS_CHANGED_FROM_HTML = 'OFFER_ADDITIONAL_TERMS_CHANGED_FROM_HTML';
export const UPDATE_OFFER = 'UPDATE_OFFER';
export const IS_SAVING_CHANGED = 'IS_SAVING_CHANGED';
export const IS_CREATING_TEMPLATE_CHANGED = 'IS_CREATING_TEMPLATE_CHANGED';
export const TEMPLATE_SAVED = 'TEMPLATE_SAVED';
export const TEMPLATE_UPDATED = 'TEMPLATE_UPDATED';
export const TEMPLATE_APPLIED = 'TEMPLATE_APPLIED';
export const TEMPLATE_REMOVED = 'TEMPLATE_REMOVED';

export const UPDATE_BUDGET = 'UPDATE_BUDGET';
export const UPDATE_CALCULATED = 'UPDATE_CALCULATED';
export const UPDATE_MODAL_UI_STATE = 'UPDATE_MODAL_UI_STATE';

export const ADD_TICKET_LINE_ITEM = 'ADD_TICKET_LINE_ITEM';
export const ADD_INCOME_LINE_ITEM = 'ADD_INCOME_LINE_ITEM';
export const ADD_BEFORE_ADJUSTED_DEDUCTIONS_LINE_ITEM = 'ADD_BEFORE_ADJUSTED_DEDUCTIONS_LINE_ITEM';
export const ADD_EXPENSES_LINE_ITEM = 'ADD_EXPENSES_LINE_ITEM';
export const BUDGET_BONUS_ADDED = 'BUDGET_BONUS_ADDED';

export const BUDGET_TEMPLATE_NAME_CHANGED = 'BUDGET_TEMPLATE_NAME_CHANGED';
export const BUDGET_TEMPLATE_CHANGED = 'BUDGET_TEMPLATE_CHANGED';

export const OFFER_TO_LINK_CHANGED = 'OFFER_TO_LINK_CHANGED';

export const ATTACH_ARTIST_MODAL_OPEN_CHANGED = 'ATTACH_ARTIST_MODAL_OPEN_CHANGED';
