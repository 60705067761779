import React, { createContext, useState, useContext } from "react";
import { ToastContainer } from 'react-toastify';
import axios from "axios";

import CampaignIndex from "./CampaignIndex";
import { toastSuccess, toastError } from "../../../shared/toastHelper";
import SetupModal from "../MarketingSettings/SetupModal";

const qs = require("qs");

const CampaignsContext = createContext();

export function useCampaignsContext() {
  return useContext(CampaignsContext);
}

const CampaignsProvider = (props) => {
  const {
    csrfToken,
    user,
    team,
    averageOrderTotal,
    campaignTemplates,
    venueOwnerships,
    lastTouchedVenueOwnership,
    sendDomains,
  } = props;

  const [venueOwnership, setVenueOwnership] = useState(lastTouchedVenueOwnership);
  const [activeCampaigns, setActiveCampaigns] = useState([]);
  const [availableCampaignTemplates, setAvailableCampaignTemplates] = useState(campaignTemplates);
  const [isLoading, setIsLoading] = useState(true);

  const fetchCampaigns = (venueOwnershipId) => {
    const queryString = qs.stringify({
      q: {
        venue_ownership_id_eq: venueOwnershipId,
      }
    });

    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.get("/teams/" + team.id + "/campaigns.json?" + queryString)
      .then(({data}) => {
        const activeCampaigns = data.filter(campaign => ["active", "paused"].includes(campaign.state));
        const availableCampaigns = data.filter(campaign => ["available"].includes(campaign.state));

        setActiveCampaigns(activeCampaigns);
        setAvailableCampaignTemplates(campaignTemplates
            .filter(campaignTemplate => !activeCampaigns.find(campaign => campaign.campaign_template_id === campaignTemplate.id))
            .map(campaignTemplate => {
              const campaign = availableCampaigns.find(campaign => campaign.campaign_template_id === campaignTemplate.id);
              return {
                ...campaignTemplate,
                campaign: campaign,
              }
            })
        );
        setIsLoading(false);
      })
      .catch(_error => {
        toastError("Could not load active campaigns, please try again")
        setIsLoading(false);
      });
  };

  const updateCampaign = (campaignId, data) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.put("/teams/" + team.id + "/campaigns/" + campaignId, data)
      .then(({data}) => {
        setActiveCampaigns(current => {
          return current.map(campaign => {
            if (campaign.id === campaignId) {
              return data;
            }

            return campaign;
          });
        });
        toastSuccess("Campaign has been updated");
      })
      .catch(_error => toastError("Could not update campaign, please try again"));
  };

  const value = {
    csrfToken,
    user,
    team,
    averageOrderTotal,
    campaignTemplates,
    fetchCampaigns,
    updateCampaign,
    venueOwnerships,
    venueOwnership,
    setVenueOwnership,
    isLoading,
    setIsLoading,
    activeCampaigns,
    availableCampaignTemplates,
  };

  return (
    <CampaignsContext.Provider value={value}>
      <>
        <SetupModal
          venueOwnership={venueOwnership}
          sendDomains={sendDomains}
        />
        {props.children}
      </>
    </CampaignsContext.Provider>
  );
};

const CampaignsContainer = (props) => (
  <CampaignsProvider {...props}>
    <ToastContainer />
    <CampaignIndex />
  </CampaignsProvider>
);

export default CampaignsContainer;
