import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import axios from 'axios';
import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';
import Flatpickr from "react-flatpickr";
import Tippy from '@tippyjs/react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import Popup from "reactjs-popup";

import {
  offsetDateForBrowser
} from '../../../shared/timeZoneLogic';

const attachmentPopupStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "maxWidth": "828px",
  "padding": "8px"
}

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const financeItemTotal = (financeItem) => {
  const amount = financeItem
    .finance_line_items
    .filter(lineItem => !lineItem._destroy)
    .map(lineItem => parseFloat(lineItem.actual))
    .reduce((a, b) => a + b, 0);

  return USDollar.format(amount);
}

const financeItemTotalEstimate = (financeItem, ticketForecast) => {
  const fixed_total = financeItem
    .finance_line_items
    .filter(lineItem => { return !lineItem._destroy && lineItem.calc_type === "Fixed" })
    .map(lineItem => parseFloat(lineItem.estimate))
    .reduce((a, b) => a + b, 0);

  const per_ticket_sold_total = financeItem
    .finance_line_items
    .filter(lineItem => { return !lineItem._destroy && lineItem.calc_type === "Per Ticket Sold" })
    .map(lineItem => parseFloat(lineItem.amount) * (ticketForecast || 0))
    .reduce((a, b) => a + b, 0);

  const percentage_of_gross_total = financeItem
    .finance_line_items
    .filter(lineItem => { return !lineItem._destroy && lineItem.calc_type === "Percentage of Gross" })
    .map(lineItem => (parseFloat(lineItem.amount) / 100) * lineItem.income_gross_potential)
    .reduce((a, b) => a + b, 0);

  return USDollar.format(fixed_total + per_ticket_sold_total + percentage_of_gross_total);
}

const parseDateTime = (dateTime) => {
  if (dateTime){
    if (typeof(dateTime) === "string") {
      var dateObj = new Date(dateTime);
      return dateObj;
    } else {
      return dateTime;
    }
  }
}

const shouldDiscardLineItem = (lineItem) => {
  return (
    (lineItem.category_id === null || lineItem.category_id === "")
    && (lineItem.description === null || lineItem.description === "")
    && (lineItem.amount === null || lineItem.amount === "" || lineItem.amount === "0.00")
    && (lineItem.estimate === null || lineItem.estimate === "" || lineItem.estimate === "0.00")
    && (lineItem.actual === null || lineItem.actual === "" || lineItem.actual === "0.00")
  );
};

const canSubmitForm = (financeItem, financeItemForFormChanged) => {
  var errors = [];

  financeItem.finance_line_items.forEach((lineItem, index) => {
    if(shouldDiscardLineItem(lineItem)) {
      return;
    }

    if(lineItem.category_id === null || lineItem.category_id === "") {
      errors.push(`lineItemCategory${index}`);
    }

    if(lineItem.description === null || lineItem.description === "") {
      errors.push(`lineItemDescription${index}`);
    }
  });

  financeItemForFormChanged({
    ...financeItem,
    errors: errors
  });

  return errors.length === 0;
}

const debouncedFetchFinanceCategories = _.debounce((team, inputValue, callback) => {
  new Promise(resolve => {
    axios.get(`/teams/${team.id}/finance_categories/autocomplete`, {
      params: {
        name: inputValue
      }
    })
    .then(function (response) {
      var newOptions = response.data;

      if(!newOptions.map(option => option.label).includes(inputValue)) {
        newOptions.unshift({
          value: inputValue,
          label: `Add "${inputValue}"`,
          name: inputValue
        })
      }

      callback(newOptions);
    })
  });
}, 250);

const createFinanceCategory = (team, category, successCallback, errorCallback) => {
  axios.post(`/teams/${team.id}/finance_categories`, {
    finance_category: {
      name: category
    }
  })
  .then(response => successCallback(response.data))
  .catch(errorCallback)
};

const ManageFinanceItemsForm = ({
  csrfToken,
  team,
  confirm,
  currentUser,
  financeItems,
  financeItemToEdit,
  financeItemToEditChanged,
  financeItemForForm,
  financeItemForFormChanged,
  financeCategories,
  financeCategoriesChanged,
  performers,
  createFinanceItem,
  updateFinanceItem,
  offers,
  offerChanged,
}) => {
  let addItemButton = useRef(null);
  let focusedRow = useRef(null);
  let focusedRowElement = useRef(null);
  let [focusedRowIndex, setFocusedRowIndex] = useState(null);

  useEffect(() => {
    if(focusedRowIndex !== null
      && focusedRow !== null
      && focusedRowElement !== null
      && focusedRow.current !== document.activeElement.closest('.line-item-row')
    ) {
      focusedRowElement.current.focus();
    }
  }, [focusedRowIndex]);

  return (
    <div className="mx-4 mt-2 p-3" style={{"background": "#f3f9fc"}}>
      <div className="d-flex justify-content-between">
        <div className="">
          <i className="fal fa-receipt mr-2" style={{"fontSize": "18px"}}></i>
          <span className="strong" style={{"fontSize": "17px"}}>
            {financeItemForForm.is_income ? "Venue Income" : "Venue Expenses"}
          </span>
        </div>
        <div className="d-flex">
          <div className="text-right">
            <div className="text-muted uppercase-text" style={{"fontSize": "12px"}}>
              {financeItemForForm.is_income ? "Total Income" : "Total Expenses"}
            </div>
            <div className="strong" style={{"fontSize": "22px"}}>
              {financeItemForForm.is_income
                ? financeItemTotal(financeItemForForm)
                : `-${financeItemTotal(financeItemForForm)}`
              }
            </div>
          </div>
          <div className="ml-3">
            <a
              href="#"
              className="text-muted"
              onClick={e => {
                e.preventDefault();
                financeItemToEditChanged({});
                financeItemForFormChanged({});
              }}
            >
              <i className="fas fa-times" style={{"fontSize": "20px"}}></i>
            </a>
          </div>
        </div>
      </div>
      <div className="mt-2 d-flex justify-content-between">
        <div style={{"width":"33.33%"}}>
          <label className="mb-1 text-muted">
            Payment Date
          </label>
          <div
            className="flatpickr-date-input px-2 d-flex align-items-center"
            style={{"backgroundColor":"#fff", "borderColor":"#e6e6e6"}}
          >
            <Flatpickr
              options={{ dateFormat: "M j, Y" }}
              placeholder={"Select date"}
              value={parseDateTime(financeItemForForm.issued_on_midday) || offsetDateForBrowser(new Date())}
              onClose={dates => {
                financeItemForFormChanged({...financeItemForForm, issued_on_midday: dates[0] });
              }}
            />
            <div className="pl-2" style={{"fontSize": "18px"}}>
              <i className="far fa-calendar"></i>
            </div>
          </div>
        </div>
        <div className="pl-4" style={{"width":"33.33%"}}>
          <label className="mb-1 text-muted">
            Payment Method
          </label>
          <Select
            placeholder="Select payment method"
            styles={{
              control: (base, state) => ({
                ...base,
                width: "100%",
                boxShadow: "none"
              })
            }}
            options={[
              { value: "Credit Card", label: "Credit Card" },
              { value: "Checking Account", label: "Checking Account" },
              { value: "ACH", label: "ACH" },
              { value: "Cash", label: "Cash" },
              { value: "Other", label: "Other" }
            ]}
            value={financeItemForForm.payment_method ? {
              value: financeItemForForm.payment_method,
              label: financeItemForForm.payment_method
            } : null}
            onChange={option => {
              financeItemForFormChanged({
                ...financeItemForForm,
                payment_method: option.value
              });
            }}
          />
        </div>
        <div className="pl-4" style={{"width":"33.33%"}}>
          <label className="mb-1 text-muted">
            Payee
          </label>
          <input
            type="text"
            className="d-block w-100 rounded-sm pl-2"
            style={{"height":"38px", "border":"1px solid #e6e6e6"}}
            placeholder="Enter payee"
            value={financeItemForForm.payee || ""}
            onChange={e => {
              financeItemForFormChanged({...financeItemForForm, payee: e.target.value });
            }}
          />
        </div>
      </div>
      <div className="mt-3 d-flex justify-content-between">
        <div style={{"width":"33.33%"}}>
          <label className="mb-1 text-muted">
            {financeItemForForm.is_income ? "Assign income to" : "Assign expense to"}
          </label>
          <Select
            isClearable
            placeholder="Select performer"
            styles={{
              control: (base, state) => ({
                ...base,
                boxShadow: "none"
              })
            }}
            options={confirm.performers.map(performer => {
              return { value: performer.id, label: performer.artistable.name };
            })}
            value={
              financeItemForForm.performer_id
                ? {
                    value: financeItemForForm.performer_id,
                    label: confirm.performers.find(performer => {
                      return performer.id === financeItemForForm.performer_id
                    }).artistable.name
                  }
                : null
            }
            onChange={option => {
              var updatedPerformer = option !== null ? option.value : null;
              financeItemForFormChanged({
                ...financeItemForForm,
                performer_id: updatedPerformer
              });
            }}
          />
        </div>
        <div className="pl-4 d-flex align-items-end" style={{"width":"33.33%"}}>
          <label className="m-0" style={{"fontSize":"14px"}}>
            <input
              type="checkbox"
              className="mr-2 rounded-sm"
              style={{
                "width": "20px",
                "height": "20px",
                "marginBottom": "5px",
                "verticalAlign": "top"
              }}
              checked={financeItemForForm.on_settlement}
              onChange={e => {
                financeItemForFormChanged({...financeItemForForm, on_settlement: e.target.checked });
              }}
            />
            Add to settlement
          </label>
          <Tippy content={
            financeItemForForm.is_income ? (
              <center>
                This income will appear as
                <br />
                an artist expense on the
                <br />
                settlement
              </center>
            ) : (
              <center>
                This expense will appear as
                <br />
                artist income on the
                <br />
                settlement
              </center>
            )
          }>
            <i
              className="ml-2 fas fa-circle-info text-muted"
              style={{"fontSize": "14px", "marginBottom": "6px"}}
            ></i>
          </Tippy>
        </div>
        <div className="pl-4 mt-auto" style={{"width":"33.33%"}}>
          {!financeItemForForm.is_income && financeItemForForm.on_settlement ? (
            <Select
              placeholder="Make a selection..."
              styles={{
                control: (base, state) => ({
                  ...base,
                  width: "100%",
                  boxShadow: "none"
                })
              }}
              options={[
                { value: "Show Expense", label: "Show Expense" },
                { value: "Show Expense + Buyout", label: "Show Expense + Buyout" },
                { value: "Deduction", label: "Deduction" }
              ]}
              value={{
                value: financeItemForForm.expense_type,
                label: financeItemForForm.expense_type
              }}
              onChange={option => {
                financeItemForFormChanged({
                  ...financeItemForForm,
                  expense_type: option.value
                });
              }}
            />
          ) : null}
        </div>
      </div>
      <hr className="my-4" style={{"borderTop": "1px solid #dfdfdf"}}/>
      <h6 className="mb-4 strong" style={{"fontSize":"14px"}}>
        {financeItemForForm.is_income ? "Itemized income" : "Itemized expenses"}
      </h6>
      <div>
        <div className="d-flex justify-content-between small text-muted text-uppercase">
          <div className="d-flex flex-grow-1" style={{"margin":"0 26px 0 0","borderBottom":"1px solid #e6e6e6"}}>
            <div style={{"width":"18.66%", "paddingLeft":"10px"}} className="flex-grow-1">Type</div>
            <div style={{"width":"24.00%", "paddingLeft":"10px"}} className="flex-grow-1">Category*</div>
            <div style={{"width":"24.00%", "paddingLeft":"10px"}} className="flex-grow-1">Description*</div>
            <div style={{"width":"11.11%", "paddingLeft":"10px"}} className="flex-grow-1">Amount</div>
            <div style={{"width":"11.11%", "paddingLeft":"10px"}} className="flex-grow-1">Forecast</div>
            <div style={{"width":"11.11%", "paddingLeft":"10px"}} className="flex-grow-1">Actual</div>
          </div>
        </div>
        {financeItemForForm.finance_line_items.filter(lineItem => !lineItem._destroy).map((lineItem, i) => (
          <div
            key={lineItem.id || `new-${i}`}
            ref={focusedRowIndex === i ? focusedRow : null}
            className="line-item-row d-flex align-items-center justify-content-between"
            onFocus={() => setFocusedRowIndex(i)}
            onKeyPress={e => {
              if (e.key === "Enter") {
                e.preventDefault();

                setFocusedRowIndex(i + 1);
                if(financeItemForForm.finance_line_items.filter(lineItem => !lineItem._destroy).length === i + 1) {
                  addItemButton.current.click();
                }
              }
            }}
          >
            <div className="text-center" style={{"width":"0px"}}>
              <i className="far fa-grip-dots-vertical text-muted" style={{"fontSize":"18px"}}></i>
            </div>
            <div
              className="d-flex flex-grow-1"
              style={{"height":"38px", "backgroundColor":"#fff", "border":"1px solid #e6e6e6", "borderTop":"none"}}
            >
              <div className="h-100 flex-grow-1" style={{"width":"18.66%", "borderRight":"1px solid #e6e6e6"}}>
                <Select
                  ref={focusedRowIndex === i ? focusedRowElement : null}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      boxShadow: "none",
                      minHeight: "37px",
                      maxHeight: "37px",
                      border: `2px solid ${state.isFocused ? "#015fcc" : "white"}`,
                      borderRadius: "3px"
                    })
                  }}
                  value={lineItem.calc_type ? {value: lineItem.calc_type, label: lineItem.calc_type} : null}
                  options={financeItemForForm.is_income ? [
                    { label: "Fixed", value: "Fixed" },
                    { label: "Per Ticket Sold", value: "Per Ticket Sold" },
                  ] : [
                    { label: "Fixed", value: "Fixed" },
                    { label: "Per Ticket Sold", value: "Per Ticket Sold" },
                    { label: "Percentage of Gross", value: "Percentage of Gross" }
                  ]}
                  onChange={e => {
                    financeItemForFormChanged({
                      ...financeItemForForm,
                      finance_line_items: financeItemForForm.finance_line_items.map(item => {
                        if (item.id === lineItem.id) {
                          return {
                            ...item,
                            calc_type: e.value,
                            amount: 0,
                            amount_two_decimals: "0.00",
                            estimate: 0,
                            estimate_two_decimals: "0.00",
                            actual: 0,
                            actual_two_decimals: "0.00",
                          };
                        } else {
                          return item;
                        }
                      })
                    });
                  }}
                />
              </div>
              <div className="h-100 flex-grow-1" style={{"width":"24%", "borderRight":"1px solid #e6e6e6"}}>
                <AsyncSelect
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      boxShadow: "none",
                      minHeight: "37px",
                      maxHeight: "37px",
                      borderRadius: "3px",
                      border: `2px solid ${
                        state.isFocused
                          ? "#015fcc"
                          : financeItemForForm.errors?.includes(`lineItemCategory${i}`)
                          ? "#FF595E"
                          : "white"
                      }`
                    })
                  }}
                  placeholder="Select or enter category"
                  value={lineItem.category_id ? {value: lineItem.category_id, label: lineItem.category.name} : null}
                  defaultOptions={financeCategories.map((category) => { return { label: category.name, value: category.id }})}
                  loadOptions={(inputValue, callback) => debouncedFetchFinanceCategories(team, inputValue, callback)}
                  onChange={e => {
                    if(e === null) {
                      financeItemForFormChanged({
                        ...financeItemForForm,
                        errors: financeItemForForm.errors?.filter(error => error !== `lineItemCategory${i}`),
                        finance_line_items: financeItemForForm.finance_line_items.map(item => {
                          if (item.id === lineItem.id) {
                            return {...item, category_id: null};
                          } else {
                            return item;
                          }
                        })
                      });
                    } else if(financeCategories.map(category => category.name).includes(e.label)) {
                      financeItemForFormChanged({
                        ...financeItemForForm,
                        errors: financeItemForForm.errors?.filter(error => error !== `lineItemCategory${i}`),
                        finance_line_items: financeItemForForm.finance_line_items.map(item => {
                          if (item.id === lineItem.id) {
                            return {...item, category_id: e.value, category: {id: e.value, name: e.label}};
                          } else {
                            return item;
                          }
                        })
                      });
                    } else {
                      createFinanceCategory(team, e.name,
                        (category) => { // Success callback
                          financeCategoriesChanged([...financeCategories, category]);

                          financeItemForFormChanged({
                            ...financeItemForForm,
                            errors: financeItemForForm.errors?.filter(error => error !== `lineItemCategory${i}`),
                            finance_line_items: financeItemForForm.finance_line_items.map(item => {
                              if (item.id === lineItem.id) {
                                return {...item, category_id: category.id, category: category};
                              } else {
                                return item;
                              }
                            })
                          });

                          toast.success(`Category "${category.name}" was created`, {
                            position: toast.POSITION.TOP_CENTER,
                            draggable: false,
                            closeOnClick: false,
                            autoClose: 5000,
                            hideProgressBar: true
                          });
                        },
                        () => { // Error callback
                          financeItemForFormChanged({
                            ...financeItemForForm,
                            finance_line_items: financeItemForForm.finance_line_items.map(item => {
                              if (item.id === lineItem.id) {
                                return {...item, category_id: null, category: null};
                              } else {
                                return item;
                              }
                            })
                          });

                          toast.error(`Could not create category "${e.name}"`, {
                            position: toast.POSITION.TOP_CENTER,
                            draggable: false,
                            closeOnClick: false,
                            autoClose: 5000,
                            hideProgressBar: true
                          });
                        }
                      );
                    }
                  }}
                />
              </div>
              <div className="h-100 flex-grow-1" style={{"width":"24%", "borderRight":"1px solid #e6e6e6"}}>
                <input
                  type="text"
                  className="px-2 d-block w-100 h-100"
                  style={{
                    borderRadius: "3px",
                    border: `2px solid ${financeItemForForm.errors?.includes(`lineItemDescription${i}`) ? "#FF595E" : "white"}`
                  }}
                  value={lineItem.description || ""}
                  placeholder="Enter description"
                  onChange={e => {
                    financeItemForFormChanged({
                      ...financeItemForForm,
                      errors: financeItemForForm.errors?.filter(error => error !== `lineItemDescription${i}`),
                      finance_line_items: financeItemForForm.finance_line_items.map(item => {
                        if (item.id === lineItem.id) {
                          return {...item, description: e.target.value};
                        } else {
                          return item;
                        }
                      })
                    });
                  }}
                />
              </div>
              <div
                className="h-100 flex-grow-1 d-flex align-items-center"
                style={{
                  "width":"11.11%",
                  "borderRight":"1px solid #e6e6e6",
                  "backgroundColor": lineItem.calc_type !== "Fixed" ? "initial" : "#F0F0F0",
                }}
              >
                {lineItem.calc_type === "Percentage of Gross" ? (
                  <i className="mx-2 fas fa-percent text-muted" style={{"fontSize":"18px"}}></i>
                ) : (
                  <i className="mx-2 fas fa-dollar text-muted" style={{"fontSize":"18px"}}></i>
                )}
                <CurrencyInput
                  className={`pr-2 d-block w-100 h-100 text-right ${lineItem.calc_type === "Fixed" ? "text-muted" : ""}`}
                  style={{ "border":"none" }}
                  placeholder={"0.00"}
                  maskOptions={{
                    prefix: "",
                    integerLimit: null,
                    allowDecimal: true,
                    decimalLimit: lineItem.calc_type === "Percentage of Gross" ? 5 : 2
                  }}
                  value={lineItem.amount || "0.00"}
                  disabled={lineItem.calc_type === "Fixed"}
                  onChange={e => {
                    financeItemForFormChanged({
                      ...financeItemForForm,
                      finance_line_items: financeItemForForm.finance_line_items.map(item => {
                        if (item.id === lineItem.id) {
                          var newValue = e.target.value === '' ? "0.00" : e.target.value;
                          return { ...item, amount: newValue.replace(',', ''), amount_two_decimals: e.target.value };
                        } else {
                          return item;
                        }
                      })
                    });
                  }}
                  onFocus={(e) => {
                    e.target.value === "0.00" && (e.target.value = "");
                  }}
                />
              </div>
              <div
                className="h-100 flex-grow-1 d-flex align-items-center"
                style={{
                  "width":"11.11%",
                  "borderRight":"1px solid #e6e6e6",
                  "backgroundColor": lineItem.calc_type === "Fixed" ? "initial" : "#F0F0F0",
                }}
              >
                <i className="mx-2 fas fa-dollar text-muted" style={{"fontSize":"18px"}}></i>
                <CurrencyInput
                  className={`pr-2 d-block w-100 h-100 text-right ${lineItem.calc_type !== "Fixed" ? "text-muted" : ""}`}
                  style={{ "border":"none" }}
                  placeholder="0.00"
                  maskOptions={{prefix: "", integerLimit: null}}
                  value={lineItem.calc_type === "Fixed" ? (
                    lineItem.estimate_two_decimals
                  ) : (
                    lineItem.calc_type === "Per Ticket Sold" ? (
                      (lineItem.amount * (confirm.ticket_forecast || 0))
                        .toLocaleString('en-US', {minimumFractionDigits: 2})
                    ) : (
                      ((lineItem.amount / 100) * lineItem.income_gross_potential)
                        .toLocaleString('en-US', {minimumFractionDigits: 2})
                    )
                  )}
                  onFocus={(e) => {
                    e.target.value === "0.00" && (e.target.value = "");
                  }}
                  disabled={lineItem.calc_type !== "Fixed"}
                  onChange={e => {
                    financeItemForFormChanged({
                      ...financeItemForForm,
                      finance_line_items: financeItemForForm.finance_line_items.map(item => {
                        if (item.id === lineItem.id) {
                          var newValue = e.target.value === '' ? "0.00" : e.target.value;
                          return { ...item, estimate: newValue.replace(',', ''), estimate_two_decimals: e.target.value };
                        } else {
                          return item;
                        }
                      })
                    });
                  }}
                />
              </div>
              <div className="h-100 flex-grow-1 d-flex align-items-center" style={{"width":"11.11%"}}>
                <i className="mx-2 fas fa-dollar text-muted" style={{"fontSize":"18px"}}></i>
                <CurrencyInput
                  className="pr-2 d-block w-100 h-100 text-right"
                  style={{ "border":"none" }}
                  placeholder="0.00"
                  maskOptions={{prefix: "", integerLimit: null}}
                  value={lineItem.actual_two_decimals}
                  onChange={e => {
                    financeItemForFormChanged({
                      ...financeItemForForm,
                      finance_line_items: financeItemForForm.finance_line_items.map(item => {
                        if (item.id === lineItem.id) {
                          var newValue = e.target.value === '' ? "0.00" : e.target.value;
                          return { ...item, actual: newValue.replace(',', ''), actual_two_decimals: e.target.value };
                        } else {
                          return item;
                        }
                      })
                    });
                  }}
                  onFocus={(e) => {
                    e.target.value === "0.00" && (e.target.value = "");
                  }}
                />
              </div>
            </div>
            <div className="text-right"  style={{"width":"26px"}}>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  financeItemForFormChanged({
                    ...financeItemForForm,
                    finance_line_items: financeItemForForm.finance_line_items.map(item => {
                      if(item.id === lineItem.id) {
                        return {...item, _destroy: true};
                      } else {
                        return item;
                      }
                    })
                  });
                }}
              >
                <i className="far fa-trash text-muted" style={{"fontSize":"18px"}}></i>
              </a>
            </div>
          </div>
        ))}
        <div
          className="mt-3 d-flex justify-content-between"
          style={{"margin":"0 26px 0 0px"}}
        >
          <div className="d-flex flex-grow-1">
            <div style={{"width":"40%"}} className="flex-grow-1 d-flex align-items-center text-primary strong">
              <a
                href="#"
                ref={addItemButton}
                onClick={e => {
                  e.preventDefault();
                  financeItemForFormChanged({
                    ...financeItemForForm,
                    finance_line_items: [
                      ...financeItemForForm.finance_line_items,
                      {
                        id: `new-${financeItemForForm.finance_line_items.length}`,
                        category_id: "",
                        description: "",
                        calc_type: "Fixed",
                        amount: "0.00",
                        estimate: "0.00",
                        actual: "0.00"
                      }
                    ]
                  });
                }}
              >
                <i className="fas fa-plus-circle mr-2" style={{"fontSize":"18px"}}></i>
                <span>Add Item</span>
              </a>
            </div>
            <div style={{"width":"40%", "fontSize":"15px"}} className="flex-grow-1 text-right font-weight-bold">
              <span className="pr-2">Total</span>
            </div>
            <div style={{"width":"11.5%", "fontSize":"15px"}} className="flex-grow-1 d-flex justify-content-end font-weight-bold">
              <span className="pr-2">{financeItemTotalEstimate(financeItemForForm, confirm.ticket_forecast)}</span>
            </div>
            <div style={{"width":"11.5%", "fontSize":"15px"}} className="flex-grow-1 d-flex justify-content-end font-weight-bold">
              <span className="pr-2">{financeItemTotal(financeItemForForm)}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 d-flex align-items-start justify-content-between">
        <div className="d-flex align-items-start">
          <label className="mt-1 mb-0 mr-2 flex-shrink-0 text-primary strong" style={{"cursor":"pointer"}}>
            <input
              type="file"
              style={{"display":"none"}}
              onChange={e => {
                financeItemForFormChanged({
                  ...financeItemForForm,
                  file_attachments: [
                    ...financeItemForForm.file_attachments, {
                      attachment: {
                        team_id: team.id,
                        team_membership_id: currentUser.current_team_membership.id,
                        file: e.target.files[0],
                        file_file_name: e.target.files[0].name
                      }
                    }
                  ]
                });

                e.target.value = null;
              }}
            />
            <i className="fas fa-paperclip mr-2" style={{"fontSize":"18px"}}></i>
            <span style={{"fontSize":"14px"}}>Add Attachment</span>
          </label>
          <div className="d-flex flex-wrap">
            {financeItemForForm.file_attachments.filter(
              fileAttachment => fileAttachment._destroy !== true
            ).map((fileAttachment, attachmentIndex) => (
              <Popup
                key={`${attachmentIndex}-fileAttachment.attachment.file_file_name`}
                modal
                arrow={false}
                position="center center"
                contentStyle={attachmentPopupStyle}
                onOpen={e => { e.preventDefault(); }}
                trigger={open => (
                  <button
                    key={fileAttachment.attachment.file_file_name}
                    className="btn btn-sm btn-outline-primary ml-2 mb-2 d-flex align-items-center text-left"
                    style={{"borderWidth":"1px", "boxShadow":"none"}}
                    onClick={e => {
                      e.preventDefault();

                    }}
                  >
                    <Tippy content={fileAttachment.attachment.file_file_name}>
                      <div style={{
                        "minWidth":"50px",
                        "maxWidth":"120px",
                        "overflow":"hidden",
                        "textOverflow":"ellipsis",
                        "whiteSpace":"nowrap"
                      }}>
                        {fileAttachment.attachment.file_file_name}
                      </div>
                    </Tippy>
                    <Tippy content="Remove attachment">
                      <i
                        className="far fa-circle-xmark ml-2"
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();

                          const updated = !fileAttachment.id ? (
                            financeItemForForm.file_attachments.filter(fa => fa.id != fileAttachment.id)
                          ) : (
                            financeItemForForm.file_attachments.map(fa => {
                              if(fa.id === fileAttachment.id) {
                                return {...fa, _destroy: true};
                              } else {
                                return fa;
                              }
                            })
                          );

                          financeItemForFormChanged({...financeItemForForm, file_attachments: updated});
                        }}
                      ></i>
                    </Tippy>
                  </button>
                )} >
                {close => (
                  <div style={{"padding":"10px"}}>
                    <div className="mb-4 d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <i className="fas fa-file-image mr-2 text-primary" style={{"fontSize":"22px"}}></i>
                        <span style={{"fontSize":"18px", "fontWeight":"bold"}}>
                          {fileAttachment.attachment.file_file_name}
                        </span>
                      </div>
                      <div>
                        {fileAttachment.id ? (
                          <>
                            <button
                              className="mr-3 btn font-weight-bold"
                              style={{"backgroundColor":"#f3f3f3"}}
                              onClick={e => {
                                e.preventDefault();

                                financeItemForFormChanged({
                                  ...financeItemForForm,
                                  file_attachments: financeItemForForm.file_attachments.map(fa => {
                                    if(fa.id === fileAttachment.id) {
                                      return {...fa, _destroy: true};
                                    } else {
                                      return fa;
                                    }
                                  })
                                });

                                close();
                              }} >
                              <i className="fas fa-trash" style={{"color":"#898989", "fontSize":"18px"}}></i>
                            </button>
                            <button
                              className="mr-3 btn font-weight-bold"
                              style={{"backgroundColor":"#f3f3f3"}}
                              onClick={e => {
                                e.preventDefault();
                                window.location = `/teams/${team.id}/attachments/${fileAttachment.attachment.token}`
                              }} >
                              <i className="fas fa-download" style={{"color":"#898989", "fontSize":"18px"}}></i>
                            </button>
                          </>
                        ) : null}
                        <button
                          className="px-0 btn font-weight-bold"
                          onClick={e => {
                            e.preventDefault();
                            close();
                          }} >
                          <i className="fas fa-times" style={{"color":"#898989", "fontSize":"18px"}}></i>
                        </button>
                      </div>
                    </div>
                    {fileAttachment.id ? (
                      <>
                        <span
                          className="font-weight-bold"
                          style={{"fontSize":"16px"}}
                        >
                          Details
                        </span>
                        <div className="mt-2 d-flex small">
                          <table style={{"marginRight":"30px"}}>
                            <colgroup>
                              <col style={{"width":"110px"}} />
                              <col style={{"width":"110px"}} />
                            </colgroup>
                            <tbody>
                              <tr>
                                <td className="p-0 text-muted">Uploaded by</td>
                                <td className="p-0" >{fileAttachment.attachment.team_membership?.name}</td>
                              </tr>
                              <tr>
                                <td className="p-0 text-muted">Created</td>
                                <td className="p-0">
                                  {fileAttachment.attachment.human_created_at}
                                </td>
                              </tr>
                              <tr>
                                <td className="p-0 text-muted">Modified</td>
                                <td className="p-0">
                                  {fileAttachment.attachment.human_file_updated_at}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table>
                            <colgroup>
                              <col style={{"width":"60px"}} />
                              <col style={{"width":"60px"}} />
                            </colgroup>
                            <tbody>
                              <tr>
                                <td className="p-0 text-muted">Type</td>
                                <td className="p-0">{fileAttachment.attachment.human_content_type}</td>
                              </tr>
                              <tr>
                                <td className="p-0 text-muted">Size</td>
                                <td className="p-0">{fileAttachment.attachment.human_size}</td>
                              </tr>
                              <tr>
                                <td className="p-0 text-muted">&nbsp;</td>
                                <td className="p-0">&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : null}
                    <div className="mt-3" style={{"height":"500px"}}>
                      <img
                        style={{"width":"100%", "height":"500px", "objectFit":"contain"}}
                        src={fileAttachment.id ? fileAttachment.attachment.file : URL.createObjectURL(fileAttachment.attachment.file)} />
                    </div>
                  </div>
                )}
              </Popup>
            ))}
          </div>
        </div>
        <div className="ml-2 d-flex align-self-end align-items-center flex-shrink-0">
          <a
            href="#"
            className="text-primary font-weight-bold"
            onClick={e => {
              e.preventDefault()
              financeItemToEditChanged({});
              financeItemForFormChanged({});
            }}
          >
            Cancel
          </a>
          <button
            type="button"
            className="ml-2 btn btn-sm btn-primary rounded-lg font-weight-bold"
            onClick={e => {
              e.preventDefault()

              if(!canSubmitForm(financeItemForForm, financeItemForFormChanged)){
                return false;
              }

              var financeItemWas = financeItems
                .find((fi) => fi.id === financeItemForForm.id);

              var shouldUpdateOffers = (
                financeItemForForm.on_settlement
                  || (
                    financeItemWas
                      && financeItemWas.on_settlement
                  )
              );

              var offersToUpdate = (shouldUpdateOffers ? [...offers] : []);
              offersToUpdate.forEach((o) => {
                var updated = Object.assign({}, o, {
                  total_due: undefined
                });

                offerChanged(updated);
              });

              const lineItemsToKeep = financeItemForForm.finance_line_items
                .filter(lineItem => !shouldDiscardLineItem(lineItem));

              const updatedFinanceItem = {
                ...financeItemForForm,
                finance_line_items: lineItemsToKeep
              };

              if(financeItemForForm.id){
                updateFinanceItem(csrfToken, team, confirm, updatedFinanceItem, offersToUpdate);
              } else {
                createFinanceItem(csrfToken, team, confirm, updatedFinanceItem, offersToUpdate);
              }
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

ManageFinanceItemsForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  financeItems: PropTypes.array,
  financeItemToEdit: PropTypes.object,
  financeItemToEditChanged: PropTypes.func.isRequired,
  financeItemForForm: PropTypes.object,
  financeItemForFormChanged: PropTypes.func.isRequired,
  financeCategories: PropTypes.array,
  financeCategoriesChanged: PropTypes.func.isRequired,
  performers: PropTypes.array,
  createFinanceItem: PropTypes.func.isRequired,
  updateFinanceItem: PropTypes.func.isRequired,
  offers: PropTypes.array,
  offerChanged: PropTypes.func.isRequired,
};

export default ManageFinanceItemsForm;
