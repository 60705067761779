import React, { useCallback } from "react";
import ReactDOM from "react-dom";

import { useBroadcastEditContext } from "./BroadcastEditContainer";
import ContainerlessTableViewApp from "../../TableView/startup/ContainerlessTableViewApp";

const containerStyle = {
  background: "white",
  boxShadow: "-2px 1px 5px 0 rgba(0,0,0,0.1)",
  display: "grid",
  width: "100%",
};

const EmailSentTab = () => {
  const {
    ahoyMessagesDataTableProps,
    setAhoyMessageId,
  } = useBroadcastEditContext();
  const rowCallback = useCallback((row, data) => {
    const mountLink = row.getElementsByClassName("timestamp-link")[0];
    const id = data.id;
    const timestamp = data.timestamp;

    ReactDOM.render(
      <a href="#" onClick={(e) => { e.preventDefault(); setAhoyMessageId(id); }}>
        {timestamp}
      </a>,
      mountLink
    );
  }, []);

  return (
    <div style={containerStyle}>
      <ContainerlessTableViewApp
        {...ahoyMessagesDataTableProps}
        rowCallback={rowCallback}
      />
    </div>
  )
};

export default EmailSentTab;
