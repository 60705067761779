import React from "react";

const sectionStyle = {
  height: "100%",
  padding: "25px 10px 5px 10px",
  width: "100%",
};

const containerStyle = {
  alignItems: "center",
  backgroundColor: "#F2F2F2",
  borderRadius: "5px",
  color: "#666",
  display: "flex",
  flexDirection: "column",
  fontSize: "12px",
  height: "100%",
  justifyContent: "center",
  padding: "10px",
  width: "100%",
};

const headerStyle = {
  fontWeight: "bold",
};

const NoCampaignData = () => {
  return (
    <div style={sectionStyle}>
      <div style={containerStyle}>
        <div style={headerStyle}>
          No Data Yet
        </div>
        <div>
          Your campaign will start tracking metrics soon.
        </div>
      </div>
    </div>
  );
};

export default NoCampaignData;
