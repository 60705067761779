import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

// https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
const youtubeIdFromUrl = (url) => {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return (match && match[7].length == 11) ? match[7] : false;
}

// https://stackoverflow.com/questions/13286785/get-video-id-from-vimeo-url/37695721#37695721
function vimeoIDFromUrl(url) {
  var vimeo_Reg = /(videos|video|channels|\.com)\/([\d]+)/;
  var match = url.match(vimeo_Reg);

  return (match && match[2] ? match[2] : false);
}

const RenderVideo = ({
  pageDetail
}) => (
  <div>
    {pageDetail.is_http_url && (pageDetail.is_youtube_url || pageDetail.is_vimeo_url) ? (
      <div style={{
             "position": "relative",
             "display": "block",
             "width": "100%",
             "padding": "0",
             "overflow": "hidden",
             "height": (pageDetail.is_vimeo_url ? "360px" : "430px")
           }}>
        {pageDetail.is_youtube_url ? (
          <iframe src={"https://www.youtube.com/embed/" + youtubeIdFromUrl(pageDetail.video_url)}
                  height={"430px"}
                  title={"YouTube video player"}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={true}
                  style={{
                    "position": "absolute",
                    "top": "0",
                    "bottom": "0",
                    "left": "0",
                    "width": "100%",
                    "height": "100%",
                    "border": "0"
                  }} />
        ) : null}
        {pageDetail.is_vimeo_url ? (
          <iframe src={"https://player.vimeo.com/video/" + vimeoIDFromUrl(pageDetail.video_url) + "?h=0d5bfa0d13&color=ffffff&byline=0"}
                  height={"360px"}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen={true}
                  style={{
                    "position": "absolute",
                    "top": "0",
                    "bottom": "0",
                    "left": "0",
                    "width": "100%",
                    "height": "100%",
                    "border": "0"
                  }} />
        ) : null}
      </div>
    ) : null}
  </div>
);

RenderVideo.propTypes = {
  pageDetail: PropTypes.object.isRequired
};

export default RenderVideo;
