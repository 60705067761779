import { combineReducers } from 'redux';
import {responsiveStateReducer} from 'redux-responsive'
import { DateUtils } from 'react-day-picker';
import {EditorState} from 'draft-js';
import { v4 as uuidv4 } from 'uuid';

import {
  offsetDateForBrowser
} from '../../../shared/timeZoneLogic';

import {
  VENUE_SELECTED_CHANGED,
  EVENTS_CHANGED,
  CALENDAR_EVENT_CONFLICTS_CHANGED,
  OPEN_CALENDAR_EVENT_CONFLICTS_MODAL,
  CLOSE_CALENDAR_EVENT_CONFLICTS_MODAL,
  USER_UPDATED,
  CALENDAR_EVENT_CONFLICTS_MERGE_CHECKBOX_CLICKED,
  TOGGLE_ON_SALE_ENABLED,
  TOGGLE_ANNOUNCED_ENABLED,
  TOGGLE_CONFIRMED_ENABLED,
  TOGGLE_HOLDS_ENABLED,
  TOGGLE_PAST_EVENTS_ENABLED,
  TOGGLE_CANCELLED_EVENTS_ENABLED,
  CALENDAR_REF_UPDATED,
  CALENDAR_START_DATE_UPDATED,
  MONTH_VIEW_CHANGED,
  DATA_TABLE_FILTERS_CHANGED,
  RELOAD_DATA_TABLE,
  DATA_TABLE_EL_CHANGED,
  SEARCH_TERM_CHANGED,
  CURRENT_TEAM_MEMBERSHIP_CHANGED,
  CHECK_AVAILS_IS_OPEN_CHANGED,
  CHECK_AVAILS_SETTINGS_CHANGED,
  CALENDAR_SYNC_IS_OPEN_CHANGED,
  CLASSIFICATION_TOTALS_CHANGED,
  CHECK_AVAILS_DATES_CHANGED,
  CHECK_AVAILS_CALENDAR_EVENTS_CHANGED,
  CHECK_AVAILS_LOADING_CHANGED
} from '../constants/calendarViewConstants';

import {
  EVENT_TO_EDIT_UPDATE_SUCCESS,
  EVENT_TO_EDIT_DELETED
} from '../../EventForm/constants/eventFormConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const user = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATED:
      return action.user;
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const venuesOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const venueSelected = (state = {}, action) => {
  switch (action.type) {
    case VENUE_SELECTED_CHANGED:
      if (action.selected === null){
        return {};
      }

      var foundVenue = action.venues.find((venue) => {
        return venue.id === action.selected.value;
      })

      return Object.assign({}, foundVenue);
    default:
      return state;
  }
};

const events = (state = [], action) => {
  switch (action.type) {
    case EVENTS_CHANGED:
      return action.events;
    case EVENT_TO_EDIT_DELETED:
      return state.filter((event) => {
        return event.id !== action.event.id;
      });
    default:
      return state;
  }
};

const calendarEventConflicts = (state = [], action) => {
  switch (action.type) {
    case CALENDAR_EVENT_CONFLICTS_CHANGED:
      return action.calendarEventConflicts;
    case CALENDAR_EVENT_CONFLICTS_MERGE_CHECKBOX_CLICKED:
      return [...state].map((calendarEventConflict) => {
        if(calendarEventConflict.id === action.calendarEventConflict.id){
          return Object.assign({}, calendarEventConflict, {shouldMerge: action.checked});
        } else {
          return calendarEventConflict;
        }
      })
    default:
      return state;
  }
};

const calendarEventConflictsModalOpen = (state = false, action) => {
  switch (action.type) {
    case OPEN_CALENDAR_EVENT_CONFLICTS_MODAL:
      return true;
    case CLOSE_CALENDAR_EVENT_CONFLICTS_MODAL:
      return false;
    default:
      return state;
  }
};

const onSaleEnabled = (state = true, action) => {
  switch (action.type) {
    case TOGGLE_ON_SALE_ENABLED:
      return state ? false : true;
    default:
      return state;
  }
};

const announcedEnabled = (state = true, action) => {
  switch (action.type) {
    case TOGGLE_ANNOUNCED_ENABLED:
      return state ? false : true;
    default:
      return state;
  }
};

const confirmedEnabled = (state = true, action) => {
  switch (action.type) {
    case TOGGLE_CONFIRMED_ENABLED:
      return state ? false : true;
    default:
      return state;
  }
};

const holdsEnabled = (state = true, action) => {
  switch (action.type) {
    case TOGGLE_HOLDS_ENABLED:
      return state ? false : true;
    default:
      return state;
  }
};

const pastEventsEnabled = (state = true, action) => {
  switch (action.type) {
    case TOGGLE_PAST_EVENTS_ENABLED:
      return state ? false : true;
    default:
      return state;
  }
};

const cancelledEventsEnabled = (state = true, action) => {
  switch (action.type) {
    case TOGGLE_CANCELLED_EVENTS_ENABLED:
      return state ? false : true;
    default:
      return state;
  }
};

const calendarRef = (state = {}, action) => {
  switch (action.type) {
    case CALENDAR_REF_UPDATED:
      return action.calendarRef;
    default:
      return state;
  }
};

const calendarStartDate = (state = new Date(), action) => {
  switch (action.type) {
    case CALENDAR_START_DATE_UPDATED:
      return action.date;
    default:
      return state;
  }
};

const monthView = (state = true, action) => {
  switch (action.type) {
    case SEARCH_TERM_CHANGED:
      return false;
    case MONTH_VIEW_CHANGED:
      return action.monthView;
    default:
      return state;
  }
};

const dataTableTitle = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableDataSource = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableOrder = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableColumns = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableEl = (state = {}, action) => {
  switch (action.type) {
    case DATA_TABLE_EL_CHANGED:
      return action.el;
    default:
      return state;
  }
};

const searchTerm = (state = "", action) => {
  switch (action.type) {
    case SEARCH_TERM_CHANGED:
      return action.term;
    case MONTH_VIEW_CHANGED:
      return "";
    default:
      return state;
  }
};

const dataTableFilters = (state = {}, action) => {
  switch (action.type) {
    case DATA_TABLE_FILTERS_CHANGED:
      return action.dataTableFilters;
    case TOGGLE_ON_SALE_ENABLED:
      return Object.assign({}, state, {show_on_sale: action.onSaleEnabled});
    case TOGGLE_ANNOUNCED_ENABLED:
      return Object.assign({}, state, {show_announced: action.announcedEnabled});
    case TOGGLE_CONFIRMED_ENABLED:
      return Object.assign({}, state, {show_confirmed: action.confirmedEnabled});
    case TOGGLE_HOLDS_ENABLED:
      return Object.assign({}, state, {show_holds: action.holdsEnabled});
    case TOGGLE_PAST_EVENTS_ENABLED:
      return Object.assign({}, state, {show_past: action.pastEventsEnabled});
    case TOGGLE_CANCELLED_EVENTS_ENABLED:
      return Object.assign({}, state, {show_cancelled: action.cancelledEventsEnabled});
    case RELOAD_DATA_TABLE:
      return Object.assign({}, state, {_changed: uuidv4()});
    default:
      return state;
  }
};

const currentTeamMembership = (state = {}, action) => {
  switch (action.type) {
    case CURRENT_TEAM_MEMBERSHIP_CHANGED:
      return action.currentTeamMembership;
    default:
      return state;
  }
};

const venueOwnerships = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const checkAvailsIsOpen = (state = false, action) => {
  switch (action.type) {
    case CHECK_AVAILS_IS_OPEN_CHANGED:
      return action.checkAvailsIsOpen;
    default:
      return state;
  }
};

var defaultCheckAvailsSettings = {
  noConfirms: true,
  noHolds: true,
  onDaysOfWeek: [0, 1, 2, 3, 4, 5, 6]
}

const checkAvailsSettings = (state = defaultCheckAvailsSettings, action) => {
  switch (action.type) {
    case CHECK_AVAILS_SETTINGS_CHANGED:
      return action.checkAvailsSettings;
    default:
      return state;
  }
};

const checkAvailsDates = (state = [], action) => {
  switch (action.type) {
    case CHECK_AVAILS_DATES_CHANGED:
      return action.checkAvailsDates;
    default:
      return state;
  }
};

const checkAvailsCalendarEvents = (state = null, action) => {
  switch (action.type) {
    case CHECK_AVAILS_CALENDAR_EVENTS_CHANGED:
      return action.checkAvailsCalendarEvents;
    default:
      return state;
  }
};

const checkAvailsLoading = (state = false, action) => {
  switch (action.type) {
    case CHECK_AVAILS_LOADING_CHANGED:
      return action.checkAvailsLoading;
    default:
      return state;
  }
};

const calendarSyncIsOpen = (state = false, action) => {
  switch (action.type) {
    case CALENDAR_SYNC_IS_OPEN_CHANGED:
      return action.calendarSyncIsOpen;
    default:
      return state;
  }
};

const classificationTotals = (state = {}, action) => {
  switch (action.type) {
    case CLASSIFICATION_TOTALS_CHANGED:
      return action.classificationTotals;
    default:
      return state;
  }
};

const calendarViewReducer = combineReducers({
  csrfToken,
  user,
  team,
  events,
  venuesOptions,
  venueSelected,
  browser: responsiveStateReducer,
  calendarEventConflicts,
  calendarEventConflictsModalOpen,
  onSaleEnabled,
  announcedEnabled,
  confirmedEnabled,
  holdsEnabled,
  pastEventsEnabled,
  cancelledEventsEnabled,
  calendarRef,
  calendarStartDate,
  monthView,
  dataTableTitle,
  dataTableDataSource,
  dataTableOrder,
  dataTableColumns,
  dataTableFilters,
  dataTableEl,
  searchTerm,
  currentTeamMembership,
  venueOwnerships,
  checkAvailsIsOpen,
  checkAvailsSettings,
  calendarSyncIsOpen,
  classificationTotals,
  checkAvailsDates,
  checkAvailsCalendarEvents,
  checkAvailsLoading
});

export default calendarViewReducer;
