import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import Tippy from '@tippyjs/react';

import AddAttendees from "./AddAttendees";
import Checkout from "./Checkout";
import TimeLimitReached from "./TimeLimitReached";
import EventDetailsApp from '../../EventDetails/startup/EventDetailsApp';

import {
  ADD_ATTENDEES_MENU_ITEM
} from '../constants/confirmAttendeesConstants';

const axios = require('axios').default;

const https = require('https');
const httpsAgent = new https.Agent({ keepAlive: true });

const ConfirmAttendees = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  confirmChanged,
  activeMenuItem,
  activeMenuItemChanged,
  ticketReservation,
  orderType,
  orderTypeChanged,
  ticketTypes,
  addOns,
  ticketTypesChanged,
  addOnsChanged,
  buildTicketReservation,
  createTicketReservation,
  updateTicketReservation,
  rootUrl,
  ticketReservationChanged,
  isPlacingOrder,
  isPlacingOrderChanged,
  checkoutTimePercentageRemaining,
  checkoutTimePercentageRemainingChanged,
  timeLimitReached,
  timeLimitReachedChanged,
  deleteTicketReservation,
  manualTypes,
  customFieldValues,
  customFieldValueChanged,
  promoters,
  buyers,
  seatsIOPublicKey,
  seatsIOChart,
  seatsIOChartChanged,
  seatsIOSelectedObjects,
  seatsIOSelectedObjectsChanged
}) => {
  const freeHoldTokens = () => {
    var orderItems = ticketTypes.filter((tt) =>
      tt.quantity && tt.quantity > 0
    );

    var addOnOrderItems = addOns.filter((ao) =>
      ao.quantity && ao.quantity > 0
    );

    var seatsioHoldTokens = [];

    if(orderItems.some((i) => i.seatsIOObjects && i.seatsIOObjects.length > 0)){
      seatsioHoldTokens = [...seatsioHoldTokens, seatsIOChart.holdToken];
    }

    var addOnHoldTokens = [
      ...new Set(
        addOnOrderItems
          .filter((item) => item.seatsIOObjects)
          .map((item) => item.seatsIOObjects)
          .flat()
          .map((object) => object.hold_token)
      )
    ];

    seatsioHoldTokens = [
      ...seatsioHoldTokens,
      ...addOnHoldTokens
    ];

    if (seatsioHoldTokens.length) {
      axios.get(`/confirms/${confirm.id}/ticket_reservations/expire_hold_tokens`, {
        params: {
          hold_tokens: seatsioHoldTokens
        },
        httpsAgent: httpsAgent
      })
      .catch(_error => {});
    }
  };

  useEffect(() => {
    window.onbeforeunload = function() {
      if(!isPlacingOrder) {
        freeHoldTokens();
        e.preventDefault();
        return "";
      }
    }
  }, [ticketTypes, addOns, isPlacingOrder]);

  return(<div>
    <ToastContainer />
    <div className="row m-0">
      <div className="col-12">
        <div className="page-title d-flex align-items-center">
          { confirm.name }
          {confirm.calendar_classification === "past" ? (
            <span className="badge badge-secondary mt-1 ml-3 p-2"
                  style={{"background": "#919191", "color": "white", "fontSize": "10px"}}>
              Past
            </span>
          ) : confirm.calendar_classification === "canceled" ? (
            <span className="badge badge-danger mt-1 ml-3 p-2"
                  style={{"fontSize": "10px"}}>
              Canceled
            </span>
          ) : confirm.calendar_classification === "on_sale" ? (
            <span className="badge badge-secondary mt-1 ml-3 p-2"
                  style={{"background": "#5f5383", "color": "white", "fontSize": "10px"}}>
              On Sale
            </span>
          ) : confirm.calendar_classification === "announced" ? (
            <span className="badge badge-secondary mt-1 ml-3 p-2"
                  style={{"background": "#98c648", "color": "white", "fontSize": "10px"}}>
              Announced
            </span>
          ) : (
            <span className="badge badge-secondary mt-1 ml-3 p-2"
                  style={{"background": "#4c81b9", "color": "white", "fontSize": "10px"}}>
              Confirmed
            </span>
          )}
        </div>
        <EventDetailsApp
          csrfToken={csrfToken}
          team={team}
          currentUser={currentUser}
          confirm={{...confirm}}
          confirmSaved={(savedConfirm) => {
            confirmChanged(savedConfirm);
          }}
          buyers={[...buyers]}
          promoters={promoters} />
      </div>
    </div>
    <div className="row mb-4 mx-0">
      <div className="col-12">
        <div className="card border-0 shadow-2 overflow-hidden"
             style={{
               "background": "#f4f9fc"
             }}>
          <div className="row"
               style={{"padding": "0 15px"}}>
            <div className="col-12 col-md-auto"
                 style={{
                   "padding": "22px 0px"
                 }}>
              <div style={{"padding": "0px 20px"}}>
                <h3 style={{"fontSize": "24px", "marginBottom": "28px"}}>
                  <strong>
                    Attendees
                  </strong>
                </h3>
              </div>
              <ul className="no-bullets p-0 ticketing-menu attendees-ticketing-menu"
                  style={{"fontSize": "14px"}}>
                <li className={"clickable rounded-0 mb-1 d-flex align-items-center " + (activeMenuItem === ADD_ATTENDEES_MENU_ITEM ? "active" : "")}
                    style={{"padding": "10px 20px"}}
                    onClick={
                      (e) => {
                        e.preventDefault();
                        activeMenuItemChanged(ADD_ATTENDEES_MENU_ITEM);
                      }
                    }>
                  <strong>Add Attendees</strong>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md ticketing-content"
                 style={{
                   "background": "white",
                   "padding": "35px 40px"
                 }}>
              {timeLimitReached ? (
                <TimeLimitReached team={team}
                                  csrfToken={csrfToken}
                                  confirm={confirm}
                                  timeLimitReachedChanged={timeLimitReachedChanged} />
              ) : !ticketReservation.id ? (
                <AddAttendees csrfToken={csrfToken}
                              currentUser={currentUser}
                              confirm={confirm}
                              orderType={orderType}
                              orderTypeChanged={orderTypeChanged}
                              ticketTypes={ticketTypes}
                              addOns={addOns}
                              ticketTypesChanged={ticketTypesChanged}
                              addOnsChanged={addOnsChanged}
                              buildTicketReservation={buildTicketReservation}
                              createTicketReservation={createTicketReservation}
                              ticketReservation={ticketReservation}
                              manualTypes={manualTypes}
                              seatsIOPublicKey={seatsIOPublicKey}
                              seatsIOChart={seatsIOChart}
                              seatsIOChartChanged={seatsIOChartChanged}
                              seatsIOSelectedObjects={seatsIOSelectedObjects}
                              seatsIOSelectedObjectsChanged={seatsIOSelectedObjectsChanged}
                              team={team} />
              ) : (
                <Checkout team={team}
                          csrfToken={csrfToken}
                          confirm={confirm}
                          ticketTypes={ticketTypes}
                          ticketReservation={ticketReservation}
                          ticketReservationChanged={ticketReservationChanged}
                          updateTicketReservation={updateTicketReservation}
                          rootUrl={rootUrl}
                          checkoutTimePercentageRemaining={checkoutTimePercentageRemaining}
                          checkoutTimePercentageRemainingChanged={checkoutTimePercentageRemainingChanged}
                          timeLimitReachedChanged={timeLimitReachedChanged}
                          deleteTicketReservation={deleteTicketReservation}
                          isPlacingOrder={isPlacingOrder}
                          isPlacingOrderChanged={isPlacingOrderChanged}
                          customFieldValues={customFieldValues}
                          customFieldValueChanged={customFieldValueChanged}
                          addOns={addOns} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>);
};

ConfirmAttendees.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  confirmChanged: PropTypes.func.isRequired,
  activeMenuItem: PropTypes.string,
  activeMenuItemChanged: PropTypes.func.isRequired,
  ticketReservation: PropTypes.object,
  orderType: PropTypes.string,
  orderTypeChanged: PropTypes.func.isRequired,
  ticketTypes: PropTypes.array,
  addOns: PropTypes.array,
  ticketTypesChanged: PropTypes.func.isRequired,
  addOnsChanged: PropTypes.func.isRequired,
  buildTicketReservation: PropTypes.func.isRequired,
  createTicketReservation: PropTypes.func.isRequired,
  updateTicketReservation: PropTypes.func.isRequired,
  rootUrl: PropTypes.string.isRequired,
  ticketReservationChanged: PropTypes.func.isRequired,
  isPlacingOrder: PropTypes.bool,
  isPlacingOrderChanged: PropTypes.func.isRequired,
  checkoutTimePercentageRemaining: PropTypes.number,
  checkoutTimePercentageRemainingChanged: PropTypes.func.isRequired,
  timeLimitReached: PropTypes.bool,
  timeLimitReachedChanged: PropTypes.func.isRequired,
  deleteTicketReservation: PropTypes.func.isRequired,
  manualTypes: PropTypes.array,
  customFieldValues: PropTypes.array,
  customFieldValueChanged: PropTypes.func.isRequired,
  buyers: PropTypes.array,
  promoters: PropTypes.array,
  seatsIOPublicKey: PropTypes.string.isRequired,
  seatsIOChart: PropTypes.object,
  seatsIOChartChanged: PropTypes.func.isRequired,
  seatsIOSelectedObjects: PropTypes.array,
  seatsIOSelectedObjectsChanged: PropTypes.func.isRequired
};

export default ConfirmAttendees;
