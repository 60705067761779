import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import ReviewBulkRefund from "./ReviewBulkRefund";

import Popup from "reactjs-popup";
import { ToastContainer } from 'react-toastify';
import DataTable from '../../DataTable/components/DataTable';
import Tippy from '@tippyjs/react';
import EventDetailsApp from '../../EventDetails/startup/EventDetailsApp';

import $ from 'jquery';
require('datatables.net-bs4');

const modalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "840px",
  "padding": "0px"
}

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const canSubmitForm = (refund, creatingRefund, buildingRefund, shouldRestrictRefund) => {
  return (
    refund
      && refund.order_ids.length > 0
      && !creatingRefund
      && !buildingRefund
      && !shouldRestrictRefund
  );
}

const shouldRestrictRefund = (refund, confirm, canBypassRefundRestrictions) => {
  if(canBypassRefundRestrictions){
    return false;
  }

  return (
    refund
      && (
        refund.amount >= 1000.00
          || confirm.calendar_classification === "past"
      )
  );
}

var renderReactOnDataTableDraw;
const BulkRefund = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  confirmChanged,
  dataTableEl,
  dataTableElChanged,
  refund,
  refundChanged,
  renderDataTableRows,
  renderDataTableHeader,
  allOrderIds,
  fetchAllOrderIds,
  buildBulkRefund,
  submittingRefund,
  confirmRefund,
  createBulkRefund,
  creatingRefund,
  buildingRefund,
  promoters,
  buyers,
  canBypassRefundRestrictions
}) => {
  // After FIRST render...
  useEffect(() => {
    fetchAllOrderIds(team, confirm);
  }, [])

  // After EVERY render...
  useEffect(() => {
    renderReactOnDataTableDraw = (settings) => {
      var tableEl = settings.nTable;

      renderDataTableRows(tableEl, refund, csrfToken, team, confirm, buildBulkRefund, refundChanged);
      renderDataTableHeader(tableEl, allOrderIds, refund, refundChanged, csrfToken, team, confirm, buildBulkRefund);
    }

    if(Object.keys(dataTableEl).length > 0){
      renderDataTableRows(dataTableEl, refund, csrfToken, team, confirm, buildBulkRefund, refundChanged);
      renderDataTableHeader(dataTableEl, allOrderIds, refund, refundChanged, csrfToken, team, confirm, buildBulkRefund);
    }
  });

  return (
    <div>
      <ToastContainer />
      <div className="row m-0">
        <div className="col-12">
          <div className="page-title d-flex align-items-center">
            { confirm.name }
            {confirm.calendar_classification === "past" ? (
              <span className="badge badge-secondary mt-1 ml-3 p-2"
                    style={{"background": "#919191", "color": "white", "fontSize": "10px"}}>
                Past
              </span>
            ) : confirm.calendar_classification === "canceled" ? (
              <span className="badge badge-danger mt-1 ml-3 p-2"
                    style={{"fontSize": "10px"}}>
                Canceled
              </span>
            ) : confirm.calendar_classification === "on_sale" ? (
              <span className="badge badge-secondary mt-1 ml-3 p-2"
                    style={{"background": "#5f5383", "color": "white", "fontSize": "10px"}}>
                On Sale
              </span>
            ) : (
              <span className="badge badge-secondary mt-1 ml-3 p-2"
                    style={{"background": "#4c81b9", "color": "white", "fontSize": "10px"}}>
                Confirmed
              </span>
            )}
          </div>
          <EventDetailsApp
            csrfToken={csrfToken}
            team={team}
            currentUser={currentUser}
            confirm={{...confirm}}
            confirmSaved={(savedConfirm) => {
              confirmChanged(savedConfirm);
            }}
            buyers={[...buyers]}
            promoters={promoters} />
        </div>
      </div>
      <form onSubmit={
              (e) => {
                e.preventDefault();

                if(!canSubmitForm(refund, creatingRefund, buildingRefund, shouldRestrictRefund(refund, confirm, canBypassRefundRestrictions))){
                  return false;
                }

                createBulkRefund(csrfToken, team, confirm, refund);
              }
            }>
        <div className="row mb-4 mx-0">
          <div className="col-12">
            <div className="card border-0 shadow-2 overflow-hidden">
              <div className="card-body"
                   style={{"padding": "32px"}}>
                {shouldRestrictRefund(refund, confirm, canBypassRefundRestrictions) ? (
                  <div className="row d-none d-md-flex">
                    <div className="col-12 text-right">
                      <p className='mb-0 text-danger'>
                        <i className="fas fa-exclamation-circle mr-2"></i>
                        Contact <a className='text-danger' href={"mailto:support@opendate.io"}>support@opendate.io</a> to process this refund.
                      </p>
                    </div>
                  </div>
                ) : null}
                <div className="row">
                  <div className="col">
                    <h3 className="mb-3"
                        style={{"fontSize": "24px"}}>
                      <strong>
                        Multiple Refunds
                      </strong>
                    </h3>
                    <p className="text-muted mb-0"
                       style={{"maxWidth": "732px"}}>
                      Issue refunds for orders sold through Stripe Payment Processing. Opendate will refund all fees. Your bank account will be debited for the amount refunded.
                    </p>
                  </div>
                  <div className="col-xs-auto d-none d-md-flex align-items-center"
                       style={{"paddingRight": "15px"}}>
                    <a style={{
                         "marginRight": "35px"
                       }}
                       href={"/teams/" + team.id + "/confirms/" + confirm.id + "/dashboard"}>
                      <strong>Cancel</strong>
                    </a>
                    <button type="submit"
                            disabled={!canSubmitForm(refund, creatingRefund, buildingRefund, shouldRestrictRefund(refund, confirm, canBypassRefundRestrictions))}
                            className="btn btn-primary">
                      {creatingRefund || buildingRefund ? (
                        <React.Fragment>
                          <img src="/uploading-loading.gif"
                               className="mr-1"
                               style={{width: "16px"}} />
                          <strong>Refund</strong>
                        </React.Fragment>
                      ) : (
                        <strong>Refund</strong>
                      )}
                    </button>
                  </div>
                </div>
                <div className='row'
                     style={{"marginTop": "40px"}}>
                  <div className="col-12 col-md-6 col-lg-4"
                       style={{"marginBottom": "22px"}}>
                    <p className="text-muted mb-0"
                       style={{"fontSize": "14px"}}>
                      Orders Selected
                    </p>
                    <p className="text-dark mb-0"
                       style={{
                         "fontSize": "34px",
                         "lineHeight": "40px"
                       }}>
                      <strong>{refund.order_ids.length.toLocaleString("en-US")}/{allOrderIds.length.toLocaleString("en-US")}</strong>
                    </p>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4"
                       style={{"marginBottom": "22px"}}>
                    <p className="text-muted mb-0"
                       style={{"fontSize": "14px"}}>
                       Amount to refund
                    </p>
                    <p className="text-dark mb-0"
                       style={{
                         "fontSize": "34px",
                         "lineHeight": "40px"
                       }}>
                      <strong>{formatter.format(refund.max_amount || 0.00)}</strong>
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-12">
                    <div className="confirm-dashboard-datatable">
                      <DataTable dataSource={"/teams/" + team.id + "/confirms/" + confirm.id + "/bulk_refunds/new.json"}
                                 onInitComplete={
                                   (settings, json) => {
                                     var tableEl = settings.oInstance[0];
                                     dataTableElChanged(tableEl);
                                   }
                                 }
                                 drawCallback={
                                   (settings) => {
                                     renderReactOnDataTableDraw(settings);
                                   }
                                 }
                                 colGroup={
                                   <colgroup>
                                     <col span={1} style={{"width": "5%"}} />
                                     <col span={1} style={{"width": "15%"}} />
                                     <col span={1} style={{"width": "20%"}} />
                                     <col span={1} style={{"width": "15%"}} />
                                     <col span={1} style={{"width": "15%"}} />
                                     <col span={1} style={{"width": "30%"}} />
                                   </colgroup>
                                 }
                                 columns={
                                   [
                                     {"label": "json", "data": "json", "visible": false},
                                     {"label": "", "data": "checkbox", "orderable": false, "className": "mount-bulk-refund-order-header-checkbox"},
                                     {"label": "Order Number", "data": "number", "orderable": false},
                                     {"label": "Buyer", "data": "buyer", "orderable": false},
                                     {"label": "QTY", "data": "qty", "orderable": false},
                                     {"label": "Price", "data": "price", "orderable": false},
                                     {"label": "Date", "data": "date", "orderable": false}
                                   ]
                                 } />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  {shouldRestrictRefund(refund, confirm, canBypassRefundRestrictions) ? (
                    <div className="col-12 d-block d-md-none text-right">
                      <p className='text-danger'>
                        <i className="fas fa-exclamation-circle mr-2"></i>
                        Contact <a className='text-danger' href={"mailto:support@opendate.io"}>support@opendate.io</a> to process this refund.
                      </p>
                    </div>
                  ) : null}
                  <div className="col-12 d-flex d-md-none align-items-center justify-content-end">
                    <a style={{
                         "marginRight": "35px"
                       }}
                       href={"/teams/" + team.id + "/confirms/" + confirm.id + "/dashboard"}>
                      <strong>Cancel</strong>
                    </a>
                    <button type="submit"
                            disabled={!canSubmitForm(refund, creatingRefund, buildingRefund, shouldRestrictRefund(refund, confirm, canBypassRefundRestrictions))}
                            className="btn btn-primary">
                      {creatingRefund || buildingRefund ? (
                        <React.Fragment>
                          <img src="/uploading-loading.gif"
                               className="mr-1"
                               style={{width: "16px"}} />
                          <strong>Refund</strong>
                        </React.Fragment>
                      ) : (
                        <strong>Refund</strong>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Popup
        open={refund && typeof(refund.id) === "number"}
        contentStyle={modalContentStyle}
        closeOnDocumentClick={false}
        className={"popup-modal"}
        onClose={
          () => {
            if(!refund.state || refund.state === "reviewing"){
              var updated = Object.assign({}, refund, {id: null});
              refundChanged(updated);
            } else {
              fetchAllOrderIds(team, confirm);

              if(Object.keys(dataTableEl).length > 0){
                $(dataTableEl).DataTable().ajax.reload();
              }

              refundChanged({order_ids: []});
            }
          }
        }
      >
        <div className="popup-modal-container">
          <ReviewBulkRefund csrfToken={csrfToken}
                            team={team}
                            currentUser={currentUser}
                            confirm={confirm}
                            refund={refund}
                            refundChanged={refundChanged}
                            submittingRefund={submittingRefund}
                            confirmRefund={confirmRefund} />
        </div>
      </Popup>
    </div>
  );
};

BulkRefund.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  confirmChanged: PropTypes.func.isRequired,
  dataTableEl: PropTypes.object,
  dataTableElChanged: PropTypes.func.isRequired,
  refund: PropTypes.object,
  refundChanged: PropTypes.func.isRequired,
  renderDataTableRows: PropTypes.func.isRequired,
  renderDataTableHeader: PropTypes.func.isRequired,
  allOrderIds: PropTypes.array,
  fetchAllOrderIds: PropTypes.func.isRequired,
  buildBulkRefund: PropTypes.func.isRequired,
  submittingRefund: PropTypes.bool,
  confirmRefund: PropTypes.func.isRequired,
  createBulkRefund: PropTypes.func.isRequired,
  creatingRefund: PropTypes.bool,
  buildingRefund: PropTypes.bool,
  buyers: PropTypes.array,
  promoters: PropTypes.array,
  canBypassRefundRestrictions: PropTypes.bool
};

export default BulkRefund;
