import React from "react";

import CampaignCardCharts from "./CampaignCardCharts";
import CampaignCardDropdown from "./CampaignCardDropdown";
import NoCampaignData from "./NoCampaignData";
import CardFooter from "./CardFooter";
import { useCampaignsContext } from "./CampaignsContainer";

const cardStyle = {
  boxShadow: "0 0 5px 0 rgba(0,0,0,0.1)",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
};

const cardTopStyle = {
  alignItems: "baseline",
  flex: 1,
  display: "flex",
  backgroundColor: "white",
  margin: "10x",
  padding: "15px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const headerStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  fontWeight: "700",
  height: "25px",
  justifyContent: "space-between",
  width: "100%",
};

const headerTitleStyle = {
  justifyContent: "flex-start",
  textWrap: "nowrap",
};

const headerIconStyle = {
  color: "#3B81C3",
  marginRight: "5px",
};

const descriptionStyle = {
  color: "#5C5C5C",
  fontSize: "10px",
  fontWeight: "lighter",
};

const ActiveCampaignCard = ({
  campaign,
}) => {
  const { averageOrderTotal } = useCampaignsContext();

  const campaignMetrics = campaign.campaign_metrics;

  const renderFooter = () => {
    if (campaignMetrics) {    
      const convertedCount = campaignMetrics.converted_count;
      const deliveredCount = campaignMetrics.delivered_count;
      const conversionRate = deliveredCount > 0 ? (convertedCount / deliveredCount * 100).toFixed(1) : 0;
    
      const estimatedRevenue = averageOrderTotal * convertedCount * 12;
      const tooltipCopy = `You have sent ${deliveredCount} messages and have a conversion rate of ${conversionRate}%. With your average order value of $${averageOrderTotal.toLocaleString(0)}, this results in $${estimatedRevenue} of additional ticket revenue per year.`;

      return (
        <CardFooter
          estimatedRevenue={estimatedRevenue}
          tooltipCopy={tooltipCopy}
        />
      );
    }
  };

  return (
    <div style={cardStyle}>
      <div style={cardTopStyle}>
        <div style={headerStyle}>
          <div style={headerTitleStyle}>
            <i className={`fa ${campaign.campaign_template.fa_icon}`} style={headerIconStyle} />
            {campaign.campaign_template.name}
          </div>
          <CampaignCardDropdown
            campaign={campaign}
          />
        </div>
        <div style={descriptionStyle}>
          {campaign.campaign_template.description}
        </div>
        {campaignMetrics ? (
          <CampaignCardCharts
            campaign={campaign}
          />
        ) : (
          <NoCampaignData />
        )}
      </div>
      {renderFooter()}
    </div>
  );
};

export default ActiveCampaignCard;
