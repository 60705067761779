import React from "react";
import Popup from "reactjs-popup";

import { useCampaignsContext } from "./CampaignsContainer";

const headerContainerStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: "7px",
  justifyContent: "flex-end",
};

const popupContentStyle = {
  border: "none",
  borderRadius: "8px",
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  padding: "0px",
  width: "fit-content",
};

const menuLineStyle = {
  display: "flex",
  flexDirection: "row",
  fontSize: "12px",
  fontWeight: "normal",
  margin: "5px",
  paddingRight: "10px",
};

const pausedBadgeStyle = {
  backgroundColor: "#FBE49B",
  borderRadius: "25px",
  color: "#CC9500",
  fontSize: "10px",
  padding: "2px 5px",
};

const menuOptionStyle = {
  width: "28px"
};

const CampaignCardDropdown = ({
  campaign,
}) => {
  const { updateCampaign } = useCampaignsContext();

  return (
    <div style={headerContainerStyle}>
      {campaign.state === "active" ? null : (
        <div style={pausedBadgeStyle}>
          Paused
        </div>
      )}
      <Popup arrow={false}
        offsetY={5}
        position="bottom right"
        contentStyle={popupContentStyle}
        onOpen={
          (e) => {
            e.stopPropagation();
            e.preventDefault();
          }
        }
        trigger={_open => (
          <a href="#"
            onClick={(e) => { e.preventDefault(); }}
            className="text-muted"
          >
            <i className="fas fa-ellipsis-v"></i>
          </a>
        )} >
        {close => (
          <div className='row text-left'>
            <div className="col-12">
              <ul className="list-group offer-inline-menu highlight-primary">
                {campaign.state === "active" ? (
                  <li className="list-group-item">
                    <a className="text-muted"
                      style={menuLineStyle}
                      href="#"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          close();
                          updateCampaign(campaign.id, { state: "paused" });
                        }
                      }
                    >
                      <span className="d-inline-block text-center" style={menuOptionStyle}>
                        <i className="fas fa-pause"></i>
                      </span>
                      Pause Campaign
                    </a>
                  </li>
                ) : (
                  <li className="list-group-item">
                    <a className="text-muted"
                      style={menuLineStyle}
                      href="#"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          close();
                          updateCampaign(campaign.id, { state: "active" });
                        }
                      }
                    >
                      <span className="d-inline-block text-center" style={menuOptionStyle}>
                        <i className="fas fa-play"></i>
                      </span>
                      Resume Campaign
                    </a>
                  </li>
                )}
                <li className="list-group-item">
                  <a className="text-muted"
                    style={menuLineStyle}
                    href="#"
                    onClick={
                      (e) => {
                        e.preventDefault();
                        close();
                        window.location.href = `/teams/${campaign.team_id}/campaigns/${campaign.id}`;
                      }
                    }
                  >
                    <span className="d-inline-block text-center" style={menuOptionStyle}>
                      <i className="fas fa-eye"></i>
                    </span>
                    Preview Campaign
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
};

export default CampaignCardDropdown;
