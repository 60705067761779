import PropTypes from 'prop-types';
import React, { useEffect, PureComponent } from 'react';

import Popup from "reactjs-popup";
const axios = require('axios').default;

const approx = require('approximate-number');

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  LabelList
} from 'recharts';

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const USDollarUnsigned = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  signDisplay: 'never',
});

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "205px",
  "padding": "0px"
}

const formatCurrency = function(value){
  var abs = Math.abs(value);

  if(value < 0){
    return ("(" + USDollar.format(abs) + ")");
  } else {
    return USDollar.format(abs);
  }
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const ticketsSoldPercent = (confirm, profitAndLoss) => {
  if (confirm.ticket_forecast === null || confirm.ticket_forecast === 0) {
    return 0;
  }

  return Math.round(
    100 * (profitAndLoss.ticketsSold / confirm.ticket_forecast)
  );
}

const capacityPercent = (confirm, profitAndLoss) => {
  if (confirm.venue_capacity === null || confirm.venue_capacity === 0) {
    return 0;
  }

  return Math.round(
    100 * (profitAndLoss.ticketsSold / confirm.venue_capacity)
  );
}

const incomeForecastPercent = (confirm, profitAndLoss) => {
  if(profitAndLoss.incomeForecastTotal === null || profitAndLoss.incomeForecastTotal === 0){
    return 0;
  }

  return Math.round(
    100 * (profitAndLoss.incomeTotal / profitAndLoss.incomeForecastTotal)
  );
}

const incomeGrossPotentialPercent = (confirm, profitAndLoss) => {
  if(profitAndLoss.incomeGrossPotential === null || profitAndLoss.incomeGrossPotential === 0){
    return 0;
  }

  return Math.round(
    100 * (profitAndLoss.incomeTotal / profitAndLoss.incomeGrossPotential)
  );
}

const expenseForecastPercent = (confirm, profitAndLoss) => {
  if(profitAndLoss.expenseForecastTotal === null || profitAndLoss.expenseForecastTotal === 0){
    return 0;
  }

  return Math.round(
    100 * (profitAndLoss.expenseTotal / profitAndLoss.expenseForecastTotal)
  );
}

const expenseGrossPotentialPercent = (confirm, profitAndLoss) => {
  if(profitAndLoss.expenseGrossPotential === null || profitAndLoss.expenseGrossPotential === 0){
    return 0;
  }

  return Math.round(
    100 * (profitAndLoss.expenseTotal / profitAndLoss.expenseGrossPotential)
  );
}

const domain = (profitAndLoss) => {
  var min = Math.min(
    Math.abs(profitAndLoss.expenseForecastTotal),
    -Math.abs(profitAndLoss.profitLoss * 2)
  )

  var max = Math.max(
    Math.abs(profitAndLoss.incomeForecastTotal),
    Math.abs(profitAndLoss.profitLoss * 2)
  )

  return [min, max];
}

var profitAndLossData = null;

const ProgressBarChart = ({
  title,
  amount,
  label,
  max,
  forecastDetail,
  potentialDetail,
}) => {
  return(
    <>
      <tr>
        <td colSpan="2" style={{"padding": "0px"}}>&nbsp;</td>
      </tr>
      <tr>
        <td colSpan="2" style={{"padding": "0px"}}>
          <strong>{title}</strong>
        </td>
      </tr>
      <tr>
        <td style={{"height": "20px", "padding": "0 10px 0 0"}}>
          <div style={{"minWidth": "50px", "height": "20px", "marginLeft": "-6px"}}>
            <ResponsiveContainer width="99%" height="100%">
              <BarChart
                layout="vertical"
                data={[{
                  "amount": amount,
                  "label": label,
                  "max": max
                }]}
              >
                <XAxis dataKey="max"
                       type="number"
                       hide={true}
                       domain={[0, max]} />
                <YAxis type="category" hide={true} />
                <defs>
                  <linearGradient id="barGradient" x1='0' y1='0' x2='100%' y2='0' spreadMethod='reflect'>
                    <stop offset="0" stopColor="#e0f0fa" />
                    <stop offset="1" stopColor="#1b81c4" />
                  </linearGradient>
                </defs>
                <Bar stackId="0"
                     background={{ fill: '#f2f2f2', radius: 8, strokeWidth: 1, stroke: '#eee' }}
                     dataKey="amount"
                     barSize={12}
                     radius={8}>
                  <Cell key={0} fill={`url(#barGradient)`} />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </td>
        <td className="text-right" style={{"padding": "0px"}}>
          <strong>
            {label}
          </strong>
        </td>
      </tr>
      <tr className="text-sm text-muted">
        <td colSpan="2" style={{"padding": "0px", "fontSize": "12px"}}>
          {forecastDetail}
        </td>
      </tr>
      <tr className="text-sm text-muted">
        <td colSpan="2" style={{"padding": "0px", "fontSize": "12px"}}>
          {potentialDetail}
        </td>
      </tr>
    </>
  );
}

const CustomizedLabel = ({ x, y, value, label1, label2, label3 }) => {
  const offset = value < 0 ? -50 : 0;

  return (
    <>
      <text x={x} y={y} position="top" dx={22} dy={offset + 15} fontSize={16} textAnchor="start" className={`profit-loss ${value < 0 ? "negative" : null}`}>
        {label1}
      </text>
      <text x={x} y={y} position="top" dx={22} dy={offset + 32} fontSize={12} textAnchor="start" className="breakeven">
        {label2}
      </text>
      <text x={x} y={y} position="top" dx={22} dy={offset + 45} fontSize={12} textAnchor="start" className="breakeven">
        {label3}
      </text>
    </>
  );
}

const ProfitAndLoss = ({
  csrfToken,
  team,
  confirm,
  currentUser,
  profitAndLoss,
  fetchProfitAndLoss,
}) => {
  useEffect(() => {
    fetchProfitAndLoss(csrfToken, team, confirm);
  }, []);

  return (
    <div className="card no-border shadow-2">
      <div className="card-body px-md-3">
        <div className="d-flex justify-content-between">
          <div>
            <strong style={{"fontSize": "16px"}}>
              Profit & Loss
            </strong>
            <a href={`/teams/${team.id}/confirms/${confirm.id}/profit_and_loss`}
               className="btn btn-external btn-sm rounded ml-2">
              <i className="fas fa-arrow-right"></i>
            </a>
          </div>
          <div>
            <Popup arrow={false}
                   offsetY={5}
                   position="bottom right"
                   contentStyle={popupContentStyle}
                   onOpen={
                     (e) => {
                       e.stopPropagation();
                       e.preventDefault();
                     }
                   }
                   trigger={open => (
                     <a href="#"
                        onClick={
                          (e) => {
                            e.preventDefault();
                          }
                        }
                        className="btn btn-external btn-sm">
                       <i className="fas fa-ellipsis-v"></i>
                     </a>
                   )} >
              {close => (
                <div className='row text-left'>
                  <div className="col-12">
                    <ul className="list-group offer-inline-menu">
                      <li className="list-group-item px-3 pt-3 pb-2">
                        <a href={"/teams/" + team.id + "/confirms/" + confirm.id + "/profit_and_loss"}
                           className="text-dark">
                          <span className="d-inline-block text-center" style={{width: "28px"}}>
                            <i className="fas fa-magnifying-glass fa-fw"></i>
                          </span>
                          <span className="ml-3">
                            Preview
                          </span>
                        </a>
                      </li>
                      <li className="list-group-item px-3 pt-3 pb-2">
                        <a href={"/teams/" + team.id + "/confirms/" + confirm.id + "/profit_and_loss.pdf"}
                           className="text-dark">
                          <span className="d-inline-block text-center" style={{width: "28px"}}>
                            <i className="fas fa-download fa-fw"></i>
                          </span>
                          <span className="ml-3">
                            Download
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </Popup>
          </div>
        </div>
        {Object.keys(profitAndLoss).length === 0 ? (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{"height": "200px"}}
          >
            <img
              src="/uploading-loading.gif"
              className="mx-auto"
              style={{"width": "30px"}}
            />
          </div>
        ) : (
          <div className="row">
            <div className="col-12 col-sm-7 col-md-12 col-xl-7">
              <div>
                <table className="mb-0 table table-borderless table-sm">
                  <tbody>
                    <ProgressBarChart
                      title="Tickets Sold"
                      amount={profitAndLoss.ticketsSold}
                      label={profitAndLoss.ticketsSoldHuman}
                      max={confirm.ticket_forecast !== 0 ? confirm.ticket_forecast : (confirm.venue_capacity || (profitAndLoss.ticketsSold * 2))}
                      forecastDetail={
                        `${ticketsSoldPercent(confirm, profitAndLoss)}% to Forecast (${numberWithCommas(confirm.ticket_forecast || 0)})`
                      }
                      potentialDetail={
                        `${capacityPercent(confirm, profitAndLoss)}% to Capacity (${numberWithCommas(confirm.venue_capacity || 0)})`
                      }
                    />
                    <ProgressBarChart
                      title="Income"
                      amount={profitAndLoss.incomeTotal}
                      label={approx(profitAndLoss.incomeTotal, { prefix: '$' })}
                      max={profitAndLoss.incomeForecastTotal !== 0 ? profitAndLoss.incomeForecastTotal : (profitAndLoss.incomeTotal * 2)}
                      forecastDetail={
                        `${incomeForecastPercent(confirm, profitAndLoss)}% to Forecast (${approx(profitAndLoss.incomeForecastTotal, { prefix: '$' })})`
                      }
                      potentialDetail={
                        `${incomeGrossPotentialPercent(confirm, profitAndLoss)}% to Gross Potential (${approx(profitAndLoss.incomeGrossPotential, { prefix: '$' })})`
                      }
                    />
                    <ProgressBarChart
                      title="Expenses"
                      amount={profitAndLoss.expenseTotal}
                      label={approx(profitAndLoss.expenseTotal, { prefix: '$' })}
                      max={profitAndLoss.expenseForecastTotal !== 0 ? profitAndLoss.expenseForecastTotal : (profitAndLoss.expenseTotal * 2)}
                      forecastDetail={
                        `${expenseForecastPercent(confirm, profitAndLoss)}% to Forecast (${approx(profitAndLoss.expenseForecastTotal, { prefix: '$' })})`
                      }
                      potentialDetail={
                        `${expenseGrossPotentialPercent(confirm, profitAndLoss)}% to Gross Expenses (${approx(profitAndLoss.expenseGrossPotential, { prefix: '$' })})`
                      }
                    />
                  </tbody>
                </table>
              </div>
            </div>
            <div className="mt-3 col-12 col-sm-5 col-md-12 col-xl-5">
              <div
                style={{
                  "minWidth": "100px",
                  "padding": "12px 10px",
                  "borderRadius": "8px",
                  "backgroundColor": "#f9f9f9",
                }}>
                <div className="mb-2" style={{"paddingLeft": "5px"}}>
                  <strong>Profit (Loss)</strong>
                </div>
                <div className="d-flex p-and-l-recharts-surface">
                  <div style={{"minWidth": "100px", "height": "220px"}}>
                    <ResponsiveContainer width="99%" height="100%">
                      <BarChart
                        width={100}
                        height={220}
                        stackOffset="sign"
                        data={[
                          {
                            "amount": profitAndLoss.profitLoss,
                            "max": domain(profitAndLoss)[1],
                          }
                        ]}>
                        <YAxis dataKey="max"
                               type="number"
                               hide={true}
                               domain={domain(profitAndLoss)}/>
                        <XAxis type="category" hide={true} padding={{ right: 78 }} />
                        <defs>
                          <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="100%" spreadMethod="reflect">
                            <stop offset="0" stopColor="#8cca2a" />
                            <stop offset="1" stopColor="#e7f6cf" />
                          </linearGradient>
                          <linearGradient id="redGradient" x1="0" y1="0" x2="0" y2="100%" spreadMethod="reflect">
                            <stop offset="0" stopColor="#ffd9da" />
                            <stop offset="1" stopColor="#ff5b60" />
                          </linearGradient>
                        </defs>
                        <Bar stackId="0"
                             background={{ fill: '#fff', radius: 8, strokeWidth: 1, stroke: '#eee' }}
                             dataKey="amount"
                             // Without isAnimationActive={false}, the custom label
                             // will not render unless the chart is resized
                             isAnimationActive={false}
                             width={100}
                             barSize={10}
                             label={
                               <CustomizedLabel
                                 label1={approx(profitAndLoss.profitLoss, {prefix: '$'})}
                                 label2={`${numberWithCommas(profitAndLoss.breakeven)} Tickets to`}
                                 label3="Breakeven"
                               />
                             }
                             radius={profitAndLoss.profitLoss > 0 ? [8, 8, 0, 0] : [8, 8, 0, 0]}>
                          <Cell key={0} fill={profitAndLoss.profitLoss > 0 ? `url(#greenGradient)` : `url(#redGradient)`} />
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

ProfitAndLoss.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  profitAndLoss: PropTypes.object.isRequired,
  fetchProfitAndLoss: PropTypes.func.isRequired,
};

export default ProfitAndLoss;
