import { combineReducers } from 'redux';

import {
  OPEN_MODAL,
  CLOSE_MODAL,
  DATA_TABLE_EL_CHANGED,
  OFFER_CHANGED,
  PIPELINE_CHANGED,
  OFFER_TO_LINK_CHANGED
} from '../constants/offerManagerConstants';

const modalIsOpen = (state = false, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return true;
    case CLOSE_MODAL:
      return false;
    default:
      return state;
  }
};

const venuesOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const defaultOffer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableTitle = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableDataSource = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableOrder = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableColumns = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableClassNames = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableEl = (state = {}, action) => {
  switch (action.type) {
    case DATA_TABLE_EL_CHANGED:
      return action.el;
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const offer = (state = {}, action) => {
  switch (action.type) {
    case OFFER_CHANGED:
      return action.offer;
    default:
      return state;
  }
};

const offerTemplates = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const pipeline = (state = {}, action) => {
  switch (action.type) {
    case PIPELINE_CHANGED:
      return action.pipeline;
    default:
      return state;
  }
};

const venueSelected = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const offerToLink = (state = {}, action) => {
  switch (action.type) {
    case OFFER_TO_LINK_CHANGED:
      return action.offer;
    default:
      return state;
  }
};

const venueOwnerships = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const offerManagerReducer = combineReducers({
  modalIsOpen,
  venuesOptions,
  csrfToken,
  team,
  defaultOffer,
  dataTableTitle,
  dataTableDataSource,
  dataTableOrder,
  dataTableColumns,
  dataTableClassNames,
  dataTableEl,
  currentUser,
  offer,
  offerTemplates,
  pipeline,
  venueSelected,
  offerToLink,
  venueOwnerships
});

export default offerManagerReducer;
