/* eslint-disable import/prefer-default-export */

import {
  DWOLLA_CUSTOMER_CHANGED,
  PLAID_ACCOUNT_LINKS_CHANGED,
  VENUE_OWNERSHIPS_CHANGED
} from '../constants/financeSettingsManagerConstants';

const axios = require('axios').default;

import $ from 'jquery';
require('datatables.net-bs4');

export const dwollaCustomerChanged = (dwollaCustomer) => ({
  type: DWOLLA_CUSTOMER_CHANGED,
  dwollaCustomer
});

export const plaidAccountLinksChanged = (plaidAccountLinks) => ({
  type: PLAID_ACCOUNT_LINKS_CHANGED,
  plaidAccountLinks
});

export const venueOwnershipsChanged = (venueOwnerships) => ({
  type: VENUE_OWNERSHIPS_CHANGED,
  venueOwnerships
});
