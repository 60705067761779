import React from "react";
import axios from "axios";

import CardFooter from "./CardFooter";
import { useCampaignsContext } from "./CampaignsContainer";
import { toastError } from "../../../shared/toastHelper";

const cardStyle = {
  boxShadow: "0 0 5px 0 rgba(0,0,0,0.1)",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
};

const cardTopStyle = {
  alignItems: "baseline",
  flex: 1,
  display: "flex",
  backgroundColor: "white",
  margin: "10x",
  padding: "15px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const headerStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  fontWeight: "700",
  height: "25px",
  justifyContent: "space-between",
  width: "100%",
};

const headerTitleStyle = {
  justifyContent: "flex-start",
  textWrap: "nowrap",
};

const headerIconStyle = {
  color: "#3B81C3",
  marginRight: "5px",
};

const descriptionStyle = {
  color: "#5C5C5C",
  fontSize: "10px",
  fontWeight: "lighter",
};

const startCampaignButtonStyle = {
  backgroundColor: "#E2F0FB",
  borderRadius: "5px",
  color: "#3B81C3",
  cursor: "pointer",
  fontSize: "10px",
  fontWeight: "700",
  justifyContent: "center",
  padding: "5px 10px",
  marginTop: "10px",
};

const startCampaignButtonIconStyle = {
  marginLeft: "5px"
};

const AvailableCampaignCard = ({
  campaignTemplate,
}) => {
  const {
    csrfToken,
    team,
    venueOwnership,
  } = useCampaignsContext();

  const startCampaign = (e) => {
    e.preventDefault();

    if (campaignTemplate.campaign) {
      window.location.href = `/teams/${team.id}/campaigns/${campaignTemplate.campaign.id}`;
    } else {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
      axios.post("/teams/" + team.id + "/campaigns/", {
        campaign: {
          state: "available",
          campaign_template_id: campaignTemplate.id,
          team_id: team.id,
          venue_ownership_id: venueOwnership.id
        }
      })
        .then(({ data }) => {
          window.location.href = `/teams/${team.id}/campaigns/${data.id}`;
        })
        .catch(_error => toastError("Could not start campaign, please try again"));
      };
    }

  return (
    <div style={cardStyle}>
      <div style={cardTopStyle}>
        <div style={headerStyle}>
          <div style={headerTitleStyle}>
            <i className={`fa ${campaignTemplate.fa_icon}`} style={headerIconStyle} />
            {campaignTemplate.name}
          </div>
        </div>
        <div style={descriptionStyle}>
          {campaignTemplate.description}
        </div>
        <div style={startCampaignButtonStyle}
          onClick={startCampaign}
        >
          Start Campaign
          <i className="fa fa-arrow-right" style={startCampaignButtonIconStyle} />
        </div>
      </div>
      <CardFooter
        estimatedRevenue={campaignTemplate.estimated_revenue}
        tooltipCopy={campaignTemplate.tooltip_copy}
      />
    </div>
  );
};

export default AvailableCampaignCard;
