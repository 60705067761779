import React from "react";

import ContainerlessTableViewApp from "../../TableView/startup/ContainerlessTableViewApp";
import PeopleCard from "./PeopleCard";

const containerStyle = {
  backgroundColor: "white",
  borderRadius: "10px",
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  display: "flex",
  flexDirection: "column",
  marginBottom: "20px",
  marginTop: "20px",
  padding: "20px",
};

const headerContainerStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}

const headerGroupStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: "10px",
};

const exportButtonStyle = {
  backgroundColor: "white",
  border: "1px solid #1982C4",
  borderRadius: "5px",
  color: "#1982C4",
  fontSize: "12px",
  fontWeight: "bold",
  height: "40px",
  width: "125px",
};

const eventTitleStyle = {
  fontSize: "16px",
  fontWeight: "bold",
};

const headerStyle = {
  color: "#666",
  paddingBottom: "10px",
};

const pageContainerStyle = {
  paddingInline: "30px",
};

const SegmentDashboard = ({
  csrfToken,
  team,
  segment,
  data_table_props
}) => {
  return (
    <div>
      <div style={headerStyle}>
        Segments / {segment.name}
      </div>
      <div style={pageContainerStyle}>
        <div style={headerContainerStyle}>
          <div style={headerGroupStyle}>
            <div style={eventTitleStyle}>
              {segment.name}
            </div>
            <a href={`/teams/${team.id}/confirms/${segment.calendar_event.id}`}>
              Go to Event Page →
            </a>
          </div>
          <div style={headerGroupStyle}>
            <button style={exportButtonStyle}
              onClick={(e) => {
                e.preventDefault();
                window.location.href = `/teams/${team.id}/segments/${segment.id}/targeted_fans.csv`;
              }}
            >
              Export
            </button>
          </div>
        </div>
        <div style={containerStyle}>
          <PeopleCard segment={segment} />
          <ContainerlessTableViewApp
            {...data_table_props}
          />
        </div>
      </div>
    </div>
  );
};

export default SegmentDashboard;
