/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import {EditorState} from 'draft-js';

import {
  offsetDateForServer
} from '../../../shared/timeZoneLogic';

import {
  PERFORMERS_CHANGED,
  PERFORMER_REMOVED,
  STAGE_PERFORMER_FOR_UPDATE,
  CANCEL_STAGE_PERFORMER_FOR_UPDATE,
  PERFORMER_ACT_TYPE_CHANGED,
  PERFORMER_CHANGED,
  PERFORMER_START_TIME_CHANGED,
  PERFORMER_END_TIME_CHANGED,
  PERFORMER_ADDED,
  PERFORMER_ARTIST_CHANGED,
  PERFORMER_CREATED,
  CANCEL_ADD_PERFORMER,
  CANCEL_EDIT_PERFORMER,
  OPEN_NOTE_CHANGED,
  CLOSE_OPEN_NOTE,
  OPEN_NOTE_TITLE_CHANGED,
  OPEN_NOTE_BODY_CHANGED,
  OPEN_NOTE_ORIGINAL_CHANGED,
  NOTE_REMOVED,
  NOTE_CREATED,
  NOTE_UPDATED,
  OPEN_NOTE_LOADING,
  OPEN_NOTE_STOPPED_LOADING,
  DISABLE_OPEN_NOTE_PREVIEW_MODE,
  NOTE_TEMPLATES_CHANGED,
  ADD_CALENDAR_EVENT_CONTACT,
  CALENDAR_EVENT_CONTACT_REMOVED,
  REMOVE_UNSAVED_CALENDAR_EVENT_CONTACT,
  CALENDAR_EVENT_CONTACT_CREATED,
  EDIT_CALENDAR_EVENT_CONTACT,
  CANCEL_EDIT_CALENDAR_EVENT_CONTACT,
  UPDATE_CALENDAR_EVENT_CONTACT_NAME,
  UPDATE_CALENDAR_EVENT_CONTACT_EMAIL,
  UPDATE_CALENDAR_EVENT_CONTACT_PHONE,
  UPDATE_CALENDAR_EVENT_CONTACT_CONTACT_TYPE,
  UPDATE_CALENDAR_EVENT_CONTACT_TITLE,
  UPDATE_CALENDAR_EVENT_CONTACT_COMPANY_NAME,
  CALENDAR_EVENT_CONTACT_CHANGED,
  EDIT_UNSAVED_CALENDAR_EVENT_CONTACT,
  CALENDAR_EVENT_CONTACTS_CHANGED,
  CLOSE_PERFORMER_OFFER_MODAL,
  OPEN_PERFORMER_OFFER_MODAL,
  SET_OFFER_PERFORMER,
  OFFER_CHANGED,
  OFFERS_CHANGED,
  OFFER_DELETED,
  CONFIRM_CHANGED,
  MODAL_OFFER_CHANGED,
  SENDING_CUSTOMER_IO_EMAIL_CHANGED,
  CUSTOMER_IO_EMAIL_PURPOSE_CHANGED,
  CUSTOMER_IO_EMAIL_TEMPLATE_CHANGED,
  MARKETING_SECTION_ACTIVE_TAB_CHANGED,
  ADDING_NEW_SOCIAL_ACCOUNT_CHANGED,
  COMPOSING_POST_CHANGED,
  POST_CREATED,
  POST_DELETED,
  POST_TO_EDIT_CHANGED,
  POST_UPDATED,
  SHOW_ACTIVITIES_CHANGED,
  SHOW_ACTIVITY_DELETED,
  EDIT_SHOW_ACTIVITY,
  CANCEL_EDIT_SHOW_ACTIVITY,
  ADD_NEW_SHOW_ACTIVITY,
  SHOW_ACTIVITY_NAME_CHANGED,
  SHOW_ACTIVITY_START_DATE_CHANGED,
  SHOW_ACTIVITY_START_TIME_CHANGED,
  SHOW_ACTIVITY_CREATED,
  SHOW_ACTIVITY_UPDATED,
  SHOW_ADVANCE_SEND_CHANGED,
  SHOW_ADVANCE_SEND_TO_CHANGED,
  SHOW_ADVANCE_SEND_SUBJECT_CHANGED,
  SHOW_ADVANCE_SEND_BODY_CHANGED,
  SHOW_ADVANCE_SEND_NOTE_CLICKED,
  CONFIRM_INVITATION_CHANGED,
  CONFIRM_INVITATION_MEMO_CHANGED,
  EMAILS_TO_INVITE_CHANGED,
  CONFIRM_INVITATIONS_CREATED,
  IS_VIEWING_GUESTS_CHANGED,
  CONFIRM_INVITATIONS_DELETED,
  GUEST_TEAM_MEMBERSHIP_REMOVED,
  SHARE_SETTINGS_POPUP_CLOSED,
  OFFER_FOR_SETTLEMENT_CHANGED,
  PERMISSION_SET_CHANGED,
  PERMISSION_RULE_ENABLED_CLICKED,
  SELECT_ALL_PERMISSION_RULES_CLICKED,
  ON_PERMISSION_SET_SAVED_CHANGED,
  CONFIRM_PAGE_SECTION_CHANGED,
  SHOW_ADVANCE_SEND_CREATED,
  ADD_SOCIAL_MARKETING_BUDGET_ITEM,
  ADD_TRADITIONAL_MARKETING_BUDGET_ITEM,
  MARKETING_BUDGET_ITEM_CREATED,
  MARKETING_BUDGET_ITEM_DESTROY,
  EDIT_MARKETING_BUDGET_ITEM,
  MARKETING_BUDGET_ITEM_UPDATED,
  MARKETING_BUDGET_ITEM_TO_EDIT_CHANGED,
  FINANCE_ITEMS_CHANGED,
  FINANCE_ITEM_DELETED,
  FINANCE_ITEM_TO_EDIT_CHANGED,
  FINANCE_ITEM_FOR_FORM_CHANGED,
  FINANCE_ITEM_ADDED,
  FINANCE_ITEM_TO_EDIT_CANCELED,
  FINANCE_ITEM_CREATED,
  FINANCE_ITEM_UPDATED,
  FINANCE_CATEGORIES_CHANGED,
  SHOW_WIDGET_CODE_CHANGED,
  EDITING_CONFIRM_WEBSITE_CHANGED,
  EDITING_CONFIRM_WEBSITE_VALUE_CHANGED,
  FOLDER_CHANGED,
  PROFIT_AND_LOSS_CHANGED,
  HOLD_GROUP_DELETION_CHANGED,
  HOLD_TO_CONFIRM_CHANGED,
  USER_CHANGED_DOORS_TIME_CHANGED,
  USER_CHANGED_EVENT_END_TIME_CHANGED,
  IS_CONVERTING_HOLD_TO_CONFIRM_CHANGED,
  POSSIBLE_MATCHING_OFFERS_CHANGED,
  OFFER_ADDED,
  POSSIBLE_MATCHING_OFFER_REMOVED
} from '../constants/confirmShowConstants';

import {
  updateOfferFrontEndState,
  serverJSONToLocal,
} from '../../OfferModal/actions/offerModalActionCreators';

const parseFloatFromString = (value) => {
  if(value === undefined || value === null){
    return value;
  }

  if(typeof(value) === "number"){
    return value;
  }

  return parseFloat(value.split(",").join(""));
}

export const stagePerformerForUpdate = (performer) => ({
  type: STAGE_PERFORMER_FOR_UPDATE,
  performer
});

export const updateDeposit = (dispatch, csrfToken, team, deposit, paid, successCallback) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch(`/teams/${team.id}/deposits/${deposit.id}`, {
      deposit: {
        paid: paid
      }
    })
    .then(({ data }) => {
      successCallback(data);
    })
    .catch((error) => {
      toast.error(error.response.data.join(", "), {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: false,
        autoClose: 5000,
        hideProgressBar: true
      });
    });
  };
};

export const deletePerformer = (dispatch, csrfToken, team, performer) => {
  return (dispatch, getState) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/performers/" + performer.id)
      .then(({ data }) => {
        dispatch(performerRemoved(data));
        dispatch(fetchPossibleMatchingOffers(dispatch, csrfToken, team, getState().confirm));
      })
      .catch((error) => {
        // dispatch(updateEventToEditErrors(error.response.data));
      });
  };
};

export const performerRemoved = (performer) => ({
  type: PERFORMER_REMOVED,
  performer
});

export const updatePerformer = (dispatch, csrfToken, team, performer) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/teams/" + team.id + "/performers/" + performer.id, {
        performer: {
          act_type: performer.act_type,
          start_time_from_string: performer.start_time,
          end_time_from_string: performer.end_time
        }
      })
      .then(({ data }) => {
        dispatch(performerChanged(data));
        dispatch(cancelStagePerformerForUpdate());
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const cancelStagePerformerForUpdate = () => ({
  type: CANCEL_STAGE_PERFORMER_FOR_UPDATE
});

export const updatePerformerActType = (index, actType) => ({
  type: PERFORMER_ACT_TYPE_CHANGED,
  index,
  actType
});

export const performerChanged = (performer) => ({
  type: PERFORMER_CHANGED,
  performer
});

export const performerStartTimeChanged = (index, startTime) => ({
  type: PERFORMER_START_TIME_CHANGED,
  index,
  startTime
});

export const performerEndTimeChanged = (index, endTime) => ({
  type: PERFORMER_END_TIME_CHANGED,
  index,
  endTime
});

export const addNewPerformer = () => ({
  type: PERFORMER_ADDED
});

export const setArtistOnNewPerformer = (index, artist) => ({
  type: PERFORMER_ARTIST_CHANGED,
  index,
  artist
});

export const createPerformer = (dispatch, csrfToken, team, confirm, performer, index) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var params = {
      performable_id: confirm.id,
      performable_type: "CalendarEvent",
      act_type: performer.act_type,
      start_time_from_string: performer.start_time,
      end_time_from_string: performer.end_time
    };

    if(performer.artist && performer.artist.value){
      params = Object.assign({}, params, {
        artistable_type: "Artist",
        artistable_id: performer.artist.value
      });
    } else {
      params = Object.assign({}, params, {
        artist_attributes: {
          name: performer.artist.name
        }
      });
    }

    return axios.post("/teams/" + team.id + "/performers", {
        performer: params
      })
      .then(({ data }) => {
        dispatch(performerCreated(index, data));
        dispatch(refreshCalendarEventContacts(dispatch, team, confirm.id));
        dispatch(fetchPossibleMatchingOffers(dispatch, csrfToken, team, confirm));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const performerCreated = (index, performer) => ({
  type: PERFORMER_CREATED,
  index,
  performer
});

export const cancelAddPerformer = () => ({
  type: CANCEL_ADD_PERFORMER
});

export const cancelEditPerformer = (performer, originalPerformer) => ({
  type: CANCEL_EDIT_PERFORMER,
  performer,
  originalPerformer
});

export const fetchNewNote = (dispatch, csrfToken, team) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/notes/new")
      .then(({ data }) => {
        dispatch(openNoteChanged(data));
        dispatch(openNoteOriginalChanged(data));
      });
  };
};

export const openNoteChanged = (note) => ({
  type: OPEN_NOTE_CHANGED,
  note
});

export const closeOpenNote = () => ({
  type: CLOSE_OPEN_NOTE
});

export const openNoteTitleChanged = (title) => ({
  type: OPEN_NOTE_TITLE_CHANGED,
  title
});

export const openNoteBodyChanged = (editorState) => ({
  type: OPEN_NOTE_BODY_CHANGED,
  editorState
});

export const openNoteOriginalChanged = (note) => ({
  type: OPEN_NOTE_ORIGINAL_CHANGED,
  note
});

const htmlToEditorState = (html) => {
  var state = stateFromHTML(html);
  return EditorState.createWithContent(state);
}

const noteFromServerJSON = (note) => {
  return Object.assign({}, note, {
    body: note.body || "",
    editorState: htmlToEditorState(note.body || ""),
  });
}

export const createNote = (dispatch, csrfToken, team, confirm, note) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(openNoteLoading());

    return axios.post("/teams/" + team.id + "/notes", {
        note: {
          calendar_event_id: confirm.id,
          title: note.title,
          body: note.body,
          note_template_id: note.note_template_id,
        }
      })
      .then(({ data }) => {
        dispatch(openNoteStoppedLoading());
        var note = noteFromServerJSON(data);

        dispatch(noteCreated(note));
        dispatch(openNoteChanged(note));
        dispatch(openNoteOriginalChanged(note));
      })
      .catch((error) => {
        dispatch(openNoteStoppedLoading());
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const deleteNote = (dispatch, csrfToken, team, note) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/notes/" + note.id)
      .then(({ data }) => {
        dispatch(noteRemoved(data));
      })
      .catch((error) => {
        // dispatch(updateEventToEditErrors(error.response.data));
      });
  };
};

export const noteRemoved = (note) => ({
  type: NOTE_REMOVED,
  note
});

export const noteCreated = (note) => ({
  type: NOTE_CREATED,
  note
});

export const fetchNoteToEdit = (dispatch, csrfToken, team, noteId) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/notes/" + noteId + "/edit")
      .then(({ data }) => {
        var note = noteFromServerJSON(data);
        note = Object.assign({}, note, {previewMode: false});
        dispatch(openNoteChanged(note));
        dispatch(openNoteOriginalChanged(note));
      });
  };
};

export const fetchNoteToPreview = (dispatch, csrfToken, team, noteId) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/notes/" + noteId + "/edit")
      .then(({ data }) => {
        var note = noteFromServerJSON(data);
        note = Object.assign({}, note, {previewMode: true});
        dispatch(openNoteChanged(note));
        dispatch(openNoteOriginalChanged(note));
      });
  };
};

export const updateNote = (dispatch, csrfToken, team, note) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(openNoteLoading());

    return axios.patch("/teams/" + team.id + "/notes/" + note.id, {
        note: {
          title: note.title,
          body: note.body,
          note_template_id: note.note_template_id,
        }
      })
      .then(({ data }) => {
        dispatch(openNoteStoppedLoading());
        var note = noteFromServerJSON(data);

        dispatch(noteUpdated(note));
        dispatch(openNoteChanged(note));
        dispatch(openNoteOriginalChanged(note));
      })
      .catch((error) => {
        dispatch(openNoteStoppedLoading());
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const noteUpdated = (note) => ({
  type: NOTE_UPDATED,
  note
});

export const openNoteLoading = () => ({
  type: OPEN_NOTE_LOADING
});

export const openNoteStoppedLoading = () => ({
  type: OPEN_NOTE_STOPPED_LOADING
});

export const disableOpenNotePreviewMode = () => ({
  type: DISABLE_OPEN_NOTE_PREVIEW_MODE
});

export const noteTemplatesChanged = (noteTemplates) => ({
  type: NOTE_TEMPLATES_CHANGED,
  noteTemplates
});

export const createCalendarEventContactFromContactId = (dispatch, csrfToken, team, confirm, contactId, index) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/calendar_event_contacts", {
        calendar_event_contact: {
          calendar_event_id: confirm.id,
          contact_id: contactId
        }
      })
      .then(({ data }) => {
        dispatch(calendarEventContactCreated(data, index));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const deleteCalendarEventContact = (dispatch, csrfToken, team, calendarEventContact) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/calendar_event_contacts/" + calendarEventContact.id)
      .then(({ data }) => {
        dispatch(calendarEventContactRemoved(data));
      })
      .catch((error) => {
        // dispatch(updateEventToEditErrors(error.response.data));
      });
  };
};

export const addCalendarEventContact = (originalCalendarEventContact) => ({
  type: ADD_CALENDAR_EVENT_CONTACT,
  originalCalendarEventContact
});

export const calendarEventContactRemoved = (calendarEventContact) => ({
  type: CALENDAR_EVENT_CONTACT_REMOVED,
  calendarEventContact
});

export const removeUnsavedCalendarEventContact = () => ({
  type: REMOVE_UNSAVED_CALENDAR_EVENT_CONTACT
});

export const calendarEventContactCreated = (calendarEventContact, index) => ({
  type: CALENDAR_EVENT_CONTACT_CREATED,
  calendarEventContact,
  index
});

export const editCalendarEventContact = (index, calendarEventContact, originalCalendarEventContact) => ({
  type: EDIT_CALENDAR_EVENT_CONTACT,
  index,
  calendarEventContact,
  originalCalendarEventContact
});

export const cancelEditCalendarEventContact = (originalCalendarEventContact) => ({
  type: CANCEL_EDIT_CALENDAR_EVENT_CONTACT,
  originalCalendarEventContact
});

export const updateCalendarEventContactName = (index, name) => ({
  type: UPDATE_CALENDAR_EVENT_CONTACT_NAME,
  index,
  name
});

export const updateCalendarEventContactEmail = (index, email) => ({
  type: UPDATE_CALENDAR_EVENT_CONTACT_EMAIL,
  index,
  email
});

export const updateCalendarEventContactPhone = (index, phone) => ({
  type: UPDATE_CALENDAR_EVENT_CONTACT_PHONE,
  index,
  phone
});

export const updateCalendarEventContactContactType = (index, contactType) => ({
  type: UPDATE_CALENDAR_EVENT_CONTACT_CONTACT_TYPE,
  index,
  contactType
});

export const updateCalendarEventContactTitle = (index, title) => ({
  type: UPDATE_CALENDAR_EVENT_CONTACT_TITLE,
  index,
  title
});

export const updateCalendarEventContactCompanyName = (index, companyName) => ({
  type: UPDATE_CALENDAR_EVENT_CONTACT_COMPANY_NAME,
  index,
  companyName
});

export const submitCalendarEventContactForm = (dispatch, csrfToken, team, confirm, calendarEventContact, index) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var method = (calendarEventContact.id ? "patch" : "post");

    var url;
    if(calendarEventContact.id){
      url = "/teams/" + team.id + "/calendar_event_contacts/" + calendarEventContact.id;
    } else {
      url = "/teams/" + team.id + "/calendar_event_contacts";
    }


    return axios({
      method: method,
      url: url,
      data: {
        calendar_event_contact: {
          calendar_event_id: confirm.id,
          contact_attributes: {
            id: calendarEventContact.contact.id,
            name: calendarEventContact.contact.name,
            email: calendarEventContact.contact.email,
            phone: calendarEventContact.contact.phone,
            contact_type: calendarEventContact.contact.contact_type,
            title: calendarEventContact.contact.title,
            company_name: calendarEventContact.contact.company_name,
            team_id: team.id
          }
        }
      }})
      .then(({ data }) => {
        dispatch(calendarEventContactChanged(index, data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const calendarEventContactChanged = (index, calendarEventContact) => ({
  type: CALENDAR_EVENT_CONTACT_CHANGED,
  index,
  calendarEventContact
});

export const editUnsavedCalendarEventContact = (name, index, originalCalendarEventContact) => ({
  type: EDIT_UNSAVED_CALENDAR_EVENT_CONTACT,
  name,
  index,
  originalCalendarEventContact
});

export const createCalendarEventContactFromAgentId = (dispatch, csrfToken, team, confirm, agentId, index) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/calendar_event_contacts", {
        calendar_event_contact: {
          calendar_event_id: confirm.id,
          contact_attributes: {
            agent_id: agentId,
            team_id: team.id
          }
        }
      })
      .then(({ data }) => {
        dispatch(calendarEventContactCreated(data, index));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const refreshCalendarEventContacts = (dispatch, team, calendarEventId) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/calendar_event_contacts", {
        params: {
          calendar_event_id: calendarEventId
        }
      })
      .then(({ data }) => {
        dispatch(calendarEventContactsChanged(data));
      })
  };
};

export const calendarEventContactsChanged = (calendarEventContacts) => ({
  type: CALENDAR_EVENT_CONTACTS_CHANGED,
  calendarEventContacts
});

export const openOfferModal = () => ({
  type: OPEN_PERFORMER_OFFER_MODAL
});

export const closeOfferModal = (dispatch, defaultOffer, dataTableEl) => {
  return dispatch => {
    var deepCopy = JSON.parse(JSON.stringify(defaultOffer));
    dispatch(updateOfferData(deepCopy));

    dispatch(broadcastCloseOfferModal());
  };
};

export const broadcastCloseOfferModal = () => ({
  type: CLOSE_PERFORMER_OFFER_MODAL
});


export const onManageLineupInit = (dispatch, defaultOffer) => {
  return dispatch => {
    var deepCopy = JSON.parse(JSON.stringify(defaultOffer));
    dispatch(updateOfferData(deepCopy));
  }
};

export const fetchOfferData = (dispatch, team, offerId, frontEndState) => {
  return dispatch => {
    return axios.get(`/teams/${team.id}/offers/${offerId}.json`)
      .then(({ data }) => {
        var offer = serverJSONToLocal(data);
        offer = Object.assign({}, offer, {frontEndState: frontEndState});

        dispatch(updateOfferData(offer));
        dispatch(openOfferModal());
      })
  };
};

export const refreshPerformer = (dispatch, team, performerId) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/performers/" + performerId)
      .then(({ data }) => {
        dispatch(performerChanged(data));
      })
  };
};

export const performersChanged = (performers) => ({
  type: PERFORMERS_CHANGED,
  performers
});

export const setOfferPerformer = (performer) => ({
  type: SET_OFFER_PERFORMER,
  performer
});

export const updateOfferArtist = (dispatch, csrfToken, team, offer, successCallback) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    const performer_attributes = !offer.savePerformer ? {} : (
      offer.performer?.id ? ({
        performer_id: offer.performer?.id
      }) : ({
        // Even though we're creating this performer through the offer,
        // it should be associated with the calendar event, not the offer.
        performer_attributes: {
          artistable_id: offer.performer?.artistable.id,
          artistable_type: "Artist",
          performable_id: offer.calendar_event?.id,
          performable_type: "CalendarEvent"
        }
      })
    );

    return axios.patch("/teams/" + team.id + "/offers/" + offer.id, {
      offer: {
        ...offer,
        ...performer_attributes
      }
    })
    .then(response => { successCallback(response.data); })
    .catch(error => {
      toast.error(error.response.data.join(", "), {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: false,
        autoClose: 5000,
        hideProgressBar: true
      });
    });
  };
}

export const acceptOffer = (dispatch, team, offerId, csrfToken) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/offers/" + offerId + "/accept")
      .then(({ data }) => {
        dispatch(offerChanged(data));
      })
  };
};

export const declineOffer = (dispatch, team, offerId, csrfToken) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/offers/" + offerId + "/decline")
      .then(({ data }) => {
        dispatch(offerChanged(data));
      })
  };
};

export const offerChanged = (offer) => ({
  type: OFFER_CHANGED,
  offer
});

export const refreshConfirmOffers = (dispatch, team, confirm) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/confirms/" + confirm.id + "/offers")
      .then(({ data }) => {
        dispatch(offersChanged(data));
      })
  };
};

export const offersChanged = (offers) => ({
  type: OFFERS_CHANGED,
  offers
});

export const deleteOffer = (dispatch, team, offerId, csrfToken) => {
  return (dispatch, getState) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/offers/" + offerId)
      .then(({ data }) => {
        dispatch(offerDeleted(data));
        dispatch(fetchPossibleMatchingOffers(dispatch, csrfToken, team, getState().confirm));
      });
  };
};

export const offerDeleted = (offer) => ({
  type: OFFER_DELETED,
  offer
});

export const updateConfirmWebsite = (dispatch, csrfToken, confirm, website, successCallback) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch( "/teams/" + confirm.team_id + "/confirms/" + confirm.id, {
      confirm: {
        venue_permalink: website,
        user_modified_venue_permalink: (website.length > 0)
      }
    })
    .then(({ data }) => {
      successCallback(data);
    })
    .catch((error) => {
      var message = typeof error.response === 'undefined'
        ? "Something went wrong"
        : error.response.data.join(', ');

      toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: false,
        autoClose: 5000,
        hideProgressBar: true
      });
    });
  };
};

export const updateConfirmTicketForecast = (dispatch, csrfToken, confirm, ticketForecast, successCallback, errorCallback) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch( "/teams/" + confirm.team_id + "/confirms/" + confirm.id, {
      confirm: {
        ticket_forecast: ticketForecast
      }
    })
    .then(({ data }) => {
      successCallback(data);
    })
    .catch((error) => {
      errorCallback(error);
    });
  };
};

export const deleteConfirm = (dispatch, csrfToken, team, confirm) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/calendar_events/" + confirm.id)
      .then(({ data }) => {
        window.location.href = "/dashboard";
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const cancelConfirm = (dispatch, csrfToken, team, confirm) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/confirms/" + confirm.id + "/cancel")
      .then(({ data }) => {
        dispatch(confirmChanged(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const uncancelConfirm = (dispatch, csrfToken, team, confirm) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/confirms/" + confirm.id + "/uncancel")
      .then(({ data }) => {
        dispatch(confirmChanged(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const confirmChanged = (confirm) => ({
  type: CONFIRM_CHANGED,
  confirm
});

export const updateOfferData = (offer) => ({
  type: MODAL_OFFER_CHANGED,
  offer
});

export const sendingCustomerIOEmailChanged = (sendingCustomerIOEmail) => ({
  type: SENDING_CUSTOMER_IO_EMAIL_CHANGED,
  sendingCustomerIOEmail
});

export const customerIOEmailPurposeChanged = (customerIOEmailPurpose) => ({
  type: CUSTOMER_IO_EMAIL_PURPOSE_CHANGED,
  customerIOEmailPurpose
});

export const customerIOEmailTemplateChanged = (customerIOEmailTemplate) => ({
  type: CUSTOMER_IO_EMAIL_TEMPLATE_CHANGED,
  customerIOEmailTemplate
});

export const createCustomerIOEmail = (dispatch, eventbriteEventId, customerIOEmailPurpose, customerIOEmailTemplate) => {
  return dispatch => {
    delete axios.defaults.headers.common['X-CSRF-TOKEN'];

    return axios.get("https://thevogue.com/wp-json/vogue/v1/emails", {
        params: {
          id: eventbriteEventId,
          template: customerIOEmailTemplate,
          purpose: customerIOEmailPurpose
        }
      })
      .then(({ data }) => {
        toast.success("Generated new email successfully", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });

        dispatch(sendingCustomerIOEmailChanged(false));
      })
      .catch((error) => {
        toast.error("Failed to generate new email", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const marketingSectionActiveTabChanged = (tab) => ({
  type: MARKETING_SECTION_ACTIVE_TAB_CHANGED,
  tab
});

export const addingNewSocialAccountChanged = (addingNewSocialAccount) => ({
  type: ADDING_NEW_SOCIAL_ACCOUNT_CHANGED,
  addingNewSocialAccount
});

export const composingPostChanged = (composingPost) => ({
  type: COMPOSING_POST_CHANGED,
  composingPost
});

export const createPost = (dispatch, csrfToken, team, confirm, postIntentToken) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/posts", {
        post: {
          post_intent_token: postIntentToken,
          calendar_event_id: confirm.id
        }
      })
      .then(({ data }) => {
        dispatch(composingPostChanged(false));
        dispatch(postCreated(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const deletePost = (dispatch, csrfToken, team, post) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/posts/" + post.id)
      .then(({ data }) => {
        dispatch(postDeleted(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const postCreated = (post) => ({
  type: POST_CREATED,
  post
});

export const postDeleted = (post) => ({
  type: POST_DELETED,
  post
});

export const postToEditChanged = (post) => ({
  type: POST_TO_EDIT_CHANGED,
  post
});

export const updatePost = (dispatch, csrfToken, team, post, postIntentToken) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.put("/teams/" + team.id + "/posts/" + post.id, {
        post: {
          post_intent_token: postIntentToken
        }
      })
      .then(({ data }) => {
        dispatch(postToEditChanged({}));
        dispatch(postUpdated(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const postUpdated = (post) => ({
  type: POST_UPDATED,
  post
});

export const updateCalendarEventContact = (dispatch, csrfToken, team, calendarEventContact, params, index) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/teams/" + team.id + "/calendar_event_contacts/" + calendarEventContact.id, {
        calendar_event_contact: params
      })
      .then(({ data }) => {
        dispatch(calendarEventContactChanged(index, data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const fetchConfirmShowActivities = (dispatch, csrfToken, team, confirm) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.get("/teams/" + team.id + "/show_activities", {
        params: {
          calendar_event_id: confirm.id
        }
      })
      .then(({ data }) => {
        dispatch(showActivitiesChanged(data));
      });
  };
};

export const refreshConfirm = (dispatch, csrfToken, team) => {
  return (dispatch, getState) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    const confirmId = getState().confirm.id;

    return axios.get(`/teams/${team.id}/confirms/${confirmId}`, {
      headers: { "Accept": "application/json" }
    })
    .then(response => {
      dispatch(confirmChanged(response.data));
      dispatch(performersChanged(response.data.performers));
    })
  };
};

export const showActivitiesChanged = (showActivities) => ({
  type: SHOW_ACTIVITIES_CHANGED,
  showActivities
});

export const deleteShowActivity = (dispatch, csrfToken, team, showActivity) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/show_activities/" + showActivity.id)
      .then(({ data }) => {
        dispatch(showActivityDeleted(data));

        toast.success("Deleted show activity detail", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: true,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const showActivityDeleted = (showActivity) => ({
  type: SHOW_ACTIVITY_DELETED,
  showActivity
});

export const editShowActivity = (showActivity) => ({
  type: EDIT_SHOW_ACTIVITY,
  showActivity
});

export const cancelEditShowActivity = (showActivityCopy) => ({
  type: CANCEL_EDIT_SHOW_ACTIVITY,
  showActivityCopy
});

export const addNewShowActivity = (showActivityCopy, confirm) => ({
  type: ADD_NEW_SHOW_ACTIVITY,
  showActivityCopy,
  confirm
});

export const showActivityNameChanged = (index, name) => ({
  type: SHOW_ACTIVITY_NAME_CHANGED,
  index,
  name
});

export const showActivityStartDateChanged = (index, date) => ({
  type: SHOW_ACTIVITY_START_DATE_CHANGED,
  index,
  date
});

export const showActivityStartTimeChanged = (index, time) => ({
  type: SHOW_ACTIVITY_START_TIME_CHANGED,
  index,
  time
});

export const createShowActivity = (dispatch, csrfToken, team, showActivity, confirm) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/show_activities", {
        show_activity: {
          name: showActivity.name,
          start_time: offsetDateForServer(showActivity.start_time_with_wrong_date),
          calendar_event_id: confirm.id
        }
      })
      .then(({ data }) => {
        dispatch(showActivityCreated(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const showActivityCreated = (showActivity) => ({
  type: SHOW_ACTIVITY_CREATED,
  showActivity
});

export const updateShowActivity = (dispatch, csrfToken, team, showActivity) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/teams/" + team.id + "/show_activities/" + showActivity.id, {
        show_activity: {
          name: showActivity.name,
          set: true,
          start_time: offsetDateForServer(showActivity.start_time_with_wrong_date),
          show_in_advance: showActivity.show_in_advance
        }
      })
      .then(({ data }) => {
        dispatch(showActivityUpdated(data));
        if(showActivity.name == "Event Start") {
          dispatch(refreshConfirm(dispatch, csrfToken, team));
        }
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const showActivityUpdated = (showActivity) => ({
  type: SHOW_ACTIVITY_UPDATED,
  showActivity
});

export const showAdvanceSendChanged = (showAdvanceSend) => ({
  type: SHOW_ADVANCE_SEND_CHANGED,
  showAdvanceSend
});

export const showAdvanceSendToChanged = (to) => ({
  type: SHOW_ADVANCE_SEND_TO_CHANGED,
  to
});

export const showAdvanceSendSubjectChanged = (subject) => ({
  type: SHOW_ADVANCE_SEND_SUBJECT_CHANGED,
  subject
});

export const showAdvanceSendBodyChanged = (body) => ({
  type: SHOW_ADVANCE_SEND_BODY_CHANGED,
  body
});

export const createShowAdvanceSend = (dispatch, csrfToken, team, showAdvanceSend) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/show_advance_sends", {
        show_advance_send: {
          calendar_event_id: showAdvanceSend.calendar_event_id,
          to: showAdvanceSend.to.split(",").map((e) => e.trim()),
          subject: showAdvanceSend.subject,
          body: showAdvanceSend.body,
          note_ids: showAdvanceSend.note_ids
        }
      })
      .then(({ data }) => {
        dispatch(showAdvanceSendChanged({}));
        dispatch(showAdvanceSendCreated(data));

        toast.success("Sending Show Advance...", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const showAdvanceSendNoteClicked = (noteId) => ({
  type: SHOW_ADVANCE_SEND_NOTE_CLICKED,
  noteId
});

export const confirmInvitationChanged = (confirmInvitation) => ({
  type: CONFIRM_INVITATION_CHANGED,
  confirmInvitation
});

export const confirmInvitationMemoChanged = (memo) => ({
  type: CONFIRM_INVITATION_MEMO_CHANGED,
  memo
});

export const emailsToInviteChanged = (emailsToInvite) => ({
  type: EMAILS_TO_INVITE_CHANGED,
  emailsToInvite
});

export const createConfirmInvitations = (dispatch, csrfToken, team, confirm, confirmInvitation, emailsToInvite, guestRole) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var emailsWithOptionalName = emailsToInvite.map((data) => {
      var result = {email: data.value};

      if(data.name && data.name !== data.value){
        var split = data.name.split(" ");

        result.last_name = split.pop();
        result.first_name = split.join(" ");
      }

      return result;
    });

    return axios.post("/teams/" + team.id + "/bulk_invitations", {
        emails_with_optional_name: emailsWithOptionalName,
        invitation: {
          type: "Invitations::Confirm",
          role_id: guestRole.id,
          calendar_event_id: confirm.id,
          memo: confirmInvitation.memo
        }
      })
      .then(({ data }) => {
        dispatch(confirmInvitationsCreated(data));

        toast.success("Invitations have been sent", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const confirmInvitationsCreated = (invitations) => ({
  type: CONFIRM_INVITATIONS_CREATED,
  invitations
});

export const isViewingGuestsChanged = (isViewingGuests) => ({
  type: IS_VIEWING_GUESTS_CHANGED,
  isViewingGuests
});

export const deleteConfirmInvitation = (dispatch, csrfToken, team, invitation, confirmInvitationsAndTeamMemberships) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/admin_invitations/" + invitation.id)
      .then(({ data }) => {
        dispatch(confirmInvitationDeleted(data));

        var remaining = confirmInvitationsAndTeamMemberships.filter((e) => {
          return (e.type_for_ui !== "Invitations::Confirm" || e.id !== data.id);
        });

        if(remaining.length === 0){
          dispatch(isViewingGuestsChanged(false));
        }
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const confirmInvitationDeleted = (invitation) => ({
  type: CONFIRM_INVITATIONS_DELETED,
  invitation
});

export const removeConfirmAccess = (dispatch, csrfToken, team, teamMembership, confirm, confirmInvitationsAndTeamMemberships) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/team_memberships/" + teamMembership.id + "/remove_confirm_access", {
        calendar_event_id: confirm.id
      })
      .then(({ data }) => {
        dispatch(guestTeamMembershipRemoved(data));

        var remaining = confirmInvitationsAndTeamMemberships.filter((e) => {
          return (e.type_for_ui !== "TeamMembership" || e.id !== data.id);
        });

        if(remaining.length === 0){
          dispatch(isViewingGuestsChanged(false));
        }
      });
  };
};

export const guestTeamMembershipRemoved = (teamMembership) => ({
  type: GUEST_TEAM_MEMBERSHIP_REMOVED,
  teamMembership
});

export const shareSettingsPopupClosed = () => ({
  type: SHARE_SETTINGS_POPUP_CLOSED
});

export const offerForSettlementChanged = (offer) => ({
  type: OFFER_FOR_SETTLEMENT_CHANGED,
  offer
});

export const permissionSetChanged = (permissionSet) => ({
  type: PERMISSION_SET_CHANGED,
  permissionSet
});

export const fetchPermissionSet = (dispatch, team, permissionSet, onPermissionSetSaved) => {
  return dispatch => {
    var endpoint;

    dispatch(onPermissionSetSavedChanged(onPermissionSetSaved));

    if(permissionSet && permissionSet.id){
      endpoint = "/teams/" + team.id + "/permission_sets/" + permissionSet.id + "/edit";
    } else {
      endpoint = "/teams/" + team.id + "/permission_sets/new";
    }

    return axios.get(endpoint)
      .then(({ data }) => {
        permissionSet = Object.assign({}, data, permissionSet);
        dispatch(permissionSetChanged(permissionSet));
      });
  };
};

export const onPermissionSetSavedChanged = (onPermissionSetSaved) => ({
  type: ON_PERMISSION_SET_SAVED_CHANGED,
  onPermissionSetSaved
});

export const permissionRuleEnabledClicked = (permissionRule) => ({
  type: PERMISSION_RULE_ENABLED_CLICKED,
  permissionRule
});

export const selectAllPermissionRulesClicked = (checked) => ({
  type: SELECT_ALL_PERMISSION_RULES_CLICKED,
  checked
});

export const submitPermissionSet = (dispatch, csrfToken, team, permissionSet, onPermissionSetSaved) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var updating = (permissionSet && permissionSet.id);
    var method = (updating ? "patch" : "post");
    var url = (updating ? (
      "/teams/" + team.id + "/permission_sets/" + permissionSet.id
    ) : (
      "/teams/" + team.id + "/permission_sets"
    ));

    var permissionRulesAttributes = permissionSet.permission_rules.map((permissionRule) => {
      return {
        id: permissionRule.id,
        enabled: permissionRule.enabled,
        rulable_type: permissionRule.rulable_type,
        rulable_id: permissionRule.rulable_id
      };
    });

    return axios.request({
        method: method,
        url: url,
        data: {
          permission_set: {
            element_id: permissionSet.element_id,
            element_type: permissionSet.element_type,
            permission_rules_attributes: permissionRulesAttributes
          }
        }
      })
      .then(({ data }) => {
        onPermissionSetSaved(data);
        dispatch(permissionSetChanged({}));

        toast.success("Permissions saved", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const updateConfirmPageSection = (confirmPageSection) => ({
  type: CONFIRM_PAGE_SECTION_CHANGED,
  confirmPageSection
});

export const showAdvanceSendCreated = (showAdvanceSend) => ({
  type: SHOW_ADVANCE_SEND_CREATED,
  showAdvanceSend
});

export const addSocialMarketingBudgetItem = (marketingBudgetItem) => ({
  type: ADD_SOCIAL_MARKETING_BUDGET_ITEM,
  marketingBudgetItem
});

export const addTraditionalMarketingBudgetItem = (marketingBudgetItem) => ({
  type: ADD_TRADITIONAL_MARKETING_BUDGET_ITEM,
  marketingBudgetItem
});

export const createMarketingBudgetItem = (dispatch, csrfToken, team, confirm, marketingBudgetItem) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/marketing_budget_items", {
        marketing_budget_item: {
          name: marketingBudgetItem.name,
          calendar_event_id: confirm.id,
          budget: parseFloatFromString(marketingBudgetItem.budget_with_two_decimals),
          actual: parseFloatFromString(marketingBudgetItem.actual_with_two_decimals),
          start_date_midday: marketingBudgetItem.start_date_midday,
          end_date_midday: marketingBudgetItem.end_date_midday,
          category: marketingBudgetItem.category
        }
      })
      .then(({ data }) => {
        dispatch(marketingBudgetItemCreated(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const marketingBudgetItemCreated = (marketingBudgetItem) => ({
  type: MARKETING_BUDGET_ITEM_CREATED,
  marketingBudgetItem
});

export const destroyMarketingBudgetItem = (dispatch, csrfToken, team, marketingBudgetItem) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/marketing_budget_items/" + marketingBudgetItem.id)
      .then(({ data }) => {
        dispatch(marketingBudgetItemDestroy(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const marketingBudgetItemDestroy = (marketingBudgetItem) => ({
  type: MARKETING_BUDGET_ITEM_DESTROY,
  marketingBudgetItem
});

export const editMarketingBudgetItem = (marketingBudgetItem) => ({
  type: EDIT_MARKETING_BUDGET_ITEM,
  marketingBudgetItem
});

export const updateMarketingBudgetItem = (dispatch, csrfToken, team, marketingBudgetItem) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/teams/" + team.id + "/marketing_budget_items/" + marketingBudgetItem.id, {
        marketing_budget_item: {
          name: marketingBudgetItem.name,
          budget: parseFloatFromString(marketingBudgetItem.budget_with_two_decimals),
          actual: parseFloatFromString(marketingBudgetItem.actual_with_two_decimals),
          start_date_midday: marketingBudgetItem.start_date_midday,
          end_date_midday: marketingBudgetItem.end_date_midday
        }
      })
      .then(({ data }) => {
        dispatch(marketingBudgetItemUpdated(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const marketingBudgetItemUpdated = (marketingBudgetItem) => ({
  type: MARKETING_BUDGET_ITEM_UPDATED,
  marketingBudgetItem
});

export const marketingBudgetItemToEditChanged = (marketingBudgetItem) => ({
  type: MARKETING_BUDGET_ITEM_TO_EDIT_CHANGED,
  marketingBudgetItem
});

export const financeCategoriesChanged = (financeCategories) => ({
  type: FINANCE_CATEGORIES_CHANGED,
  financeCategories
});

export const fetchNewFinanceItem = (dispatch, team, is_income) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/finance_items/new")
      .then(({ data }) => {
        data.payment_method = null;
        data.is_income = is_income;

        data.finance_line_items.forEach(lineItem => {
          lineItem.category_id = "";
        });

        dispatch(financeItemAdded(data));
        dispatch(financeItemForFormChanged(data));
        dispatch(financeItemToEditChanged(data.finance_line_items[0]));
      })
  };
};

export const deleteFinanceItem = (dispatch, csrfToken, team, confirm, financeItem, offersToUpdate) => {
  return dispatch => {
    return axios.delete("/teams/" + team.id + "/finance_items/" + financeItem.id)
      .then(({ data }) => {
        dispatch(financeItemDeleted(data));
        dispatch(fetchProfitAndLoss(dispatch, csrfToken, team, confirm));

        offersToUpdate.forEach((offer) => {
          dispatch(fetchOfferTotalDue(dispatch, team, offer));
        });
      })
  };
};

export const financeItemsChanged = (financeItems) => ({
  type: FINANCE_ITEMS_CHANGED,
  financeItems
});

export const financeItemDeleted = (financeItem) => ({
  type: FINANCE_ITEM_DELETED,
  financeItem
});

export const financeItemToEditChanged = (financeItem) => ({
  type: FINANCE_ITEM_TO_EDIT_CHANGED,
  financeItem
});

export const financeItemForFormChanged = (financeItemForForm) => ({
  type: FINANCE_ITEM_FOR_FORM_CHANGED,
  financeItemForForm
});

export const financeItemAdded = (financeItem) => ({
  type: FINANCE_ITEM_ADDED,
  financeItem
});

export const financeItemToEditCanceled = () => ({
  type: FINANCE_ITEM_TO_EDIT_CANCELED
});

export const financeItemParams = (financeItem, confirm=null) => {
  var params = new FormData();

  params.append("finance_item[is_income]", financeItem.is_income);
  params.append("finance_item[calendar_event_id]", confirm?.id || financeItem.calendar_event_id);
  params.append("finance_item[performer_id]", financeItem.performer_id);
  params.append("finance_item[on_settlement]", financeItem.on_settlement);
  params.append("finance_item[issued_on_midday]", financeItem.issued_on_midday || offsetDateForServer(new Date()))
  params.append("finance_item[expense_type]", financeItem.expense_type);
  params.append("finance_item[payment_method]", financeItem.payment_method || '');
  params.append("finance_item[payee]", financeItem.payee || '');

  financeItem.finance_line_items.forEach((lineItem, i) => {
    if(lineItem.id > 0) {
      params.append(`finance_item[finance_line_items_attributes][${i}][id]`, lineItem.id);
    }

    params.append(`finance_item[finance_line_items_attributes][${i}][calc_type]`, lineItem.calc_type);
    params.append(`finance_item[finance_line_items_attributes][${i}][category_id]`, lineItem.category_id);
    params.append(`finance_item[finance_line_items_attributes][${i}][description]`, lineItem.description);
    params.append(`finance_item[finance_line_items_attributes][${i}][amount]`, parseFloatFromString(lineItem.amount));
    params.append(`finance_item[finance_line_items_attributes][${i}][estimate]`, parseFloatFromString(lineItem.estimate));
    params.append(`finance_item[finance_line_items_attributes][${i}][actual]`, parseFloatFromString(lineItem.actual));

    if(lineItem._destroy) {
      params.append(`finance_item[finance_line_items_attributes][${i}][_destroy]`, true);
    }
  });

  financeItem.file_attachments.forEach((fileAttachment, i) => {
    if(fileAttachment.id > 0) {
      params.append(`finance_item[file_attachments_attributes][${i}][id]`, fileAttachment.id);
      params.append(`finance_item[file_attachments_attributes][${i}][attachment_attributes][id]`, fileAttachment.attachment.id);

      if(fileAttachment._destroy) {
        params.append(`finance_item[file_attachments_attributes][${i}][_destroy]`, true);
        params.append(`finance_item[file_attachments_attributes][${i}][attachment_attributes][_destroy]`, true);
      }
    } else {
      params.append(`finance_item[file_attachments_attributes][${i}][attachment_attributes][team_id]`, fileAttachment.attachment.team_id);
      params.append(`finance_item[file_attachments_attributes][${i}][attachment_attributes][team_membership_id]`, fileAttachment.attachment.team_membership_id);
      params.append(`finance_item[file_attachments_attributes][${i}][attachment_attributes][file]`, fileAttachment.attachment.file);
    }
  });

  return params;
};


export const createFinanceItem = (dispatch, csrfToken, team, confirm, financeItem, offersToUpdate) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/finance_items",
      financeItemParams(financeItem, confirm),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
      .then(({ data }) => {
        dispatch(financeItemCreated(data));
        dispatch(financeItemToEditChanged({}));

        dispatch(fetchProfitAndLoss(dispatch, csrfToken, team, confirm));

        offersToUpdate.forEach((offer) => {
          dispatch(fetchOfferTotalDue(dispatch, team, offer));
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const updateFinanceItem = (dispatch, csrfToken, team, confirm, financeItem, offersToUpdate) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/teams/" + team.id + "/finance_items/" + financeItem.id,
      financeItemParams(financeItem),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
      .then(({ data }) => {
        dispatch(financeItemUpdated(data));
        dispatch(financeItemToEditChanged({}));

        dispatch(fetchProfitAndLoss(dispatch, csrfToken, team, confirm));

        offersToUpdate.forEach((offer) => {
          dispatch(fetchOfferTotalDue(dispatch, team, offer));
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const financeItemCreated = (financeItem) => ({
  type: FINANCE_ITEM_CREATED,
  financeItem
});

export const financeItemUpdated = (financeItem) => ({
  type: FINANCE_ITEM_UPDATED,
  financeItem
});

export const showWidgetCodeChanged = (showWidgetCode) => ({
  type: SHOW_WIDGET_CODE_CHANGED,
  showWidgetCode
});

export const editingConfirmWebsiteChanged = (editingConfirmWebsite) => ({
  type: EDITING_CONFIRM_WEBSITE_CHANGED,
  editingConfirmWebsite
});

export const editingConfirmWebsiteValueChanged = (editingConfirmWebsiteValue) => ({
  type: EDITING_CONFIRM_WEBSITE_VALUE_CHANGED,
  editingConfirmWebsiteValue
});

export const fetchOfferTotalDue = (dispatch, team, offer) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/offers/" + offer.id + "/settlement.json")
      .then(({ data }) => {
        var updated = Object.assign({}, offer, {
          total_due: data.total_due
        });

        dispatch(offerChanged(updated));
      })
  };
};

export const folderChanged = (folder) => ({
  type: FOLDER_CHANGED,
  folder
});

export const profitAndLossChanged = (profitAndLoss) => ({
  type: PROFIT_AND_LOSS_CHANGED,
  profitAndLoss
});

export const fetchProfitAndLoss = (dispatch, csrfToken, team, confirm) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.get(`/teams/${team.id}/confirms/${confirm.id}/profit_and_loss.json`)
      .then(({ data }) => {
        dispatch(profitAndLossChanged({
          ...data,
          incomeTotal: parseFloat(data.incomeTotal),
          incomeForecastTotal: parseFloat(data.incomeForecastTotal),
          incomeGrossPotential: parseFloat(data.incomeGrossPotential),
          expenseTotal: parseFloat(data.expenseTotal),
          expenseForecastTotal: parseFloat(data.expenseForecastTotal),
          expenseGrossPotential: parseFloat(data.expenseGrossPotential),
          profitLoss: parseFloat(data.profitLoss),
          profitLossForecast: parseFloat(data.profitLossForecast)
        }));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  }
};

export const holdGroupDeletionChanged = (holdGroupDeletion) => ({
  type: HOLD_GROUP_DELETION_CHANGED,
  holdGroupDeletion
});

export const deleteHoldGroup = (dispatch, csrfToken, team, holdId, holdGroupDeletion) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/hold_groups/" + holdId, {
        data: {
          hold_group_deletion: {
            reason: holdGroupDeletion.reason,
            explanation: holdGroupDeletion.explanation,
            follow_up_at: holdGroupDeletion.followUpAt
          }
        }
      })
      .then(({ data }) => {
        window.location.replace("/dashboard");
      })
      .catch((error) => {
        toast.error("Unable to delete hold. Please try again later.", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const holdToConfirmChanged = (holdToConfirm) => ({
  type: HOLD_TO_CONFIRM_CHANGED,
  holdToConfirm
});

export const userChangedDoorsTimeChanged = (userChangedDoorsTime) => ({
  type: USER_CHANGED_DOORS_TIME_CHANGED,
  userChangedDoorsTime
});

export const userChangedEventEndTimeChanged = (userChangedEventEndTime) => ({
  type: USER_CHANGED_EVENT_END_TIME_CHANGED,
  userChangedEventEndTime
});

const convertHoldGroupToConfirmParams = (holdToConfirm) => {
  var params = {
    door_time: offsetDateForServer(holdToConfirm.door_time),
    end_time: offsetDateForServer(holdToConfirm.end_time),
    calendar_classification: "confirm",
    event_template_id: (holdToConfirm.event_template ? holdToConfirm.event_template.value : null),
    hold_positions_attributes: holdToConfirm.hold_positions.map((holdPosition) => {
      if(holdToConfirm.selectedHoldPositionId === holdPosition.id){
        return {
          id: holdPosition.id,
          start_time: offsetDateForServer(holdToConfirm.start_time),
          position: 1
        }
      } else {
        return {
          id: holdPosition.id,
          _destroy: true
        }
      }
    })
  }

  return params;
}

export const convertHoldGroupToConfirm = (dispatch, csrfToken, team, holdToConfirm) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    dispatch(isConvertingHoldToConfirmChanged(true));

    return axios.post("/teams/" + team.id + "/hold_groups/" + holdToConfirm.id + "/convert_to_confirm", {
        confirm: convertHoldGroupToConfirmParams(holdToConfirm)
      })
      .then(({ data }) => {
        toast.success("Event has been confirmed. Refreshing the page in 5 seconds...", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });

        dispatch(holdToConfirmChanged({}));

        setTimeout(() => {
          location.reload();
        }, 5000);
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .finally(() => {
        dispatch(isConvertingHoldToConfirmChanged(false));
      });
  }
}

export const isConvertingHoldToConfirmChanged = (isConvertingHoldToConfirm) => ({
  type: IS_CONVERTING_HOLD_TO_CONFIRM_CHANGED,
  isConvertingHoldToConfirm
});

export const possibleMatchingOffersChanged = (possibleMatchingOffers) => ({
  type: POSSIBLE_MATCHING_OFFERS_CHANGED,
  possibleMatchingOffers
});

export const fetchPossibleMatchingOffers = (dispatch, csrfToken, team, confirm) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/offers/possible_matches", {
        params: {
          calendar_event_id: confirm.id
        }
      })
      .then(({ data }) => {
        dispatch(possibleMatchingOffersChanged(data));
      });
  };
};

export const dismissMatchingOffers = (dispatch, csrfToken, team, confirm, possibleMatchingOffers) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/dismissed_matching_offers", {
        dismissed_matching_offer: {
          calendar_event_id: confirm.id,
          offer_ids: possibleMatchingOffers.map((e) => e.id)
        }
      })
      .then(({ data }) => {
        dispatch(possibleMatchingOffersChanged([]));
      })
      .catch((error) => {
        toast.error("Unable to dismiss matching offers. Please try again later.", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const acceptMatchingOffer = (dispatch, csrfToken, team, confirm, offer, performer) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/teams/" + team.id + "/offers/" + offer.id, {
        offer: {
          calendar_event_id: confirm.id,
          performer_id: performer.id
        }
      })
      .then(({ data }) => {
        dispatch(offerAdded(data));
        dispatch(possibleMatchingOfferRemoved(data));

        var updatedPerformer = Object.assign({}, performer, {
          offer: data
        });

        dispatch(performerChanged(updatedPerformer));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const offerAdded = (offer) => ({
  type: OFFER_ADDED,
  offer
});

export const possibleMatchingOfferRemoved = (possibleMatchingOffer) => ({
  type: POSSIBLE_MATCHING_OFFER_REMOVED,
  possibleMatchingOffer
});
