// Simple example of a React "smart" component

import { connect } from 'react-redux';
import WebOrderForm from '../components/WebOrderForm';
import * as actions from '../actions/webOrderFormActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  team: state.team,
  csrfToken: state.csrfToken,
  confirm: state.confirm,
  ticketTypes: state.ticketTypes,
  promoCode: state.promoCode,
  promoCodeName: state.promoCodeName,
  promoCodeNameValid: state.promoCodeNameValid,
  ticketReservation: state.ticketReservation,
  stripePromise: state.stripePromise,
  stripePublishableKey: state.stripePublishableKey,
  stripeError: state.stripeError,
  rootUrl: state.rootUrl,
  checkoutTimePercentageRemaining: state.checkoutTimePercentageRemaining,
  timeLimitReached: state.timeLimitReached,
  isPlacingOrder: state.isPlacingOrder,
  addOns: state.addOns,
  googleTrackingId: state.googleTrackingId,
  gtag: state.gtag,
  customFieldValues: state.customFieldValues,
  userContext: state.userContext,
  seatsIOPublicKey: state.seatsIOPublicKey,
  seatsIOChart: state.seatsIOChart,
  seatsIOSelectedObjects: state.seatsIOSelectedObjects,
  customerioId: state.customerioId,
  isCreatingTicketReservation: state.isCreatingTicketReservation,
  verticalInsureClientID: state.verticalInsureClientID,
  insuranceOfferState: state.insuranceOfferState,
  ticketInsurance: state.ticketInsurance,
  hasInsuranceQuote: state.hasInsuranceQuote,
  announceListEnabled: state.announceListEnabled,
  waitlistEnabled: state.waitlistEnabled,
  donationCustomChargeType: state.donationCustomChargeType,
  feeRules: state.feeRules
});

const mapDispatchToProps = (dispatch) => {
  return {
    confirmChanged: (confirm) => dispatch(actions.confirmChanged(confirm)),
    channelMsgReceived: (data) => dispatch(actions.channelMsgReceived(dispatch, data)),
    lookupPromoCode: (confirm, name, ticketTypes, csrfToken, addOns, ticketReservation, userContext) => dispatch(actions.lookupPromoCode(dispatch, confirm, name, ticketTypes, csrfToken, addOns, ticketReservation, userContext)),
    promoCodeNameChanged: (name) => dispatch(actions.promoCodeNameChanged(name)),
    promoCodeChanged: (promoCode) => dispatch(actions.promoCodeChanged(promoCode)),
    ticketTypesChanged: (ticketTypes) => dispatch(actions.ticketTypesChanged(ticketTypes)),
    buildTicketReservation: (csrfToken, confirm, ticketTypes, promoCode, addOns, ticketReservation, userContext) => dispatch(actions.buildTicketReservation(dispatch, csrfToken, confirm, ticketTypes, promoCode, addOns, ticketReservation, userContext)),
    ticketReservationChanged: (ticketReservation) => dispatch(actions.ticketReservationChanged(ticketReservation)),
    createTicketReservation: (csrfToken, confirm, ticketTypes, promoCode, addOns, ticketReservation, seatsIOChart, customerioId, userContext) => dispatch(actions.createTicketReservation(dispatch, csrfToken, confirm, ticketTypes, promoCode, addOns, ticketReservation, seatsIOChart, customerioId, userContext)),
    deleteTicketReservation: (csrfToken, confirm, ticketReservation) => dispatch(actions.deleteTicketReservation(dispatch, csrfToken, confirm, ticketReservation)),
    stripePromiseChanged: (stripePromise) => dispatch(actions.stripePromiseChanged(stripePromise)),
    stripeErrorChanged: (error) => dispatch(actions.stripeErrorChanged(error)),
    updateTicketReservation: (csrfToken, confirm, ticketReservation, onSuccess, customFieldValues) => dispatch(actions.updateTicketReservation(dispatch, csrfToken, confirm, ticketReservation, onSuccess, customFieldValues)),
    checkoutTimePercentageRemainingChanged: (percentage) => dispatch(actions.checkoutTimePercentageRemainingChanged(percentage)),
    timeLimitReachedChanged: (reached) => dispatch(actions.timeLimitReachedChanged(reached)),
    isPlacingOrderChanged: (isPlacingOrder) => dispatch(actions.isPlacingOrderChanged(isPlacingOrder)),
    addOnsChanged: (addOns) => dispatch(actions.addOnsChanged(addOns)),
    gtagChanged: (gtag) => dispatch(actions.gtagChanged(gtag)),
    customFieldValueChanged: (customFieldValue) => dispatch(actions.customFieldValueChanged(customFieldValue)),
    cancelCheckout: () => dispatch(actions.cancelCheckout()),
    seatsIOChartChanged: (seatsIOChart) => dispatch(actions.seatsIOChartChanged(seatsIOChart)),
    seatsIOSelectedObjectsChanged: (seatsIOSelectedObjects) => dispatch(actions.seatsIOSelectedObjectsChanged(seatsIOSelectedObjects)),
    autoSaveTicketReservation: (csrfToken, confirm, ticketReservation) => dispatch(actions.autoSaveTicketReservation(dispatch, csrfToken, confirm, ticketReservation)),
    customerioIdChanged: (customerioId) => dispatch(actions.customerioIdChanged(customerioId)),
    saveGeoCodedStateCode: (csrfToken, confirm, ticketReservation, stateCode) => dispatch(actions.saveGeoCodedStateCode(dispatch, csrfToken, confirm, ticketReservation, stateCode)),
    registerTicketInsurance: (csrfToken, confirm, ticketReservation, quote) => dispatch(actions.registerTicketInsurance(dispatch, csrfToken, confirm, ticketReservation, quote)),
    insuranceOfferStateChanged: (insuranceOfferState) => dispatch(actions.insuranceOfferStateChanged(insuranceOfferState)),
    deleteTicketInsurance: (csrfToken, confirm, ticketInsurance) => dispatch(actions.deleteTicketInsurance(dispatch, csrfToken, confirm, ticketInsurance)),
    hasInsuranceQuoteChanged: (hasInsuranceQuote) => dispatch(actions.hasInsuranceQuoteChanged(hasInsuranceQuote)),
    donationCustomChargeTypeChanged: (donationCustomChargeType) => dispatch(actions.donationCustomChargeTypeChanged(donationCustomChargeType))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(WebOrderForm);
