/* eslint-disable import/prefer-default-export */

export const BASIC_INFO_MENU_ITEM = 'BASIC_INFO_MENU_ITEM';
export const DETAILS_MENU_ITEM = 'DETAILS_MENU_ITEM';
export const TICKETS_MENU_ITEM = 'TICKETS_MENU_ITEM';
export const AUTOMATION_MENU_ITEM = 'AUTOMATION_MENU_ITEM';
export const PROMOTION_MENU_ITEM = 'PROMOTION_MENU_ITEM';
export const PUBLISH_MENU_ITEM = 'PUBLISH_MENU_ITEM';
export const SETTINGS_MENU_ITEM = 'SETTINGS_MENU_ITEM';

export const TICKETING_ADMISSION_MENU_ITEM = 'TICKETING_ADMISSION_MENU_ITEM';
export const TICKETING_PROMO_CODES_MENU_ITEM = 'TICKETING_PROMO_CODES_MENU_ITEM';
export const TICKETING_ADD_ONS_MENU_ITEM = 'TICKETING_ADD_ONS_MENU_ITEM';
export const TICKETING_SETTINGS_MENU_ITEM = 'TICKETING_SETTINGS_MENU_ITEM';

export const ACTIVE_MENU_ITEM_CHANGED = 'ACTIVE_MENU_ITEM_CHANGED';
export const ACTIVE_TICKETING_MENU_ITEM_CHANGED = 'ACTIVE_TICKETING_MENU_ITEM_CHANGED';
export const UPDATE_CONFIRM = 'UPDATE_CONFIRM';
export const CONFIRM_CHANGED = 'CONFIRM_CHANGED';

export const PERFORMER_REMOVED = 'PERFORMER_REMOVED';
export const PERFORMER_ADDED = 'PERFORMER_ADDED';
export const PERFORMER_UPDATED = 'PERFORMER_UPDATED';
export const PERFORMER_DRAGGED = 'PERFORMER_DRAGGED';

export const PAGE_DETAIL_CREATED = 'PAGE_DETAIL_CREATED';
export const PAGE_DETAIL_REMOVED = 'PAGE_DETAIL_REMOVED';
export const PAGE_DETAIL_CHANGED = 'PAGE_DETAIL_CHANGED';
export const PAGE_DETAIL_DRAGGED = 'PAGE_DETAIL_DRAGGED';

export const TICKET_TYPE_FOR_FORM_CHANGED = 'TICKET_TYPE_FOR_FORM_CHANGED';
export const TICKET_TYPE_DELETED = 'TICKET_TYPE_DELETED';
export const TICKET_TYPE_ADDED = 'TICKET_TYPE_ADDED';
export const TICKET_TYPE_UPDATED = 'TICKET_TYPE_UPDATED';
export const TICKET_TYPE_DRAGGED = 'TICKET_TYPE_DRAGGED';
export const TICKET_TYPES_CHANGED = 'TICKET_TYPES_CHANGED';

export const PROMO_CODE_FOR_FORM_CHANGED = 'PROMO_CODE_FOR_FORM_CHANGED';
export const PROMO_CODE_DELETED = 'PROMO_CODE_DELETED';
export const PROMO_CODE_ADDED = 'PROMO_CODE_ADDED';
export const PROMO_CODE_UPDATED = 'PROMO_CODE_UPDATED';

export const ADD_ONS_CHANGED = 'ADD_ONS_CHANGED';
export const ADD_ON_FOR_FORM_CHANGED = 'ADD_ON_FOR_FORM_CHANGED';
export const ADD_ON_ADDED = 'ADD_ON_ADDED';
export const ADD_ON_DELETED = 'ADD_ON_DELETED';
export const ADD_ON_UPDATED = 'ADD_ON_UPDATED';
export const ADD_ON_DRAGGED = 'ADD_ON_DRAGGED';

export const PAGE_DETAIL_TEMPLATES_CHANGED = 'PAGE_DETAIL_TEMPLATES_CHANGED';
export const PAGE_DETAIL_TEMPLATE_DELETED = 'PAGE_DETAIL_TEMPLATE_DELETED';
export const PAGE_DETAIL_TEMPLATE_ADDED = 'PAGE_DETAIL_TEMPLATE_ADDED';

export const MAIN_EVENT_IMAGE_LOADING_CHANGED = 'MAIN_EVENT_IMAGE_LOADING_CHANGED';
export const STATEMENT_DESCRIPTOR_SUFFIX_CHANGED = 'STATEMENT_DESCRIPTOR_SUFFIX_CHANGED';
export const ARTIST_BIOS_CHANGED = 'ARTIST_BIOS_CHANGED';
export const PAGE_DETAIL_TEMPLATE_CHANGED = 'PAGE_DETAIL_TEMPLATE_CHANGED';

export const EXAMPLE_TICKET_CHANGED = 'EXAMPLE_TICKET_CHANGED';
export const IS_LOADING_DEFAULT_TICKET_TYPES_CHANGED = 'IS_LOADING_DEFAULT_TICKET_TYPES_CHANGED';
export const IS_EDITING_ADD_ONS_INTRO_CHANGED = 'IS_EDITING_ADD_ONS_INTRO_CHANGED';
export const ADD_ONS_INTRO_EDITOR_STATE_CHANGED = 'ADD_ONS_INTRO_EDITOR_STATE_CHANGED';
export const IS_LOADING_DEFAULT_ADD_ONS_CHANGED = 'IS_LOADING_DEFAULT_ADD_ONS_CHANGED';
export const IS_EDITING_ADD_ONS_ALERT_MESSAGE_CHANGED = 'IS_EDITING_ADD_ONS_ALERT_MESSAGE_CHANGED';
export const ADD_ONS_ALERT_MESSAGE_EDITOR_STATE_CHANGED = 'ADD_ONS_ALERT_MESSAGE_EDITOR_STATE_CHANGED';

export const REQUIRE_PER_TICKET_CUSTOM_CHARGE_TYPE_ADDED = 'REQUIRE_PER_TICKET_CUSTOM_CHARGE_TYPE_ADDED';
export const REQUIRE_PER_TICKET_CUSTOM_CHARGE_TYPE_CHANGED = 'REQUIRE_PER_TICKET_CUSTOM_CHARGE_TYPE_CHANGED';
export const REQUIRE_PER_TICKET_CUSTOM_CHARGE_TYPE_DELETED = 'REQUIRE_PER_TICKET_CUSTOM_CHARGE_TYPE_DELETED';

export const WARN_BEFORE_LEAVING_CHANGED = 'WARN_BEFORE_LEAVING_CHANGED';
export const WARN_BEFORE_LEAVING_OPEN_CHANGED = 'WARN_BEFORE_LEAVING_OPEN_CHANGED';
export const WARN_BEFORE_LEAVING_CALLBACK_CHANGED = 'WARN_BEFORE_LEAVING_CALLBACK_CHANGED';
