// Simple example of a React "smart" component

import { connect } from 'react-redux';
import FinanceSettingsManager from '../components/FinanceSettingsManager';
import * as actions from '../actions/financeSettingsManagerActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  plaidToken: state.plaidToken,
  dwollaCustomer: state.dwollaCustomer,
  plaidAccountLinks: state.plaidAccountLinks,
  venueOwnerships: state.venueOwnerships
});

const mapDispatchToProps = (dispatch) => {
  return {
    dwollaCustomerChanged: (dwollaCustomer) => dispatch(actions.dwollaCustomerChanged(dwollaCustomer)),
    plaidAccountLinksChanged: (plaidAccountLinks) => dispatch(actions.plaidAccountLinksChanged(plaidAccountLinks)),
    venueOwnershipsChanged: (venueOwnerships) => dispatch(actions.venueOwnershipsChanged(venueOwnerships))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(FinanceSettingsManager);
