import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer } from 'react-toastify';

import CampaignShow from "./CampaignShow";

const CampaignContext = createContext();

export function useCampaignContext() {
  return useContext(CampaignContext);
}

const CampaignProvider = (props) => {
  const {
    csrfToken,
    user,
    team,
    venueOwnership,
    campaign,
    ahoyMessagesDataTableProps,
    sendDomain,
    previouslySent,
  } = props;

  const [ahoyMessageId, setAhoyMessageId] = useState(null);
  const [ahoyMessage, setAhoyMessage] = useState({});

  useEffect(() => {
    if (ahoyMessageId) {
      axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
      axios.get(`/teams/${team.id}/campaigns/${campaign.id}/ahoy_messages/${ahoyMessageId}`)
        .then((response) => {
          setAhoyMessage(response.data);
        });
    } else {
      setAhoyMessage({});
    }
  }, [ahoyMessageId]);

  const value = {
    csrfToken,
    user,
    team,
    venueOwnership,
    campaign,
    ahoyMessagesDataTableProps,
    ahoyMessageId,
    setAhoyMessageId,
    ahoyMessage,
    setAhoyMessage,
    sendDomain,
    previouslySent,
  };

  return (
    <CampaignContext.Provider value={value}>
      {props.children}
    </CampaignContext.Provider>
  );
};

const CampaignContainer = (props) => (
  <CampaignProvider {...props}>
    <ToastContainer />
    <CampaignShow />
  </CampaignProvider>
);

export default CampaignContainer;
