import React, { useRef } from "react";
import Popup from "reactjs-popup";

const broadcastModalContentStyle = {
  textAlign: "left",
  border: "none",
  borderRadius: "8px",
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  width: "80vw",
  padding: "15px"
};

const htmlViewerContainerStyle = {
  height: "60vh",
  maxHeight: "75%",
  padding: "15px",
  margin: "15px",
  border: "1px solid #EEEEEE",
};

const htmlContentStyle = {
  border: "none",
  height: "100%",
  overflow: "scroll",
  width: "100%",
};

const gridStyle = {
  display: "grid",
  gridTemplateColumns: "1fr 6fr",
  gap: "5px",
  paddingVertical: "10px",
};

const labelStyle = {
  textAlign: "right",
  fontWeight: "bold",
}

const AhoyMessageOverlay = ({
  ahoyableContext,
}) => {
  const {
    ahoyMessageId,
    setAhoyMessageId,
    ahoyMessage,
    sendDomain,
  } = ahoyableContext();

  const iframeRef = useRef(null);

  const updateLinks = () => {
    const iframe = iframeRef.current;

    if (iframe && iframe.contentDocument) {
      const iframeLinks = iframe.contentDocument.querySelectorAll("a");

      iframeLinks.forEach(link => {
        link.setAttribute("target", "_blank");
      });
    }
  };

  return (
    <Popup
      modal
      open={!!ahoyMessageId}
      contentStyle={broadcastModalContentStyle}
      closeOnDocumentClick
      onClose={() => setAhoyMessageId(null)}
    >
      {() => (
        <div>
          {!!ahoyMessage.id ? (
            <div>
              <div className="col-xs-auto" style={{textAlign: "right"}}>
                <a href="#"
                    className="text-dark"
                    onClick={e => {e.preventDefault(); setAhoyMessageId(null);}} >
                  <i className="fa fa-times"></i>
                </a>
              </div>
              <div style={gridStyle}>
                <>
                  <div style={labelStyle}>From:</div>
                  <div>{ahoyMessage.email?.from}</div>
                </>
                <>
                  <div style={labelStyle}>To:</div>
                  <div>{`${ahoyMessage.user?.name} <${ahoyMessage.email?.to}>`}</div>
                </>
                <>
                  <div style={labelStyle}>Subject:</div>
                  <div>{ahoyMessage.email?.subject}</div>
                </>
              </div>
              <br/>
              <div style={gridStyle}>
                {ahoyMessage.human_timestamps ? (
                  <>
                    {ahoyMessage.human_timestamps.sent_at ? (
                      <>
                        <div style={labelStyle}>Sent at</div>
                        <div>{ahoyMessage.human_timestamps.sent_at}</div>
                      </>
                    ) : null}
                    {ahoyMessage.human_timestamps.opened_at ? (
                      <>
                        <div style={labelStyle}>Opened at</div>
                        <div>{ahoyMessage.human_timestamps.opened_at}</div>
                      </>
                    ) : null}
                    {ahoyMessage.human_timestamps.clicked_at ? (
                      <>
                        <div style={labelStyle}>Clicked at</div>
                        <div>{ahoyMessage.human_timestamps.clicked_at}</div>
                      </>
                    ) : null}
                    {ahoyMessage.human_timestamps.failed_at ? (
                      <>
                        <div style={labelStyle}>Failed at</div>
                        <div>{ahoyMessage.human_timestamps.failed_at}</div>
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
              <div>
                <div>
                  <div style={htmlViewerContainerStyle}>
                    <iframe
                      ref={iframeRef}
                      srcDoc={ahoyMessage.email?.html_body}
                      style={htmlContentStyle}
                      onLoad={updateLinks}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              Loading...
            </div>
          )}
        </div>
      )}
    </Popup>
  );
};

export default AhoyMessageOverlay;
