import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import * as axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import Flatpickr from "react-flatpickr";
import Popup from "reactjs-popup";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Tippy, { tippy } from '@tippyjs/react';

import {
  offsetDateForBrowser
} from '../../../shared/timeZoneLogic';

const onDayCreate = (dObj, dStr, fp, dayElem) => {
  let dateStr = dayElem.getAttribute('aria-label');
  let date = new Date(dateStr);
  let tooltipContent = "";
  let showTooltip = false;

  let normalizedEventStart = new Date(fp.eventStart.getFullYear(), fp.eventStart.getMonth(), fp.eventStart.getDate());
  let normalizedEventEnd = new Date(fp.eventEnd.getFullYear(), fp.eventEnd.getMonth(), fp.eventEnd.getDate());

  if(date && (fp.isEventStart || fp.isEventEnd)){
    if(fp.isEventStart && date > normalizedEventEnd) {
      tooltipContent = "Event Start must be on or before Event End";
      showTooltip = true;
    }

    if(fp.isEventEnd && date < normalizedEventStart) {
      tooltipContent = "Event End must be on or after Event Start";
      showTooltip = true;
    }
  }

  if(showTooltip) {
    tippy(dayElem, {
      content: tooltipContent,
      zIndex: 999999
    });
  }
};

const advanceSentPopupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "210px",
  "maxHeight": "300px",
  "overflowY": "auto",
  "padding": "12px 20px"
}

const modalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "800px",
  "padding": "20px"
}

const canSubmitForm = (showActivity) => {
  return (
    showActivity
      && showActivity.name
      && showActivity.name.length > 0
      && showActivity.start_date_midday
      && showActivity.start_time_with_wrong_date
  );
}

const canAddNewShowActivity = (showActivities) => {
  return !showActivities.some((showActivity) => !showActivity.id);
}

const parseDateTime = (dateTime) => {
  if (dateTime){
    if (typeof(dateTime) === "string") {
      var dateObj = new Date(dateTime);
      return dateObj;
    } else {
      return dateTime;
    }
  }
}

const getShowActivity = (showActivities, name) => {
  if (showActivities.length > 0) {
    return showActivities.find((showActivity) => showActivity.name === name);
  }
}

const flatPickrFormat = "n/j/y at h:iK"

const ManageShowActivities = ({
  csrfToken,
  team,
  confirm,
  currentUser,
  fetchConfirmShowActivities,
  showActivities,
  deleteShowActivity,
  editShowActivity,
  cancelEditShowActivity,
  addNewShowActivity,
  showActivityNameChanged,
  showActivityStartDateChanged,
  showActivityStartTimeChanged,
  showActivityCopy,
  createShowActivity,
  updateShowActivity,
  showAdvanceSends
}) => {
  const [searchedShowActivities, setSearchedShowActivities] = useState([]);
  const [selectedShowActivity, setSelectedShowActivity] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [controller, setController] = useState(null);
  const [isAddShowActivityOpen, setIsAddShowActivityOpen] = useState(false);

  useEffect(() => {
    fetchConfirmShowActivities(csrfToken, team, confirm);
  }, [])

  const handleOnSearchShowActivities = async (query) => {
    setIsLoading(true);

    controller && controller.abort()
    const newController = new AbortController();
    setController(newController)

    const searchedShowActivities = await axios.get(`/teams/${team.id}/show_activity_settings/autocomplete?name=${query}`,{
        signal: newController.signal
     });

    setIsLoading(false);
    setSearchedShowActivities(searchedShowActivities.data.concat({ name: `Add "${query}"` }));
  };

  useEffect(() => {
    if (selectedShowActivity.length > 0) {
      handleOnSearchShowActivities(selectedShowActivity[0].name);
    }
  }, [selectedShowActivity])

  const addNewShowActivityDetail = async () => {
    axios.post(
      `/teams/${team.id}/show_activity_settings`,
      {show_activity_setting: selectedShowActivity[0]}
    )
    .then(function (response) {
      setIsAddShowActivityOpen(false);

      toast.success("Saved a new show activity detail", {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: false,
        autoClose: 5000,
        hideProgressBar: true
      });
    })
    .catch(function (error) {
      let message;
      try {
        message = error.response.data[0];
      }
      catch (err) {
        message = "Something went wrong while saving a new show activity detail";
      }

      toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: true,
        autoClose: 5000,
        hideProgressBar: true
      });
    });
  }

  const canManageActivities = currentUser.can_manage_show_activities
    && confirm.calendar_classification !== "past";

  return (
    <div>
      <ToastContainer />
      <div className="card-body pb-0">
        <div className="row pb-3">
          <div className="col">
            <h5 className="strong">Show Details</h5>
          </div>
          {showAdvanceSends.length > 0 ? (
            <div className="col-xs-auto"
                 style={{"paddingRight": "15px"}}>
              <Popup arrow={false}
                    offsetY={5}
                    position="bottom right"
                    contentStyle={advanceSentPopupContentStyle}
                    on={"hover"}
                    trigger={open => (
                      <a href="#"
                         onClick={
                           (e) => {
                             e.preventDefault();
                           }
                         }
                         className="text-muted">
                        <i className="fal fa-paper-plane mr-2"></i>
                        <u>Advance Sent</u>
                      </a>
                    )} >
               {close => (
                 <div className="row">
                   <div className="col-12">
                     {showAdvanceSends.map((showAdvanceSend, index) =>
                       <p key={index}
                          className={"small text-muted " + (index + 1 === showAdvanceSends.length ? "mb-0" : "")}>
                        Advance sent on {showAdvanceSend.human_created_at} to {showAdvanceSend.to_as_sentence}
                       </p>
                     )}
                   </div>
                 </div>
               )}
              </Popup>
            </div>
          ) : null}
        </div>
      </div>
      {showActivities.length === 0 ? (
        <div className="card-body pt-0">
          <div className="card no-border mb-0" style={{'background': '#f4f9fc'}}>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 order-md-2 text-left text-md-center">
                  <i className="fal fa-clipboard-list"
                     style={{"fontSize": "120px", "color": "rgba(25, 130, 196, .5)"}}></i>
                </div>
                <div className="col-12 col-md-6 col-lg-8 order-md-1">
                  <h5>Add Show Details</h5>
                  <p className="text-muted">
                    Build your show schedule and share with the team to ensure the event runs smoothly.
                  </p>
                  {canManageActivities ? (
                    <a href="#"
                       onClick={
                         (e) => {
                           e.preventDefault();

                           if(canAddNewShowActivity(showActivities)){
                             addNewShowActivity(showActivityCopy, confirm);
                           }
                         }
                       }
                       className="btn btn-primary">
                      <strong>Add Activity</strong>
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12">
            <form className="form-inline"
                  onSubmit={
                    (e) => {
                      e.preventDefault();

                      var showActivity = showActivities
                        .find((showActivity) => showActivity._editing);

                      if(!showActivity){
                        return false;
                      }

                      if(!canSubmitForm(showActivity)){
                        return false;
                      }

                      if(showActivity.id){
                        updateShowActivity(csrfToken, team, showActivity);
                      } else {
                        createShowActivity(csrfToken, team, showActivity, confirm);
                      }

                      setSelectedShowActivity([]);
                    }
                  }>
              <table className="table table-sm table-borderless vertical-middle"
                     style={{tableLayout: "fixed"}}>
                <colgroup>
                  <col span={1} style={{"width": "40%"}} />
                  <col span={1} style={{"width": "40%"}} />
                  <col span={1} style={{"width": "20%"}} />
                </colgroup>
                <thead>
                  <tr className="text-muted">
                    <th style={{"paddingLeft": "25px"}}>Activity</th>
                    <th>Date and Time</th>
                    <th style={{"paddingRight": "25px"}}>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {showActivities.sort((a, b) => {return new Date(a.start_time) - new Date(b.start_time)}).map((showActivity, index) => (
                    showActivity._editing ? (
                      <tr key={index} style={{"background": "#f4f9fc"}}>
                        {showActivity.required ? (
                          <td className="overflow-ellipsis" style={{"paddingLeft": "25px"}}>
                            <span title={showActivity.name}>
                              {showActivity.name}
                            </span>
                          </td>
                        ) : (
                          <td style={{"paddingLeft": "25px"}}>
                            <div className="form-group">
                              <AsyncTypeahead
                                id="show-activity-search"
                                className="w-100"
                                labelKey="name"
                                filterBy={() => true}
                                isLoading={false}
                                options={searchedShowActivities}
                                placeholder=""
                                useCache={false}
                                selected={selectedShowActivity}
                                onSearch={handleOnSearchShowActivities}
                                onChange={
                                  (value) => {
                                    if (value.length === 0) {
                                      return;
                                    }
                                    if (value[0].name.includes('Add "')) {
                                      setIsAddShowActivityOpen(true);
                                      setSelectedShowActivity(selectedShowActivity);
                                      return;
                                    }

                                    showActivityNameChanged(index, value[0].name);
                                    setSelectedShowActivity(value);
                                  }
                                }
                                onInputChange={
                                  (value) => {
                                    setSelectedShowActivity([{...showActivity, name: value}]);
                                    showActivityNameChanged(index, value);
                                  }
                                }
                              />
                              { selectedShowActivity.length > 0 && (
                                <Popup
                                  open={isAddShowActivityOpen}
                                  contentStyle={modalContentStyle}
                                  closeOnDocumentClick={false}
                                  className={"offer-modal"}
                                  onClose={() => {
                                  }}
                                >
                                  <div>
                                    <div className="row">
                                      <div className="col">
                                        <div className="row">
                                          <div className="col-sm-12">
                                            <h5 style={{"marginBottom": "16px"}}>
                                              <strong>Add a new field</strong>
                                            </h5>
                                          </div>
                                          <div className="col-sm-12">
                                            <p>
                                              Add this new field to show details.
                                            </p>
                                          </div>
                                          <div className="col-sm-12 d-flex align-items-center" style={{"gap": "30px"}}>
                                            <input
                                              type="text"
                                              className="form-control"
                                              value={selectedShowActivity[0].name}
                                              onChange={(e) => {
                                                setSelectedShowActivity([{...selectedShowActivity[0], name: e.target.value}])
                                                showActivityNameChanged(index, e.target.value);
                                              }}
                                            />
                                            <div className="custom-control custom-checkbox custom-checkbox-table d-flex">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                checked={selectedShowActivity[0].default}
                                                onChange={(e) => {
                                                  setSelectedShowActivity([{...selectedShowActivity[0], default: e.target.checked}])
                                                }}
                                                id={`new-show-activity-${index}`}
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={`new-show-activity-${index}`}
                                              >
                                                Default
                                              </label>
                                              <Tippy
                                                content={
                                                  <div style={{
                                                        "fontSize": "12px"
                                                      }}>
                                                    Default fields will automatically be added to Show Details when a new confirm is created.
                                                  </div>
                                                }
                                                placement={'top'}
                                                maxWidth={200}
                                                animation={'shift-away'}
                                                theme='material'
                                              >
                                                <i className="fas fa-info-circle text-muted ml-2"></i>
                                              </Tippy>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="text-right">
                                          <a href="#"
                                            onClick={
                                              (e) => {
                                                e.preventDefault();
                                                setIsAddShowActivityOpen(false);
                                              }
                                            }>
                                            Cancel
                                          </a>
                                          <a href="#"
                                            className="btn btn-primary ml-3"
                                            onClick={
                                              (e) => {
                                                e.preventDefault();
                                                addNewShowActivityDetail();
                                              }
                                            }>
                                            Add
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Popup>
                              )}
                            </div>
                          </td>
                        )}
                        <td>
                          <div className="form-group">
                            <div style={{"backgroundColor": "#fff"}} className="flatpickr-date-input px-2 d-flex align-items-center">
                              <div className="pr-2" style={{"fontSize": "18px"}}>
                                <i className="far fa-calendar"></i>
                              </div>
                              <Flatpickr
                                options={{
                                  onDayCreate: (dObj, dStr, fp, dayElem) => {
                                    fp = {
                                      ...fp,
                                      isEventStart: showActivity.name === "Event Start",
                                      isEventEnd: showActivity.name === "Event End",
                                      eventStart: offsetDateForBrowser(getShowActivity(showActivities, "Event Start").start_time),
                                      eventEnd: offsetDateForBrowser(getShowActivity(showActivities, "Event End").start_time)
                                    }
                                    onDayCreate(dObj, dStr, fp, dayElem);
                                  },
                                  enableTime: true,
                                  dateFormat: flatPickrFormat,
                                  maxDate: (showActivity.name === "Event Start"
                                    ? (offsetDateForBrowser(getShowActivity(showActivities, "Event End")?.start_time || null))
                                    : null
                                  ),
                                  minDate: (showActivity.name === "Event End"
                                    ? (offsetDateForBrowser(getShowActivity(showActivities, "Event Start")?.start_time || new Date()))
                                    : new Date()
                                  )
                                }}
                                placeholder={"Select date / time"}
                                value={showActivity.start_time_with_wrong_date}
                                onChange={dates => {
                                  showActivityStartTimeChanged(index, dates[0]);
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td className="text-right" style={{"paddingRight": "25px"}}>
                          <a href="#"
                             onClick={
                               (e) => {
                                 e.preventDefault();
                                 cancelEditShowActivity(showActivityCopy);
                                 setSelectedShowActivity([]);
                               }
                             }>
                            Cancel
                          </a>
                          <button type="submit"
                                  style={{"borderRadius": "8px"}}
                                  disabled={!canSubmitForm(showActivity)}
                                  className="btn btn-primary btn-sm ml-2">
                            Save
                          </button>
                        </td>
                      </tr>
                    ) : (
                      <tr key={index}>
                        <td className="overflow-ellipsis" style={{"paddingLeft": "25px"}}>
                          <span title={showActivity.name}>
                            {showActivity.name}
                          </span>
                        </td>
                        <td>
                          <span>
                            {showActivity.set ? showActivity.human_start_date : null}
                          </span>
                          <span className="pl-3">
                            {showActivity.set ? showActivity.human_start_time : null}
                          </span>
                        </td>
                        <td className="text-right" style={{"paddingRight": "25px"}}>
                          {canManageActivities ? (
                            <div>
                              {showActivity.show_in_advance ? (
                                <Tippy
                                  content={
                                    <div className='text-center' style={{"fontSize": "10px"}}>
                                      Visible on advance.<br />
                                      Click to hide.
                                    </div>
                                  }
                                  placement={'top'}
                                  animation={'shift-away'}
                                  theme='material'
                                >
                                  <a href="#"
                                      className="text-muted"
                                      onClick={
                                        (e) => {
                                          e.preventDefault();

                                          var updated = Object.assign(showActivity, {}, {
                                            show_in_advance: false
                                          });

                                          updateShowActivity(csrfToken, team, updated);
                                        }
                                      }>
                                    <i className="fas fa-eye mr-2"></i>
                                  </a>
                                </Tippy>
                              ) : (
                                <Tippy
                                  content={
                                    <div className='text-center' style={{"fontSize": "10px"}}>
                                      Hidden from advance.<br />
                                      Click to show.
                                    </div>
                                  }
                                  placement={'top'}
                                  animation={'shift-away'}
                                  theme='material'
                                >
                                  <a href="#"
                                      className="text-muted"
                                      onClick={
                                        (e) => {
                                          e.preventDefault();

                                          var updated = Object.assign(showActivity, {}, {
                                            show_in_advance: true
                                          });

                                          updateShowActivity(csrfToken, team, updated);
                                        }
                                      }>
                                    <i className="fas fa-eye-slash mr-2 text-muted" style={{"opacity": "50%"}}></i>
                                  </a>
                                </Tippy>
                              )}
                              <a href="#"
                                 className="text-muted"
                                 onClick={
                                   (e) => {
                                     e.preventDefault();
                                     editShowActivity(showActivity);
                                     setSelectedShowActivity([showActivity])
                                   }
                                 }>
                                <i className="fas fa-pencil"></i>
                              </a>
                              {showActivity.required ? (
                                <Tippy
                                  content={
                                    <div style={{"fontSize": "12px"}}>
                                      Required
                                    </div>
                                  }
                                  placement={'top'}
                                  animation={'shift-away'}
                                  theme='material'
                                >
                                  <i className="text-optional ml-2 fas fa-lock"></i>
                                </Tippy>
                              ) : (
                                <a href="#"
                                   className="text-muted ml-2"
                                   onClick={
                                     (e) => {
                                       e.preventDefault();
                                       deleteShowActivity(csrfToken, team, showActivity);
                                     }
                                   }>
                                  <i className="fas fa-trash"></i>
                                </a>
                              )}
                            </div>
                          ) : null}
                        </td>
                      </tr>
                    )
                  ))}
                </tbody>
              </table>
            </form>
            {canManageActivities ? (
              <div className="card-body pt-3">
                <a href="#"
                   className={(canAddNewShowActivity(showActivities) ? "" : "disabled")}
                   onClick={
                     (e) => {
                       e.preventDefault();

                       if(canAddNewShowActivity(showActivities)){
                         addNewShowActivity(showActivityCopy, confirm);
                       }
                     }
                   }>
                  <i className="fas fa-plus-circle"></i>
                  &nbsp;
                  <strong>Add Activity</strong>
                </a>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  )
};

ManageShowActivities.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  fetchConfirmShowActivities: PropTypes.func.isRequired,
  showActivities: PropTypes.array,
  deleteShowActivity: PropTypes.func.isRequired,
  editShowActivity: PropTypes.func.isRequired,
  cancelEditShowActivity: PropTypes.func.isRequired,
  addNewShowActivity: PropTypes.func.isRequired,
  showActivityNameChanged: PropTypes.func.isRequired,
  showActivityStartDateChanged: PropTypes.func.isRequired,
  showActivityStartTimeChanged: PropTypes.func.isRequired,
  showActivityCopy: PropTypes.object,
  createShowActivity: PropTypes.func.isRequired,
  updateShowActivity: PropTypes.func.isRequired,
  showAdvanceSends: PropTypes.array
};

export default ManageShowActivities;
