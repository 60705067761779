import { combineReducers } from 'redux';

import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import {EditorState} from 'draft-js';

import {
  OFFER_ADDITIONAL_TERMS_CHANGED_FROM_HTML,
  UPDATE_OFFER,
  ADD_TICKET_LINE_ITEM,
  ADD_INCOME_LINE_ITEM,
  ADD_BEFORE_ADJUSTED_DEDUCTIONS_LINE_ITEM,
  ADD_EXPENSES_LINE_ITEM,
  UPDATE_BUDGET,
  UPDATE_CALCULATED,
  UPDATE_MODAL_UI_STATE,
  IS_SAVING_CHANGED,
  IS_CREATING_TEMPLATE_CHANGED,
  TEMPLATE_SAVED,
  TEMPLATE_UPDATED,
  TEMPLATE_APPLIED,
  TEMPLATE_REMOVED,
  BUDGET_TEMPLATE_NAME_CHANGED,
  BUDGET_TEMPLATE_CHANGED,
  OFFER_TO_LINK_CHANGED,
  BUDGET_BONUS_ADDED,
  ATTACH_ARTIST_MODAL_OPEN_CHANGED,
} from '../constants/offerEditUIConstants';

export const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const offer = (state = {}, action) => {
  switch (action.type) {
    case OFFER_ADDITIONAL_TERMS_CHANGED_FROM_HTML:
      var fromHTML = stateFromHTML(action.html);
      var editorState = EditorState.createWithContent(fromHTML);

      return Object.assign({}, state, {additionalTerms: editorState});
    case UPDATE_OFFER:
      return action.updated;
    case IS_SAVING_CHANGED:
      if(action.isSaving){
        return state;
      } else {
        var now = new Date();
        return Object.assign({}, state, {updated_at: now.toISOString()});
      }
    case TEMPLATE_APPLIED:
      var fromHTML = stateFromHTML(action.budget.additional_terms);
      var editorState = EditorState.createWithContent(fromHTML);

      return Object.assign({}, state, {additionalTerms: editorState});
    default:
      return state;
  }
};

export const budget = (state = {}, action) => {
  switch (action.type) {
    case ADD_TICKET_LINE_ITEM:
      var ticketLineItems = [...state.ticket_line_items, action.ticketLineItem];
      return Object.assign({}, state, {ticket_line_items: ticketLineItems});
    case ADD_INCOME_LINE_ITEM:
      var incomeLineItems = [...state.income_line_items, action.incomeLineItem];
      return Object.assign({}, state, {income_line_items: incomeLineItems});
    case ADD_BEFORE_ADJUSTED_DEDUCTIONS_LINE_ITEM:
      var beforeAdjustedDeductionsLineItems = [...state.before_adjusted_deductions_line_items, action.beforeAdjustedDeductionsLineItem];
      return Object.assign({}, state, {before_adjusted_deductions_line_items: beforeAdjustedDeductionsLineItems});
    case ADD_EXPENSES_LINE_ITEM:
      var expensesLineItems = [...state.expense_line_items, action.expensesLineItem];
      return Object.assign({}, state, {expense_line_items: expensesLineItems});
    case BUDGET_BONUS_ADDED:
      var budgetBonuses = [...state.budget_bonuses, action.budgetBonus];
      return Object.assign({}, state, {budget_bonuses: budgetBonuses});
    case UPDATE_BUDGET:
      return Object.assign({}, state, action.updated);
    case TEMPLATE_APPLIED:
      return action.budget;
    default:
      return state;
  }
};

export const calculated = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CALCULATED:
      return action.updated;
    case TEMPLATE_APPLIED:
      return action.calculated;
    default:
      return state;
  }
};

export const modalUIState = (state = "", action) => {
  switch (action.type) {
    case UPDATE_MODAL_UI_STATE:
      return action.modalUIState;
    default:
      return state;
  }
};

export const isSaving = (state = false, action) => {
  switch (action.type) {
    case IS_SAVING_CHANGED:
      return action.isSaving;
    default:
      return state;
  }
};

export const isCreatingTemplate = (state = false, action) => {
  switch (action.type) {
    case IS_CREATING_TEMPLATE_CHANGED:
      return action.isCreatingTemplate;
    case TEMPLATE_SAVED:
      return false;
    case TEMPLATE_UPDATED:
      return false;
    default:
      return state;
  }
};

export const budgetTemplates = (state = [], action) => {
  switch (action.type) {
    case TEMPLATE_SAVED:
      var newTemplate = {
        value: action.budgetTemplate.id,
        label: action.budgetTemplate.name
      };

      return [...state, newTemplate];
    case TEMPLATE_UPDATED:
      return [...state].map((template) => {
        if(template.value === action.budgetTemplate.id){
          return {
            value: action.budgetTemplate.id,
            label: action.budgetTemplate.name
          };
        } else {
          return template;
        }
      });
    case TEMPLATE_REMOVED:
      return state.filter((t) => t.value !== action.budgetTemplate.id);
    default:
      return state;
  }
};

export const budgetTemplate = (state = {}, action) => {
  switch (action.type) {
    case BUDGET_TEMPLATE_NAME_CHANGED:
      return Object.assign({}, state, {name: action.name});
    case TEMPLATE_SAVED:
      return {};
    case TEMPLATE_UPDATED:
      return {};
    case BUDGET_TEMPLATE_CHANGED:
      return action.budgetTemplate;
    default:
      return state;
  }
};

export const offerToLink = (state = {}, action) => {
  switch (action.type) {
    case OFFER_TO_LINK_CHANGED:
      return action.offer;
    default:
      return state;
  }
};

export const attachArtistModalOpen = (state = false, action) => {
  switch (action.type) {
    case ATTACH_ARTIST_MODAL_OPEN_CHANGED:
      return action.attachArtistModalOpen;
    default:
      return state;
  }
};

const offerEditUIReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  offer,
  budget,
  calculated,
  modalUIState,
  isSaving,
  isCreatingTemplate,
  budgetTemplates,
  budgetTemplate,
  offerToLink,
  attachArtistModalOpen,
});

export default offerEditUIReducer;
