import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Tippy from '@tippyjs/react';

import AddOnsTab from './AddOnsTab';
import PromoCodesTab from './PromoCodesTab';
import SettingsTab from './SettingsTab';
import TicketTypeRow from './TicketTypeRow';
import SlidingPane from "react-sliding-pane";
import {DebounceInput} from 'react-debounce-input';

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

var _ = require('lodash');
var debouncedBuildExampleTicket;

import Select from 'react-select'
import Flatpickr from "react-flatpickr";

import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { offsetDateForBrowser } from '../../../shared/timeZoneLogic';

import {
  TICKETING_ADMISSION_MENU_ITEM,
  TICKETING_PROMO_CODES_MENU_ITEM,
  TICKETING_ADD_ONS_MENU_ITEM,
  TICKETING_SETTINGS_MENU_ITEM,
  AUTOMATION_MENU_ITEM,
  PROMOTION_MENU_ITEM
} from '../constants/confirmTicketingConstants';

export const parseFloatFromString = (value) => {
  if(typeof(value) === "number"){
    return value;
  }

  return parseFloat(value.split(",").join(""));
}

export const parseDateTime = (dateTime) => {
  if (dateTime){
    if (typeof(dateTime) === "string") {
      var dateObj = new Date(dateTime);
      return dateObj;
    } else {
      return dateTime;
    }
  }
}

const canSubmitForm = (ticketType) => {
  var minimumTicketsPerOrder;
  if(ticketType.minimum_tickets_per_order){
    minimumTicketsPerOrder = parseFloatFromString(ticketType.minimum_tickets_per_order);
  }

  var maximumTicketsPerOrder;
  if(ticketType.maximum_tickets_per_order){
    maximumTicketsPerOrder = parseFloatFromString(ticketType.maximum_tickets_per_order);
  }

  var startTime = ticketType.start_time;
  var endTime   = ticketType.end_time;

  var availabilityValid = (
    ticketType
      && ticketType.availability
      && (
        ticketType.availability === "Date & Time"
          && startTime
          && startTime <= endTime
          && endTime
      ) || (
        ticketType.availability === "Based on show details"
          && ticketType.start_show_activity_id !== null
          && ticketType.end_show_activity_id !== null
      ) || (
        (
          ticketType.availability === "When sales end for..."
            || ticketType.availability === "When tickets sell out for..."
            || ticketType.availability === "When ticket sales end or sell out for..."
        )
        && ticketType.wait_for_ticket_type_id
        && endTime
      )
  );

  return (
    ticketType
      && ticketType.name
      && ticketType.available
      && ticketType.price_two_decimals
      && ticketType.settlement_amount_two_decimals
      && minimumTicketsPerOrder
      && maximumTicketsPerOrder
      && minimumTicketsPerOrder <= maximumTicketsPerOrder
      && availabilityValid
  );
};

const minTicketsPerOrderMoreThanMax = (ticketType) => {
  var minimumTicketsPerOrder;
  if(ticketType.minimum_tickets_per_order){
    minimumTicketsPerOrder = parseFloatFromString(ticketType.minimum_tickets_per_order);
  }

  var maximumTicketsPerOrder;
  if(ticketType.maximum_tickets_per_order){
    maximumTicketsPerOrder = parseFloatFromString(ticketType.maximum_tickets_per_order);
  }

  return (
    minimumTicketsPerOrder
      && maximumTicketsPerOrder
      && minimumTicketsPerOrder > maximumTicketsPerOrder
  );
};

export const timeOnDate = (date, time) => {
  date = parseDateTime(date);
  time = parseDateTime(time);

  if(!date || !time){
    return null;
  }

  var month = date.getUTCMonth() + 1;
  var day   = date.getUTCDate();
  var year  = date.getUTCFullYear();

  var copiedTime = new Date(time.getTime());
  copiedTime.setFullYear(year, month, day);

  return copiedTime;
};

const ticketTypeIsSeated = (ticketType) => {
  return (
    ticketType
      && ticketType.seating_chart_category_key
      && ticketType.seating_chart_category_key.length > 0
  )
}

const canChangeTicketingArrangement = (confirm, isLoadingDefaultTicketTypes) => {
  return (
    confirm
      && confirm.can_change_ticketing_arrangement
      && !isLoadingDefaultTicketTypes
  )
}

export const showActivityOption = (showActivity) => {
  const formattedTime = offsetDateForBrowser(showActivity.start_time).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit"
  });

  return {
    value: showActivity.id,
    label: `${showActivity.name} - ${formattedTime}`
  }
}

const manualTaxShouldBeChecked = (item, manualTax) => {
  var foundTaxableItem = (item.taxable_items || []).find((taxableItem) =>
    taxableItem.manual_tax_id === manualTax.id
  );

  return (
    foundTaxableItem !== undefined
      && foundTaxableItem
      && !foundTaxableItem._destroy
  );
}

const calculateLift = (ticketType, ticketTypes) => {
  var bundledId = parseInt(ticketType.bundled_ticket_type_id);
  var bundledTicketType = ticketTypes.find((tt) => tt.id === bundledId);

  var selfAmount = parseFloatFromString(ticketType.settlement_amount_two_decimals || "0.00");
  var bundledAmount = parseFloatFromString(bundledTicketType.settlement_amount_two_decimals || "0.00");
  var calculated = (selfAmount - bundledAmount);

  return (calculated >= 0.00 ? calculated : 0.00);
}

const Tickets = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  activeMenuItemChanged,
  activeTicketingMenuItem,
  activeTicketingMenuItemChanged,
  ticketTypeForForm,
  ticketTypeForFormChanged,
  fetchNewTicketType,
  ticketTypes,
  deleteTicketType,
  fetchTicketTypeForEdit,
  createTicketType,
  updateTicketType,
  ticketTypeDragged,
  addOns,
  addOnForForm,
  fetchNewAddOn,
  addOnForFormChanged,
  createAddOn,
  fetchAddOnToEdit,
  deleteAddOn,
  updateAddOn,
  addOnDragged,
  exampleTicket,
  buildExampleTicket,
  exampleTicketChanged,
  seatingCharts,
  autoSaveConfirm,
  updateConfirm,
  createDefaultTicketTypes,
  isLoadingDefaultTicketTypes,
  addOnSeatingCharts,
  isEditingAddOnsIntro,
  isEditingAddOnsIntroChanged,
  addOnsIntroEditorState,
  addOnsIntroEditorStateChanged,
  createDefaultAddOns,
  isLoadingDefaultAddOns,
  isEditingAddOnsAlertMessage,
  isEditingAddOnsAlertMessageChanged,
  addOnsAlertMessageEditorState,
  addOnsAlertMessageEditorStateChanged,
  showActivities,
  manualTaxes,
  addOnAdded,
  feeRuleSets
}) => {
  useEffect(() => {
    debouncedBuildExampleTicket = _.debounce((csrfToken, team, confirm, ticketType) => {
      buildExampleTicket(csrfToken, team, confirm, ticketType);
    }, 500);
  }, []);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <h3 style={{"fontSize": "20px"}}
              className="mb-3">
            <strong>
              Tickets
            </strong>
          </h3>
          <ul className="nav primary-nav-tabs">
            <li className="nav-item">
              <a className={"nav-link " + (activeTicketingMenuItem === TICKETING_ADMISSION_MENU_ITEM ? "active" : "")}
                 onClick={
                   (e) => {
                     e.preventDefault();
                     activeTicketingMenuItemChanged(TICKETING_ADMISSION_MENU_ITEM);
                   }
                 }
                 href="#">
                <strong>Admission</strong>
              </a>
            </li>
            <li className="nav-item">
              <a className={"nav-link " + (activeTicketingMenuItem === TICKETING_ADD_ONS_MENU_ITEM ? "active" : "")}
                 onClick={
                   (e) => {
                     e.preventDefault();
                     activeTicketingMenuItemChanged(TICKETING_ADD_ONS_MENU_ITEM);
                   }
                 }
                 href="#">
                <strong>Add-ons</strong>
              </a>
            </li>
            <li className="nav-item">
              <a className={"nav-link " + (activeTicketingMenuItem === TICKETING_PROMO_CODES_MENU_ITEM ? "active" : "")}
                 onClick={
                   (e) => {
                     e.preventDefault();
                     activeTicketingMenuItemChanged(TICKETING_PROMO_CODES_MENU_ITEM);
                   }
                 }
                 href="#">
                <strong>Promo Codes</strong>
              </a>
            </li>
            <li className="nav-item">
              <a className={"nav-link " + (activeTicketingMenuItem === TICKETING_SETTINGS_MENU_ITEM ? "active" : "")}
                 onClick={
                   (e) => {
                     e.preventDefault();
                     activeTicketingMenuItemChanged(TICKETING_SETTINGS_MENU_ITEM);
                   }
                 }
                 href="#">
                <strong>Settings</strong>
              </a>
            </li>
          </ul>
          {(activeTicketingMenuItem === TICKETING_ADMISSION_MENU_ITEM ? (
            <React.Fragment>
              <div className="row mt-4">
                <div className="col">
                  {seatingCharts.length > 0 ? (
                    <React.Fragment>
                    <div className="btn-group btn-group-radio"
                         style={{"marginBottom": "13px"}}>
                      <button type="button"
                              onClick={
                                (e) => {
                                  var updated = Object.assign({}, confirm, {
                                    ticketing_arrangement: "Seated"
                                  });

                                  if(canChangeTicketingArrangement(confirm, isLoadingDefaultTicketTypes)){
                                    updateConfirm(updated);
                                    autoSaveConfirm(csrfToken, team, updated);
                                    createDefaultTicketTypes(csrfToken, team, updated, null, true);
                                  }
                                }
                              }
                              style={{
                                "paddingLeft": "30px",
                                "paddingRight": "30px"
                              }}
                              className={"btn small " + (confirm.ticketing_arrangement === "Seated" ? "btn-primary " : "btn-link ") + (!canChangeTicketingArrangement(confirm, isLoadingDefaultTicketTypes) ? "disabled" : "")}>
                        <strong>Seated</strong>
                      </button>
                      <button type="button"
                              onClick={
                                (e) => {
                                  var updated = Object.assign({}, confirm, {
                                    ticketing_arrangement: "General Admission",
                                    seating_chart_id: ""
                                  });

                                  if(canChangeTicketingArrangement(confirm, isLoadingDefaultTicketTypes)){
                                    updateConfirm(updated);
                                    autoSaveConfirm(csrfToken, team, updated);
                                    createDefaultTicketTypes(csrfToken, team, updated, null, false);
                                  }
                                }
                              }
                              style={{
                                "borderTopRightRadius": "20px",
                                "borderBottomRightRadius": "20px"
                              }}
                              className={"btn small " + (confirm.ticketing_arrangement === "General Admission" ? "btn-primary " : "btn-link ") + (!canChangeTicketingArrangement(confirm, isLoadingDefaultTicketTypes) ? "disabled" : "")}>
                        <strong>General Admission</strong>
                      </button>
                    </div>
                    </React.Fragment>
                  ) : null}
                </div>
                {confirm.ticketing_arrangement === "General Admission" ? (
                  <div className="col d-flex justify-content-end"
                       style={{
                         "marginBottom": "13px",
                         "paddingRight": "15px"
                       }}>
                    <a href="#"
                       className="btn btn-outline-primary small"
                       style={{
                         "borderWidth": "1px",
                         "borderRadius": "4px"
                       }}
                       onClick={
                         (e) => {
                           e.preventDefault();
                           fetchNewTicketType(csrfToken, team, confirm);
                         }
                       }>
                      <strong>
                        Add Ticket
                      </strong>
                    </a>
                  </div>
                ) : null}
                {confirm.ticketing_arrangement === "Seated" && confirm.seating_chart_id && seatingCharts.length > 1 ? (
                  <div className="col d-flex justify-content-end"
                       style={{
                         "marginBottom": "13px",
                         "paddingRight": "15px"
                       }}>
                    <select className="form-control form-control-sm text-primary small"
                           style={{
                             "border": "1px solid #1982C4",
                             "borderRadius": "4px",
                             "fontWeight": "bold",
                             "maxWidth": "200px",
                             "minWidth": "100px"
                           }}
                           disabled={!canChangeTicketingArrangement(confirm, isLoadingDefaultTicketTypes)}
                           onChange={
                             (e) => {
                               var seatingChart = {id: e.target.value};
                               var updated = Object.assign({}, confirm, {
                                 seating_chart_id: seatingChart.id
                               });

                               updateConfirm(updated);
                               autoSaveConfirm(csrfToken, team, updated);
                               createDefaultTicketTypes(csrfToken, team, updated, seatingChart, false);
                             }
                           }
                           value={confirm.seating_chart_id || ""}>
                     {seatingCharts.map((seatingChart, index) =>
                       <option value={seatingChart.id} key={index}>
                        {seatingChart.name}
                      </option>
                     )}
                    </select>
                  </div>
                ) : null}
              </div>
              {seatingCharts.length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    {confirm.ticketing_arrangement === "Seated" && !confirm.seating_chart_id ? (
                      <p className="text-muted small mb-0">
                        Select your seating configuration.
                      </p>
                    ) : confirm.ticketing_arrangement === "Seated" && confirm.seating_chart_id ? (
                      <p className="text-muted small mb-0">
                        Ticket types are defined by your seating configuration. You can modify pricing for each ticket type.
                      </p>
                    ) : (
                      <p className="text-muted small mb-0">
                        Create your tickets.
                      </p>
                    )}
                  </div>
                </div>
              ) : null}
              {isLoadingDefaultTicketTypes ? (
                <div className="form-row mt-3">
                  <div className="col-12 text-center">
                    <img src="/uploading-loading.gif"
                         style={{width: "50px"}} />
                  </div>
                </div>
              ) : seatingCharts.length > 0 && confirm.ticketing_arrangement === "Seated" && !confirm.seating_chart_id ? (
                <div className="form-row" style={{"marginTop": "14px"}}>
                  {seatingCharts.map((seatingChart, index) =>
                    <div className="col-12 col-xl-6" key={index}>
                      <div className="card clickable clickable-card"
                           onClick={
                             (e) => {
                               e.preventDefault();
                               var updated = Object.assign({}, confirm, {
                                 seating_chart_id: seatingChart.id
                               });

                               updateConfirm(updated);
                               autoSaveConfirm(csrfToken, team, updated);
                               createDefaultTicketTypes(csrfToken, team, updated, seatingChart, false);
                             }
                           }
                           style={{
                             "borderColor": "#f3f3f3",
                             "borderWidth": "1px"
                           }}>
                        <div className="card-body"
                             style={{"padding": "18px 6px"}}>
                          <div className="form-row">
                            <div className="col-xs-auto" style={{"paddingLeft": "15px"}}>
                              <img src={seatingChart.preview_thumb_url}
                                   className="img-fluid"
                                   style={{
                                     "width": "120px",
                                     "height": "120px"
                                   }} />
                            </div>
                            <div className="col">
                              <p className="text-dark"
                                 style={{
                                   "fontSize": "16px",
                                   "marginBottom": "4px"
                                 }}>
                                <strong>{seatingChart.name}</strong>
                              </p>
                              {seatingChart.tags.map((tagName, index) =>
                                <span className="badge badge-dark small mr-2 mb-2"
                                      style={{
                                        "fontWeight": "normal",
                                        "padding": "4px 11px"
                                      }}
                                      key={index}>
                                  {tagName}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="row">
                  <div className="col-12">
                    <table className="table mb-0 vertical-middle no-top-border table-responsive"
                           style={{tableLayout: "fixed", "fontSize": "16px"}}>
                      <colgroup>
                        <col span={1} style={{"width": "5%"}} />
                        <col span={1} style={{"width": "60%"}} />
                        <col span={1} style={{"width": "15%"}} />
                        <col span={1} style={{"width": "15%"}} />
                        <col span={1} style={{"width": "5%"}} />
                      </colgroup>
                      <DragDropContext onDragEnd={
                                         (result) => {
                                           if (!result.destination) {
                                             return false;
                                           }

                                           var itemIndex = result.source.index;
                                           var moveIndex = result.destination.index;
                                           var updated = Object.assign({}, ticketTypes[itemIndex], {
                                             position: (moveIndex + 1)
                                           });

                                           ticketTypeDragged(result);
                                           updateTicketType(csrfToken, team, updated);
                                         }
                                       }>
                        <Droppable droppableId="ticket-types-droppable">
                          {(provided, snapshot) => (
                            <tbody {...provided.droppableProps} ref={provided.innerRef}>
                              {ticketTypes.sort((a, b) => a.position - b.position).map((ticketType, index) =>
                                <Draggable key={"" + ticketType.id} draggableId={"" + ticketType.id} index={index}>
                                  {(provided, snapshot) => (
                                    <TicketTypeRow csrfToken={csrfToken}
                                                   provided={provided}
                                                   snapshot={snapshot}
                                                   team={team}
                                                   currentUser={currentUser}
                                                   confirm={confirm}
                                                   fetchNewTicketType={fetchNewTicketType}
                                                   ticketType={ticketType}
                                                   deleteTicketType={deleteTicketType}
                                                   ticketTypeIsSeated={ticketTypeIsSeated}
                                                   fetchTicketTypeForEdit={fetchTicketTypeForEdit} />
                                  )}
                                </Draggable>
                              )}
                              {provided.placeholder}
                              {ticketTypes.length > 0 ? (
                                <tr>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td className="text-muted text-center">
                                    {ticketTypes.reduce((acc, ticketType) => acc + ticketType.number_of_tickets_sold, 0)}/{confirm.venue_capacity || 0}
                                  </td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                </tr>
                              ) : null}
                            </tbody>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </table>
                  </div>
                </div>
              )}
            </React.Fragment>
          ) : activeTicketingMenuItem === TICKETING_ADD_ONS_MENU_ITEM ? (
            <AddOnsTab csrfToken={csrfToken}
                       confirm={confirm}
                       currentUser={currentUser}
                       addOns={addOns}
                       addOnForForm={addOnForForm}
                       fetchNewAddOn={fetchNewAddOn}
                       addOnForFormChanged={addOnForFormChanged}
                       createAddOn={createAddOn}
                       fetchAddOnToEdit={fetchAddOnToEdit}
                       deleteAddOn={deleteAddOn}
                       updateAddOn={updateAddOn}
                       addOnDragged={addOnDragged}
                       ticketTypes={ticketTypes}
                       addOnSeatingCharts={addOnSeatingCharts}
                       autoSaveConfirm={autoSaveConfirm}
                       updateConfirm={updateConfirm}
                       isEditingAddOnsIntro={isEditingAddOnsIntro}
                       isEditingAddOnsIntroChanged={isEditingAddOnsIntroChanged}
                       addOnsIntroEditorState={addOnsIntroEditorState}
                       addOnsIntroEditorStateChanged={addOnsIntroEditorStateChanged}
                       createDefaultAddOns={createDefaultAddOns}
                       isLoadingDefaultAddOns={isLoadingDefaultAddOns}
                       isEditingAddOnsAlertMessage={isEditingAddOnsAlertMessage}
                       isEditingAddOnsAlertMessageChanged={isEditingAddOnsAlertMessageChanged}
                       addOnsAlertMessageEditorState={addOnsAlertMessageEditorState}
                       addOnsAlertMessageEditorStateChanged={addOnsAlertMessageEditorStateChanged}
                       team={team}
                       manualTaxes={manualTaxes}
                       manualTaxShouldBeChecked={manualTaxShouldBeChecked}
                       addOnAdded={addOnAdded}
                       showActivities={showActivities} />
          ) : activeTicketingMenuItem === TICKETING_PROMO_CODES_MENU_ITEM ? (
            <PromoCodesTab csrfToken={csrfToken}
                           confirm={confirm}
                           showActivities={showActivities}
                           team={team}
                           ticketTypes={ticketTypes} />
          ) : (
            <SettingsTab csrfToken={csrfToken} 
                         confirm={confirm}
                         updateConfirm={updateConfirm}
                         autoSaveConfirm={autoSaveConfirm}
                         feeRuleSets={feeRuleSets}
                         team ={team} />
          ))}
          <div className="row">
            <div className="col-12 d-flex justify-content-end align-items-end"
                 style={{"height": "130px"}}>
              <a href="#"
                 onClick={
                   (e) => {
                     e.preventDefault();

                     if(activeTicketingMenuItem === TICKETING_ADMISSION_MENU_ITEM){
                       activeTicketingMenuItemChanged(TICKETING_ADD_ONS_MENU_ITEM);
                     } else if (activeTicketingMenuItem === TICKETING_ADD_ONS_MENU_ITEM) {
                       activeTicketingMenuItemChanged(TICKETING_PROMO_CODES_MENU_ITEM);
                     } else if (activeTicketingMenuItem === TICKETING_PROMO_CODES_MENU_ITEM) {
                        activeTicketingMenuItemChanged(TICKETING_SETTINGS_MENU_ITEM);
                     } else if (team.can_access_marketing_dashboard) {
                        activeMenuItemChanged(AUTOMATION_MENU_ITEM);
                     } else {
                       activeMenuItemChanged(PROMOTION_MENU_ITEM);
                     }
                   }
                 }
                 className={"btn btn-primary"}>
                <strong>Next</strong>
              </a>
            </div>
          </div>
        </div>
      </div>
      <SlidingPane
        isOpen={Object.keys(ticketTypeForForm).length > 0}
        className="sliding-pane-container"
        width={"525px"}
        hideHeader={true}
        onAfterOpen={
          () => {
            debouncedBuildExampleTicket(csrfToken, team, confirm, ticketTypeForForm);
          }
        }
        onRequestClose={() => {
          ticketTypeForFormChanged({});
          exampleTicketChanged({});
        }}
      >
        <React.Fragment>
          <div className="row m-0 py-4"
               style={{"borderBottom": "2px solid #fafafa"}}>
            <div className="col pl-4">
              <h3 className="mb-0"
                  style={{"fontSize": "20px"}}>
                <strong>Add Ticket</strong>
              </h3>
            </div>
            <div className="col-xs-auto"
                 style={{"paddingRight": "39px"}}>
              <a href="#"
                 className="text-muted"
                 style={{"fontSize": "22px"}}
                 onClick={
                   (e) => {
                     e.preventDefault();
                     ticketTypeForFormChanged({});
                   }
                 }>
                <i className="fal fa-times"></i>
              </a>
            </div>
          </div>
          <form onSubmit={
                  (e) => {
                    e.preventDefault();

                    if(!canSubmitForm(ticketTypeForForm)){
                      return false;
                    }

                    if(ticketTypeForForm.id){
                      updateTicketType(csrfToken, team, ticketTypeForForm);
                    } else {
                      createTicketType(csrfToken, team, confirm, ticketTypeForForm);
                    }
                  }
                }>
            <div className="row m-0">
              <div className="col-12 pt-3">
                <div className="btn-group btn-block pb-4">
                  <button type="button"
                          onClick={
                            (e) => {
                              e.preventDefault();

                              var updated = Object.assign({}, ticketTypeForForm, {free: false});

                              debouncedBuildExampleTicket(csrfToken, team, confirm, updated);
                              ticketTypeForFormChanged(updated);
                            }
                          }
                          style={{
                            "borderWidth": "1px",
                            "borderRight": "0px"
                          }}
                          className={"btn btn-outline-primary " + (!ticketTypeForForm.free ? "btn-checked" : "")}>
                    <strong>Paid</strong>
                  </button>
                  <div style={{
                        "height": "38px",
                        "width": "1px",
                        "background": "#1982C4"
                       }}>
                  </div>
                  <button type="button"
                          onClick={
                            (e) => {
                              e.preventDefault();

                              var updated = Object.assign({}, ticketTypeForForm, {
                                free: true,
                                price_two_decimals: "0.00",
                                settlement_amount_two_decimals: "0.00",
                                has_fixed_fees: false,
                                fixed_fee_amount_two_decimals: "",
                                absorb_fees: false
                              });

                              debouncedBuildExampleTicket(csrfToken, team, confirm, updated);
                              ticketTypeForFormChanged(updated);
                            }
                          }
                          style={{
                            "borderWidth": "1px",
                            "borderLeft": "0px"
                          }}
                          className={"btn btn-outline-primary ml-0 " + (ticketTypeForForm.free ? "btn-checked" : "")}>
                    <strong>Free</strong>
                  </button>
                </div>
                <div className="form-group">
                  <label className="text-muted mb-1"
                         htmlFor="ticket-type-name"
                         style={{"fontSize": "14px"}}>
                    Name
                    <span className="text-danger">&nbsp;*</span>
                  </label>
                  <input type="text"
                         className="form-control form-control-slim"
                         id="ticket-type-name"
                         disabled={ticketTypeIsSeated(ticketTypeForForm)}
                         value={ticketTypeForForm.name || ""}
                         onChange={
                           (e) => {
                             var updated = Object.assign({}, ticketTypeForForm, {name: e.target.value});
                             ticketTypeForFormChanged(updated);
                           }
                         } />
                </div>
                <div className="form-group">
                  <label className="text-muted mb-1"
                         htmlFor="ticket-type-available"
                         style={{"fontSize": "14px"}}>
                    Available Quantity
                    <span className="text-danger">&nbsp;*</span>
                  </label>
                  <MaskedInput className="form-control form-control-slim"
                               mask={createNumberMask({
                                 prefix: "",
                                 decimalLimit: 0
                               })}
                               id="ticket-type-available"
                               disabled={ticketTypeIsSeated(ticketTypeForForm)}
                               onChange={
                                 (e) => {
                                   var updated = Object.assign({}, ticketTypeForForm, {available: e.target.value});
                                   ticketTypeForFormChanged(updated);
                                 }
                               }
                               value={ticketTypeForForm.available || ""} />
                </div>
                <div className="form-group">
                  <label className="text-muted mb-1"
                         htmlFor="ticket-type-price"
                         style={{"fontSize": "14px"}}>
                    Price
                    <span className="text-danger">&nbsp;*</span>
                  </label>
                  {ticketTypeForForm.free ? (
                    <input type="text"
                           className="form-control form-control-slim"
                           value="$ Free"
                           disabled={true} />
                  ) : (
                    <React.Fragment>
                      <CurrencyInput className="form-control form-control-slim"
                                     maskOptions={{
                                       prefix: "",
                                       integerLimit: null
                                     }}
                                     value={ticketTypeForForm.price_two_decimals || ""}
                                     onChange={
                                       (e) => {
                                         var amount = e.target.value;

                                         var updated = Object.assign({}, ticketTypeForForm, {
                                           price_two_decimals: amount,
                                           settlement_amount_two_decimals: amount
                                         });

                                         debouncedBuildExampleTicket(csrfToken, team, confirm, updated);
                                         ticketTypeForFormChanged(updated);
                                       }
                                     }
                                     id="ticket-type-price" />
                      {Object.keys(exampleTicket).length > 0 ? (
                        <p className="mb-0 small text-muted">
                          Buyer total: {formatter.format(parseFloat(exampleTicket.final_cost))}
                        </p>
                      ) : null}
                    </React.Fragment>
                  )}
                </div>
                {!ticketTypeForForm.free ? (
                  <div className="form-group">
                    <div className="custom-control custom-checkbox custom-checkbox-table">
                      <input type="checkbox"
                             className="custom-control-input"
                             onChange={
                               (e) => {
                                 var updated = Object.assign({}, ticketTypeForForm, {
                                   absorb_fees: e.target.checked
                                 });

                                 debouncedBuildExampleTicket(csrfToken, team, confirm, updated);
                                 ticketTypeForFormChanged(updated);
                               }
                             }
                             checked={ticketTypeForForm.absorb_fees || false}
                             id="ticket-type-absorb-fees" />
                      <label className="custom-control-label"
                             style={{"fontSize": "14px"}}
                             htmlFor="ticket-type-absorb-fees">
                        Absorb Fees: Ticketing fees are deducted from your ticket revenue
                      </label>
                    </div>
                  </div>
                ) : null}
                {ticketTypeForForm.allow_fixed_fees && !ticketTypeForForm.free ? (
                  <React.Fragment>
                    <div className="form-group">
                      <div className="custom-control custom-checkbox custom-checkbox-table">
                        <input type="checkbox"
                               className="custom-control-input"
                               onChange={
                                 (e) => {
                                   var updated = Object.assign({}, ticketTypeForForm, {
                                     has_fixed_fees: e.target.checked,
                                     fixed_fee_amount_two_decimals: ""
                                   });

                                   debouncedBuildExampleTicket(csrfToken, team, confirm, updated);
                                   ticketTypeForFormChanged(updated);
                                 }
                               }
                               checked={ticketTypeForForm.has_fixed_fees || false}
                               id="ticket-type-has-fixed-fees" />
                        <label className="custom-control-label"
                               style={{"fontSize": "14px"}}
                               htmlFor="ticket-type-has-fixed-fees">
                          Fixed Fees
                        </label>
                      </div>
                    </div>
                    {ticketTypeForForm.has_fixed_fees ? (
                      <div className="form-group">
                        <label className="text-muted mb-1"
                               htmlFor="ticket-type-fixed-fee-amount"
                               style={{"fontSize": "14px"}}>
                          Fee Amount
                        </label>
                        <CurrencyInput className="form-control form-control-slim"
                                       maskOptions={{
                                         prefix: "",
                                         integerLimit: null
                                       }}
                                       value={ticketTypeForForm.fixed_fee_amount_two_decimals || ""}
                                       onChange={
                                         (e) => {
                                           var updated = Object.assign({}, ticketTypeForForm, {fixed_fee_amount_two_decimals: e.target.value});

                                           debouncedBuildExampleTicket(csrfToken, team, confirm, updated);
                                           ticketTypeForFormChanged(updated);
                                         }
                                       }
                                       id="ticket-type-fixed-fee-amount" />
                      </div>
                    ) : null}
                  </React.Fragment>
                ) : null}
                {!ticketTypeIsSeated(ticketTypeForForm) ? (
                  <div className="form-row">
                    <div className="col-12">
                      <hr className="mb-0 mt-0"
                        style={{"borderTop": "1px solid #e6e6e6"}} />
                      <div className="form-group mt-2 mb-0">
                        <div className="custom-control custom-checkbox custom-checkbox-table">
                          <input type="checkbox"
                                 className="custom-control-input"
                                 checked={ticketTypeForForm.has_bundled_ticket_type || false}
                                 onChange={
                                   (e) => {
                                      var updated = Object.assign({}, ticketTypeForForm, {
                                        has_bundled_ticket_type: e.target.checked,
                                        bundled_ticket_type_id: ""
                                      });

                                      ticketTypeForFormChanged(updated);
                                   }
                                 }
                                 id="ticket-type-has-bundled-ticket-type" />
                          <label className="custom-control-label text-dark"
                                 style={{"fontSize": "14px"}}
                                 htmlFor="ticket-type-has-bundled-ticket-type">
                            This is a bundle that includes an existing ticket type.
                            <Tippy content={
                                     <div style={{
                                            "fontSize": "12px"
                                          }}>
                                       Inventory from the linked ticket type will not be automatically adjusted when a purchase is made of this bundle. Please adjust available inventory as required.
                                     </div>
                                   }
                                   placement={'top'}
                                   maxWidth={200}
                                   animation={'shift-away'}
                                   theme='material'>
                              <i className="fas fa-info-circle text-muted ml-2"></i>
                            </Tippy>
                          </label>
                        </div>
                        {ticketTypeForForm.has_bundled_ticket_type ? (
                          <>
                            <select className="form-control form-control-slim"
                                    style={{"marginTop": "7px"}}
                                    value={ticketTypeForForm.bundled_ticket_type_id || ""}
                                    onChange={(e) => {
                                      var updated = Object.assign({}, ticketTypeForForm, {
                                        bundled_ticket_type_id: e.target.value
                                      });

                                      ticketTypeForFormChanged(updated);
                                    }}
                                    id="ticket-type-bundled-ticket-type-id">
                              <option value="">
                                Select Ticket Type
                              </option>
                              {ticketTypes.filter((tt) => tt.id !== ticketTypeForForm.id).map((ticketType, index) =>
                                <option key={index} value={ticketType.id}>
                                  {ticketType.name}
                                </option>
                              )}
                            </select>
                            {ticketTypeForForm.bundled_ticket_type_id ? (
                              <p className='mb-0 small text-muted' style={{"marginTop": "2px"}}>
                                Artist Lift: {formatter.format(calculateLift(ticketTypeForForm, ticketTypes))}
                              </p>
                            ) : null}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="form-row">
                  <div className="col-12 mt-3">
                    <p>
                      <strong style={{"fontSize": "16px"}}>Sales Start</strong>
                    </p>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="ticket-type-availability"
                             className="text-muted mb-1"
                             style={{"fontSize": "14px"}}>
                        When are tickets available?
                      </label>
                      <select className="form-control form-control-slim"
                              value={ticketTypeForForm.availability || ""}
                              onChange={(e) => {
                                var updated;

                                if(e.target.value === "Date & Time" && ticketTypeForForm.availability != e.target.value) {
                                  updated = Object.assign({}, ticketTypeForForm, {
                                    availability: e.target.value,
                                  });
                                } else {
                                  updated = Object.assign({}, ticketTypeForForm, {availability: e.target.value});
                                }

                                ticketTypeForFormChanged(updated);
                              }}
                              id="ticket-type-availability">
                        <option value="Date & Time">
                          Date & Time
                        </option>
                        <option value="Based on show details">
                          Based on show details
                        </option>
                        <option value="When sales end for...">
                          When ticket sales end for...
                        </option>
                        <option value="When tickets sell out for...">
                          When tickets sell out for...
                        </option>
                        <option value="When ticket sales end or sell out for...">
                          When ticket sales end or sell out for...
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                {ticketTypeForForm.availability === "Date & Time" ? (
                  <div className="form-row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="ticket-type-start-date"
                               className="text-muted mb-1"
                               style={{"fontSize": "14px"}}>
                          Start Date
                          <span className="text-danger">&nbsp;*</span>
                        </label>
                        <Flatpickr
                          options={{
                            dateFormat: "M j, Y at h:iK",
                            enableTime: true,
                          }}
                          placeholder={"Select date and time"}
                          className={"form-control ticketing-date-picker form-control-flatpickr"}
                          value={ticketTypeForForm.start_time}
                          onClose={dates => {
                            var startTime = parseDateTime(dates[0]);
                            var endTime   = parseDateTime(ticketTypeForForm.end_time);
  
                            var attrs = {
                              start_time: startTime,
                            };
  
                            if(endTime && endTime < startTime){
                              endTime = "";
                              attrs = Object.assign({}, attrs, {
                                end_time: endTime
                              });
                            }
  
                            var updated = Object.assign({}, ticketTypeForForm, attrs);
                            ticketTypeForFormChanged(updated);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (ticketTypeForForm.availability === "Based on show details" ? (
                  <div className="form-row">
                    <div className="col-12 form-group">
                      <label htmlFor="ticket-type-start-date"
                             className="text-muted mb-1"
                             style={{"fontSize": "14px"}}>
                        Start Show Detail
                        <span className="text-danger">&nbsp;*</span>
                      </label>
                      <Select
                        options={showActivities.map(showActivity => showActivityOption(showActivity))}
                        placeholder="Select a show detail"
                        value={showActivities.filter(showActivity => {
                          return showActivity.id === ticketTypeForForm.start_show_activity_id
                        }).map(showActivity => showActivityOption(showActivity))}
                        onChange={option => {
                          ticketTypeForFormChanged({
                            ...ticketTypeForForm,
                            start_show_activity_id: option.value
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="form-row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="ticket-type-wait-for-ticket-type"
                               className="text-muted mb-1"
                               style={{"fontSize": "14px"}}>
                          Ticket Type
                        </label>
                        <select className="form-control form-control-slim"
                                value={ticketTypeForForm.wait_for_ticket_type_id || ""}
                                onChange={
                                  (e) => {
                                    var updated = Object.assign({}, ticketTypeForForm, {wait_for_ticket_type_id: e.target.value});
                                    ticketTypeForFormChanged(updated);
                                  }
                                }
                                id="ticket-type-wait-for-ticket-type">
                          <option value="">
                            Select Ticket Type
                          </option>
                          {ticketTypes.filter((tt) => tt.id !== ticketTypeForForm.id).map((ticketType, index) =>
                            <option key={index} value={ticketType.id}>
                              {ticketType.name}
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                ))}
                {ticketTypeForForm.availability === "Based on show details" ? (
                  <div className="form-row">
                    <div className="col-12 form-group">
                      <label htmlFor="ticket-type-end-date"
                             className="text-muted mb-1"
                             style={{"fontSize": "14px"}}>
                        End Show Detail
                        <span className="text-danger">&nbsp;*</span>
                      </label>
                      <Select
                        options={showActivities.map(showActivity => showActivityOption(showActivity))}
                        placeholder="Select a show detail"
                        value={showActivities.filter(showActivity => {
                          return showActivity.id === ticketTypeForForm.end_show_activity_id
                        }).map(showActivity => showActivityOption(showActivity))}
                        onChange={option => {
                          ticketTypeForFormChanged({
                            ...ticketTypeForForm,
                            end_show_activity_id: option.value
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="form-row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="ticket-type-end-date"
                               className="text-muted mb-1"
                               style={{"fontSize": "14px"}}>
                          End Date
                          <span className="text-danger">&nbsp;*</span>
                        </label>
                        <Flatpickr
                          options={{
                            dateFormat: "M j, Y at h:iK",
                            enableTime: true,
                            minDate: ticketTypeForForm.start_time,
                          }}
                          disabled={!ticketTypeForForm.start_time}
                          placeholder={"Select date & Time"}
                          className={"form-control ticketing-date-picker form-control-flatpickr " + (!ticketTypeForForm.start_time ? "form-control-disabled" : "")}
                          value={ticketTypeForForm.end_time}
                          onClose={dates => {
                            var startTime = parseDateTime(ticketTypeForForm.start_time);
                            var endTime   = parseDateTime(dates[0]);

                            var attrs = {
                              end_time: endTime
                            };

                            if(endTime && endTime < startTime){
                              endTime = "";
                              attrs = Object.assign({}, attrs, {
                                end_time: endTime
                              });
                            }

                            var updated = Object.assign({}, ticketTypeForForm, attrs);
                            ticketTypeForFormChanged(updated);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="form-row">
                  <div className="col-12">
                    <hr className="mb-0"
                        style={{"borderTop": "1px solid #e6e6e6"}} />
                  </div>
                  <div className="col-12 mt-3">
                    <p>
                      <strong style={{"fontSize": "16px"}}>Advanced Settings</strong>
                    </p>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12">
                    <div className="form-group mb-2">
                      <label className="text-muted mb-1"
                             htmlFor="ticket-type-description"
                             style={{"fontSize": "14px"}}>
                        Description
                      </label>
                      <DebounceInput className="form-control form-control-slim small"
                                     element="textarea"
                                     id="ticket-type-description"
                                     placeholder="Tell attendees more about this ticket..."
                                     style={{"resize": "none"}}
                                     value={ticketTypeForForm.description || ""}
                                     onChange={
                                       (e) => {
                                         var updated = Object.assign({}, ticketTypeForForm, {description: e.target.value});
                                         ticketTypeForFormChanged(updated);
                                       }
                                     }
                                     rows="5" />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12">
                    <div className="form-group mb-2">
                      <label className="text-muted mb-1"
                             htmlFor="ticket-type-visibility"
                             style={{"fontSize": "14px"}}>
                        Visibility
                      </label>
                      <select className="form-control form-control-slim"
                              value={ticketTypeForForm.visibility || ""}
                              onChange={
                                (e) => {
                                  var updated = Object.assign({}, ticketTypeForForm, {visibility: e.target.value});
                                  ticketTypeForFormChanged(updated);
                                }
                              }
                              id="ticket-type-visibility">
                        <option value="Visible">
                          Visible
                        </option>
                        <option value="Hidden">
                          Hidden
                        </option>
                        <option value="Hidden when not on sale">
                          Hidden when not on sale
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12 mt-3">
                    <p className="mb-1">
                      <strong style={{"fontSize": "16px"}}>Tickets Per Order</strong>
                    </p>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="text-muted mb-1"
                             htmlFor="ticket-type-minimum-tickets-per-order"
                             style={{"fontSize": "14px"}}>
                        Minimum
                      </label>
                      <MaskedInput className={"form-control form-control-slim " + (minTicketsPerOrderMoreThanMax(ticketTypeForForm) ? "highlight" : "")}
                                   mask={createNumberMask({
                                     prefix: "",
                                     decimalLimit: 0
                                   })}
                                   id="ticket-type-minimum-tickets-per-order"
                                   disabled={ticketTypeIsSeated(ticketTypeForForm)}
                                   onChange={
                                     (e) => {
                                       var updated = Object.assign({}, ticketTypeForForm, {minimum_tickets_per_order: e.target.value});
                                       ticketTypeForFormChanged(updated);
                                     }
                                   }
                                   value={ticketTypeForForm.minimum_tickets_per_order || ""} />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="text-muted mb-1"
                             htmlFor="ticket-type-maximum-tickets-per-order"
                             style={{"fontSize": "14px"}}>
                        Maximum
                      </label>
                      <MaskedInput className={"form-control form-control-slim " + (minTicketsPerOrderMoreThanMax(ticketTypeForForm) ? "highlight" : "")}
                                   mask={createNumberMask({
                                     prefix: "",
                                     decimalLimit: 0
                                   })}
                                   id="ticket-type-maximum-tickets-per-order"
                                   onChange={
                                     (e) => {
                                       var updated = Object.assign({}, ticketTypeForForm, {maximum_tickets_per_order: e.target.value});
                                       ticketTypeForFormChanged(updated);
                                     }
                                   }
                                   value={ticketTypeForForm.maximum_tickets_per_order || ""} />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="text-muted mb-1"
                             htmlFor="ticket-type-sales-channel"
                             style={{"fontSize": "14px"}}>
                        Sales Channel
                      </label>
                      <select className="form-control form-control-slim"
                              disabled={ticketTypeIsSeated(ticketTypeForForm)}
                              value={ticketTypeForForm.sales_channel || ""}
                              onChange={
                                (e) => {
                                  var updated = Object.assign({}, ticketTypeForForm, {sales_channel: e.target.value});
                                  ticketTypeForFormChanged(updated);
                                }
                              }
                              id="ticket-type-sales-channel">
                        <option value="Everywhere">
                          Everywhere
                        </option>
                        <option value="Online only">
                          Online only
                        </option>
                        <option value="At the door only">
                          At the door only
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                {!ticketTypeForForm.free ? (
                  <div className="form-row">
                    <div className="col-12 mt-3">
                      <p className="mb-1">
                        <strong style={{"fontSize": "16px"}}>Settlement Amount</strong>
                      </p>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label className="text-muted mb-1"
                               htmlFor="ticket-type-settlement-amount"
                               style={{"fontSize": "14px"}}>
                          Customize the settlement amount
                        </label>
                        <CurrencyInput className={"form-control form-control-slim " + (ticketTypeForForm.price_two_decimals && !ticketTypeForForm.settlement_amount_two_decimals ? "highlight" : "")}
                                       maskOptions={{
                                         prefix: "",
                                         integerLimit: null
                                       }}
                                       value={ticketTypeForForm.settlement_amount_two_decimals || ""}
                                       onChange={
                                         (e) => {
                                           var updated = Object.assign({}, ticketTypeForForm, {settlement_amount_two_decimals: e.target.value});
                                           ticketTypeForFormChanged(updated);
                                         }
                                       }
                                       id="ticket-type-settlement-amount" />
                      </div>
                    </div>
                  </div>
                ) : null}
                {manualTaxes.length > 0 ? (
                  <div className="form-row mb-3">
                    <div className="col-12">
                      <p className="mb-1">
                        <strong style={{"fontSize": "16px"}}>Apply Taxes</strong>
                      </p>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        {manualTaxes.sort((a, b) => a.name.localeCompare(b.name)).map((manualTax, index) => (
                          <div key={index} className="custom-control custom-checkbox custom-checkbox-table">
                            <input type="checkbox"
                                    className="custom-control-input"
                                    onChange={
                                      (e) => {
                                        var newTaxableItems = [...ticketTypeForForm.taxable_items];
                                        var foundIndex = newTaxableItems
                                          .map((taxableItem) => taxableItem.manual_tax_id)
                                          .indexOf(manualTax.id);

                                        if(e.target.checked){
                                          if(foundIndex === -1){
                                            newTaxableItems.push(
                                              {
                                                manual_tax_id: manualTax.id,
                                                _destroy: false
                                              }
                                            );
                                          } else {
                                            newTaxableItems[foundIndex]._destroy = false;
                                          }
                                        } else {
                                          if(foundIndex === -1){
                                            // no op...
                                          } else {
                                            newTaxableItems[foundIndex]._destroy = true;
                                          }
                                        }

                                        var updated = Object.assign({}, ticketTypeForForm, {
                                          taxable_items: newTaxableItems
                                        });

                                        debouncedBuildExampleTicket(csrfToken, team, confirm, updated);
                                        ticketTypeForFormChanged(updated);
                                      }
                                    }
                                    checked={
                                      manualTaxShouldBeChecked(ticketTypeForForm, manualTax)
                                    }
                                    id={`manual-tax-${manualTax.id}`} />
                            <label className="custom-control-label"
                                    style={{"fontSize": "14px"}}
                                    htmlFor={`manual-tax-${manualTax.id}`}>
                              {manualTax.name} ({parseFloat(manualTax.rate)}%)
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
                {!ticketTypeIsSeated(ticketTypeForForm) ? (
                  <div className="form-row">
                    <div className="col-12">
                      <div className="form-group mb-0">
                        <div className="custom-control custom-checkbox custom-checkbox-table mt-2">
                          <input type="checkbox"
                                 className="custom-control-input"
                                 checked={ticketTypeForForm.should_be_default || false}
                                 onChange={
                                   (e) => {
                                     var updated = Object.assign({}, ticketTypeForForm, {should_be_default: e.target.checked});
                                     ticketTypeForFormChanged(updated);
                                   }
                                 }
                                 id="ticket-type-should-be-default" />
                          <label className="custom-control-label text-dark"
                                 style={{"fontSize": "16px"}}
                                 htmlFor="ticket-type-should-be-default">
                            Set as default
                            <Tippy content={
                                     <div style={{
                                            "fontSize": "12px"
                                          }}>
                                       Default tickets are automatically added to each new event you build.
                                     </div>
                                   }
                                   placement={'top'}
                                   maxWidth={200}
                                   animation={'shift-away'}
                                   theme='material'>
                              <i className="fas fa-info-circle text-muted ml-2"></i>
                            </Tippy>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="form-row">
                  <div className="col-12">
                    <div className="form-group mb-0">
                      <div className="custom-control custom-switch mt-3">
                        <input type="checkbox"
                               className="custom-control-input"
                               checked={ticketTypeForForm.manifested || false}
                               onChange={
                                 (e) => {
                                   var updated = Object.assign({}, ticketTypeForForm, {manifested: e.target.checked});
                                   ticketTypeForFormChanged(updated);
                                 }
                               }
                               id="ticket-type-manifested" />
                        <label className="custom-control-label text-dark"
                               style={{"fontSize": "16px"}}
                               htmlFor="ticket-type-manifested">
                          {ticketTypeForForm.manifested ? (
                            <span>Manifested Ticket</span>
                          ) : (
                            <span>Unmanifested Ticket</span>
                          )}
                          <Tippy content={
                                   <div style={{
                                          "fontSize": "12px"
                                        }}>
                                     Unmanifested tickets are not counted towards event and venue capacity restrictions and are not shown on artist settlements.
                                   </div>
                                 }
                                 placement={'top'}
                                 maxWidth={200}
                                 animation={'shift-away'}
                                 theme='material'>
                            <i className="fas fa-info-circle text-muted ml-2"></i>
                          </Tippy>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-12 text-right my-4">
                    <a href="#"
                       onClick={
                         (e) => {
                           e.preventDefault();
                           ticketTypeForFormChanged({});
                         }
                       }
                       className="btn btn-outline-primary">
                      <strong>Cancel</strong>
                    </a>
                    <button type="submit"
                            disabled={!canSubmitForm(ticketTypeForForm)}
                            className="btn btn-primary ml-3">
                      <strong>Save</strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </React.Fragment>
      </SlidingPane>
    </React.Fragment>
  );
};

Tickets.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  activeMenuItemChanged: PropTypes.func.isRequired,
  activeTicketingMenuItem: PropTypes.string,
  activeTicketingMenuItemChanged: PropTypes.func.isRequired,
  ticketTypeForForm: PropTypes.object,
  ticketTypeForFormChanged: PropTypes.func.isRequired,
  fetchNewTicketType: PropTypes.func.isRequired,
  ticketTypes: PropTypes.array,
  deleteTicketType: PropTypes.func.isRequired,
  fetchTicketTypeForEdit: PropTypes.func.isRequired,
  createTicketType: PropTypes.func.isRequired,
  updateTicketType: PropTypes.func.isRequired,
  ticketTypeDragged: PropTypes.func.isRequired,
  addOns: PropTypes.array,
  addOnForForm: PropTypes.object,
  fetchNewAddOn: PropTypes.func.isRequired,
  addOnForFormChanged: PropTypes.func.isRequired,
  createAddOn: PropTypes.func.isRequired,
  fetchAddOnToEdit: PropTypes.func.isRequired,
  deleteAddOn: PropTypes.func.isRequired,
  updateAddOn: PropTypes.func.isRequired,
  addOnDragged: PropTypes.func.isRequired,
  exampleTicket: PropTypes.object,
  buildExampleTicket: PropTypes.func.isRequired,
  exampleTicketChanged: PropTypes.func.isRequired,
  seatingCharts: PropTypes.array,
  autoSaveConfirm: PropTypes.func.isRequired,
  updateConfirm: PropTypes.func.isRequired,
  createDefaultTicketTypes: PropTypes.func.isRequired,
  isLoadingDefaultTicketTypes: PropTypes.bool,
  addOnSeatingCharts: PropTypes.array,
  isEditingAddOnsIntro: PropTypes.bool,
  isEditingAddOnsIntroChanged: PropTypes.func.isRequired,
  addOnsIntroEditorState: PropTypes.object,
  addOnsIntroEditorStateChanged: PropTypes.func.isRequired,
  createDefaultAddOns: PropTypes.func.isRequired,
  isLoadingDefaultAddOns: PropTypes.bool,
  isEditingAddOnsAlertMessage: PropTypes.bool,
  isEditingAddOnsAlertMessageChanged: PropTypes.func.isRequired,
  addOnsAlertMessageEditorState: PropTypes.object,
  addOnsAlertMessageEditorStateChanged: PropTypes.func.isRequired,
  showActivities: PropTypes.array.isRequired,
  manualTaxes: PropTypes.array,
  addOnAdded: PropTypes.func.isRequired,
  feeRuleSets: PropTypes.array
};

export default Tickets;
