import PropTypes from 'prop-types';
import React from 'react';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';

const canSeeEventbriteUrl = (confirm, currentUser) => {
  return (confirm.eventbrite_event && confirm.eventbrite_event.eventbrite_url);
}

const hasConfirmVenuePermalink = (confirm, currentUser) => {
  return (
    typeof confirm.venue_permalink !== 'undefined'
      && confirm.venue_permalink !== null
      && confirm.venue_permalink.length > 0
  );
}

const hasEventbriteVenuePermalink = (confirm, currentUser) => {
  return (
    confirm.eventbrite_event
      && confirm.eventbrite_event.venue_permalink
      && confirm.eventbrite_event.venue_permalink.length > 0
  );
}

const canSeeVenuePermalink = (confirm, currentUser) => {
  return (
    hasConfirmVenuePermalink(confirm, currentUser)
      || hasEventbriteVenuePermalink(confirm, currentUser)
  );
}

const venuePermalinkToShow = (confirm, currentUser) => {
  return (
    hasConfirmVenuePermalink(confirm, currentUser) ? (
      confirm.venue_permalink
    ) : (hasEventbriteVenuePermalink(confirm, currentUser) ? (
      confirm.eventbrite_event.venue_permalink
    ) : (
      ""
    ))
  );
}

const canSeeShareThisPage = (confirm, currentUser) => {
  return (
    currentUser.role.name === "Administrator"
      || (currentUser.role.name === "Promoter" && currentUser.current_team_membership.company === "MOKB")
  );
}

const canModifyVenuePermalink = (confirm, currentUser) => {
  return(
    !hasEventbriteVenuePermalink(confirm, currentUser)
      || hasConfirmVenuePermalink(confirm, currentUser)
  );
}

const canSeeAnyLink = (confirm, currentUser) => {
  return (
    canSeeEventbriteUrl(confirm, currentUser)
      || canSeeVenuePermalink(confirm, currentUser)
      || canSeeShareThisPage(confirm, currentUser)
  );
}

const CopyLinks = ({
  csrfToken,
  team,
  confirm,
  confirmChanged,
  updateConfirmWebsite,
  currentUser,
  selfRegisterLink,
  editingConfirmWebsite,
  editingConfirmWebsiteChanged,
  editingConfirmWebsiteValue,
  editingConfirmWebsiteValueChanged
}) => (
  canSeeAnyLink(confirm, currentUser) ? (
    <div className="card no-border shadow-2">
      <div className="card-body px-md-3">
        <h5 className="strong mb-3">Links</h5>
        {canSeeEventbriteUrl(confirm, currentUser) ? (
          <div>
            <p className="mb-0">
              <strong>Eventbrite</strong>
            </p>
            <div className="row">
              <div className="col text-overflow-ellipsis">
                <a href={confirm.eventbrite_event.eventbrite_url}
                   target="_blank">
                  {confirm.eventbrite_event.eventbrite_url}
                </a>
              </div>
              <div className="col-xs-auto"
                   style={{"paddingRight": "15px"}}>
                <CopyToClipboard text={confirm.eventbrite_event.eventbrite_url}
                                 onCopy={() => {
                                   toast.success("Copied to clipboard", {
                                     position: toast.POSITION.TOP_CENTER,
                                     draggable: false,
                                     closeOnClick: false,
                                     autoClose: 5000,
                                     hideProgressBar: true
                                   });
                                 }}>
                  <a className="text-muted"
                     onClick={
                       (e) => {
                         e.preventDefault();
                       }
                     }
                     href="#">
                    <i className="far fa-copy"></i>
                  </a>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        ) : null}
        <div>
          <p className="mb-0 mt-2">
            <strong>Website</strong>
            {!editingConfirmWebsite && canModifyVenuePermalink(confirm, currentUser) ? (
              <Tippy content={venuePermalinkToShow(confirm, currentUser).length == 0 ? (
                "Add Website URL"
              ) : (
                "Edit Website URL"
              )}>
                <a className="ml-1 text-muted"
                   onClick={
                     (e) => {
                       e.preventDefault();
                       editingConfirmWebsiteValueChanged(confirm.venue_permalink);
                       editingConfirmWebsiteChanged(true);
                     }
                   }
                   href="#">
                  {venuePermalinkToShow(confirm, currentUser).length == 0 ? (
                    <i className="fas fa-plus" style={{"fontSize": "12px"}}></i>
                  ) : (
                    <i className="fas fa-pencil" style={{"fontSize": "12px"}}></i>
                  )}
                </a>
              </Tippy>
            ) : null}
          </p>
          {!editingConfirmWebsite ? (
            <div className="row">
              <div className="col text-overflow-ellipsis">
                <a href={venuePermalinkToShow(confirm, currentUser)}
                   target="_blank">
                  {venuePermalinkToShow(confirm, currentUser)}
                </a>
              </div>
              <div className="col-xs-auto"
                   style={{"paddingRight": "15px"}}>
                {venuePermalinkToShow(confirm, currentUser).length > 0 ? (
                  <CopyToClipboard text={venuePermalinkToShow(confirm, currentUser)}
                                   onCopy={() => {
                                     toast.success("Copied to clipboard", {
                                       position: toast.POSITION.TOP_CENTER,
                                       draggable: false,
                                       closeOnClick: false,
                                       autoClose: 5000,
                                       hideProgressBar: true
                                     });
                                   }}>
                    <a className="text-muted"
                       onClick={
                         (e) => {
                           e.preventDefault();
                         }
                       }
                       href="#">
                      <i className="far fa-copy"></i>
                    </a>
                  </CopyToClipboard>
                ) : null}
              </div>
            </div>
          ) : (
            <React.Fragment>
              <div className="row">
                <div className="col">
                  <textarea
                    className="w-100 form-control"
                    style={{"fontSize": "14px"}}
                    value={editingConfirmWebsiteValue}
                    onChange={e => {
                      editingConfirmWebsiteValueChanged(e.target.value);
                    }} >
                  </textarea>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 align-middle text-right">
                  <a href="#"
                    onClick={
                      (e) => {
                        e.preventDefault();
                        editingConfirmWebsiteValueChanged("");
                        editingConfirmWebsiteChanged(false);
                      }
                    } >
                    Cancel
                  </a>
                  <button style={{"borderRadius": "8px"}}
                    className="btn btn-primary btn-sm ml-2"
                    onClick={() => {
                      updateConfirmWebsite(
                        csrfToken,
                        confirm,
                        editingConfirmWebsiteValue,
                        (data) => {
                          confirmChanged({
                            ...confirm,
                            venue_permalink: editingConfirmWebsiteValue
                          });
                          editingConfirmWebsiteChanged(false);
                          toast.success("Confirm website updated", {
                            position: toast.POSITION.TOP_CENTER,
                            draggable: false,
                            closeOnClick: false,
                            autoClose: 5000,
                            hideProgressBar: true
                          });

                        }
                      );
                    }} >
                    Save
                  </button>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
        {canSeeShareThisPage(confirm, currentUser) ? (
          <div>
            <p className="mb-0 mt-2">
              <strong>Share this Page</strong>
            </p>
            <div className="row">
              <div className="col text-overflow-ellipsis">
                <a href={selfRegisterLink}
                   target="_blank">
                  {selfRegisterLink}
                </a>
              </div>
              <div className="col-xs-auto"
                   style={{"paddingRight": "15px"}}>
                <CopyToClipboard text={selfRegisterLink}
                                 onCopy={() => {
                                   toast.success("Copied to clipboard", {
                                     position: toast.POSITION.TOP_CENTER,
                                     draggable: false,
                                     closeOnClick: false,
                                     autoClose: 5000,
                                     hideProgressBar: true
                                   });
                                 }}>
                  <a className="text-muted"
                     onClick={
                       (e) => {
                         e.preventDefault();
                       }
                     }
                     href="#">
                    <i className="far fa-copy"></i>
                  </a>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        ) : null}
        <div>
          <p className="mb-0 mt-2">
            <strong>Public Event Page</strong>
            {confirm.published_at ? (
              <Tippy content="Published">
                <i className="fas fa-check-circle ml-1 text-success" />
              </Tippy>
            ) : null}
          </p>
          <div className="row">
            <div className="col text-overflow-ellipsis">
              <a href={confirm.public_ticketing_url}
                 target="_blank">
                {confirm.public_ticketing_url}
              </a>
            </div>
            <div className="col-xs-auto"
                 style={{"paddingRight": "15px"}}>
              <CopyToClipboard text={confirm.public_ticketing_url}
                               onCopy={() => {
                                 toast.success("Copied to clipboard", {
                                   position: toast.POSITION.TOP_CENTER,
                                   draggable: false,
                                   closeOnClick: false,
                                   autoClose: 5000,
                                   hideProgressBar: true
                                 });
                               }}>
                <a className="text-muted"
                   onClick={
                     (e) => {
                       e.preventDefault();
                     }
                   }
                   href="#">
                  <i className="far fa-copy"></i>
                </a>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
);

CopyLinks.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  confirmChanged: PropTypes.func.isRequired,
  updateConfirmWebsite: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  selfRegisterLink: PropTypes.string,
  editingConfirmWebsite: PropTypes.bool.isRequired,
  editingConfirmWebsiteChanged: PropTypes.func.isRequired,
  editingConfirmWebsiteValue: PropTypes.string.isRequired,
  editingConfirmWebsiteValueChanged: PropTypes.func.isRequired
};

export default CopyLinks;
