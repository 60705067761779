import React, { useEffect } from "react";

import AvailableCampaignCard from "./AvailableCampaignCard";
import ActiveCampaignCard from "./ActiveCampaignCard";
import { useCampaignsContext } from "./CampaignsContainer";

const selectStyle = {
  border: "1px solid #B3B3B3",
  borderRadius: 4,
  color: "#333333",
  fontSize: 14,
  height: 40,
  margin: "10px 0",
  padding: 8,
  width: 250,
};

const titleStyle = {
  color: "#333333",
  fontSize: "16px",
  fontWeight: 600,
}

const headerStyle = {
  color: "#333333",
  fontSize: "16px",
  marginBottom: "15px",
};

const campaignsContainerStyle = {
  display: "grid",
  gap: "20px",
  gridTemplateColumns: "repeat(auto-fill, minmax(350px, 350px))",
};

const pageBreakStyle = {
  borderTop: "1px solid rgb(230, 230, 230)",
};

const CampaignsTab = () => {
  const {
    venueOwnerships,
    venueOwnership,
    setVenueOwnership,
    isLoading,
    setIsLoading,
    fetchCampaigns,
    activeCampaigns,
    availableCampaignTemplates,
  } = useCampaignsContext();

  const handleVenueOwnershipChange = (e) => {
    e.preventDefault();

    setVenueOwnership(venueOwnerships[e.target.value])
  };

  useEffect(() => {
    setIsLoading(true);
    fetchCampaigns(venueOwnership.id);
  }, [venueOwnership]);

  return (
    <div>
      <div style={titleStyle}>Campaigns</div>
      <select value={venueOwnerships.findIndex(o => o.id === venueOwnership.id)} onChange={handleVenueOwnershipChange} style={selectStyle}>
        {venueOwnerships.map((option, index) => (
          <option key={`${option.venueable.name}-${index}`} value={index}>
            {option.venueable.name}
          </option>
        ))}
      </select>
      {availableCampaignTemplates.length ? (
        isLoading ? (
          <img src="/uploading-loading.gif" className="mr-1" style={{width: "16px"}} />
        ) : (
          <>
            <div style={headerStyle}>Available Campaigns</div>
            <div style={campaignsContainerStyle}>
              {availableCampaignTemplates.map((campaignTemplate, index) => (
                <AvailableCampaignCard
                  key={index}
                  campaignTemplate={campaignTemplate}
                />
              ))}
            </div>
          </>
        )
      ) : null}
      {availableCampaignTemplates.length > 0 && activeCampaigns.length > 0 ? (
        <hr style={pageBreakStyle} />
      ) : null}
      {activeCampaigns.length ? (
        isLoading ? (
          <img src="/uploading-loading.gif" className="mr-1" style={{width: "16px"}} />
        ) : (
          <>
            <div style={headerStyle}>
              Active Campaigns
            </div>
            <div style={campaignsContainerStyle}>
              {activeCampaigns.map((campaign, index) => (
                <ActiveCampaignCard
                  key={index}
                  campaign={campaign}
                />
              ))}
            </div>
          </>
        )
      ) : null}
    </div>
  );
};

export default CampaignsTab;
