import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import TagManagerApp from '../../TagManager/startup/TagManagerApp';

import { ToastContainer, toast } from 'react-toastify';

const ArtistDetails = ({
  csrfToken,
  team,
  currentUser,
  artist,
  artistChanged,
  tagManagerOpen,
  tagManagerOpenChanged,
  tagManagerFilter,
  tagManagerFilterChanged,
  getActiveTags,
  getAvailableTags,
  availableTags,
  availableTagsChanged,
}) => {
  return (
    <React.Fragment>
      <ToastContainer />
      <TagManagerApp
        csrfToken={csrfToken}
        team={team}
        scope="Artist"
        title={`Tags for ${artist.name}`}
        taggable={artist}
        containerRowClasses="m-0"
        containerColClasses="px-4 pt-2"
      />
    </React.Fragment>
  )
};

ArtistDetails.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  artist: PropTypes.object.isRequired,
  artistChanged: PropTypes.func.isRequired,
  tagManagerOpen: PropTypes.bool,
  tagManagerOpenChanged: PropTypes.func.isRequired,
  tagManagerFilter: PropTypes.string,
  tagManagerFilterChanged: PropTypes.func.isRequired,
  getActiveTags: PropTypes.func.isRequired,
  getAvailableTags: PropTypes.func.isRequired,
  availableTags: PropTypes.array,
  availableTagsChanged: PropTypes.func.isRequired,
};

export default ArtistDetails;
