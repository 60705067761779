import React, { useState, useEffect } from "react";
import Tippy from '@tippyjs/react';
import axios from "axios";

const errorsContainerStyle = {
  alignItems: "center",
  color: "#333333",
  display: "flex",
  flexDirection: "row",
  fontSize: "16px",
  gap: "10px",
};

const noErrorIconStyle = {
  color: "#8AC926",
};

const yesErrorIconStyle = {
  color: "#FF595E",
}

export const parseLiquid = async (template, sampleData, teamId, id, csrfToken) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
  const response = await axios.post(`/teams/${teamId}/beefree_templates/${id}/parse_liquid`, {
    beefree_template: {
      template: template,
      sample_data: sampleData,
    }
  });
  return response.data;
};

export const parseLiquidTemplate = async (template, sampleData, teamId, id, csrfToken) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
  const response = await axios.post(`/teams/${teamId}/beefree_templates/${id}/parse_liquid_template`, {
    beefree_template: {
      template: template,
      sample_data: sampleData,
    }
  });
  return response.data;
};

const ErrorsContainer = ({
  templatableContext,
}) => {
  const {
    csrfToken,
    team,
    template,
    body,
    sampleData,
    isEmailTemplate,
    errors,
    setErrors,
    beefreeTemplate,
    setProcessedTemplate,
  } = templatableContext();

  const [isParsing, setIsParsing] = useState(false);

  useEffect(() => {
    setIsParsing(true);

    const delayDebounce = setTimeout(() => {
      if (isEmailTemplate) {
        parseLiquidTemplate(template, sampleData, team.id, beefreeTemplate.id, csrfToken).then(({ liquidErrors, processedTemplate }) => {
          setProcessedTemplate(processedTemplate);
          setErrors(liquidErrors);
          setIsParsing(false);
        });
      } else {
        parseLiquid(body, sampleData, team.id, beefreeTemplate.id, csrfToken).then(({ parsedText, liquidErrors }) => {
          setParsedBody(parsedText);
          setErrors(liquidErrors);
          setIsParsing(false);
        });
      }
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [template, body, sampleData]);

  return (
    isParsing ? (
      <i className="fas fa-spinner fa-spin" />
    ) : (
      <div style={errorsContainerStyle}>
          {errors?.length > 0 ? (
            <Tippy
              content={
                <>
                  <div>
                    The following liquid values could not be resolved:
                  </div>
                  {errors.map((error, index) => (
                    <div key={index}>
                      {error}
                    </div>
                  ))}
                </>
              }
            >
              <i className="far fa-times-circle" style={yesErrorIconStyle} />
            </Tippy>
          ) : (
            <i className="far fa-check-circle" style={noErrorIconStyle} />
          )}
          <div>
            {errors?.length || "No"} Errors
          </div>
      </div>
    )
  );
};

export default ErrorsContainer;
