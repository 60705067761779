import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "200px",
  "padding": "0px"
}

const ticketTypeCurrentlyHidden = (ticketType) => {
  var now = new Date();
  var startTime = new Date(ticketType.start_time);

  return (
    ticketType.visibility === "Hidden"
      || (
        ticketType.visibility === "Hidden when not on sale"
          && now <=  startTime
      )
  );
}

const ticketTypeStartTime = (ticketType) => {
  var startTimePieces = [];

  if(ticketType.scheduled){
    startTimePieces.push("Scheduled");
  }

  if(ticketType.wait_for_ticket_type && ticketType.wait_for_ticket_type.id){
    startTimePieces.push("Starts after " + ticketType.wait_for_ticket_type.name);
  } else {
    startTimePieces.push(ticketType.human_start_time);
  }

  return startTimePieces.join(" • ");
}

const draggablePropsWithStyle = (provided, snapshot) => {
  var props =  {...provided.draggableProps};
  var style = Object.assign({}, props.style, {
    "display": (snapshot.isDragging ? "table" : "")
  });

  return Object.assign({}, props, {style: style});
}

const TicketTypeRow = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  fetchNewTicketType,
  ticketType,
  deleteTicketType,
  fetchTicketTypeForEdit,
  provided,
  snapshot,
  ticketTypeIsSeated
}) => (
  <tr ref={provided.innerRef} {...draggablePropsWithStyle(provided, snapshot)}>
    <td className="text-muted text-center">
      <a {...provided.dragHandleProps}
         onClick={
           (e) => {
             e.preventDefault();
           }
         }
         href="#"
         className={"btn btn-link btn-sm small text-muted " + (ticketTypeIsSeated(ticketType) ? "hide" : "")}>
        <i className="fas fa-grip-vertical"></i>
      </a>
    </td>
    <td className="overflow-ellipsis">
      <strong title={ticketType.name}>
        {ticketType.name}
      </strong>
      <br />
      <span className="text-muted"
            title={ticketTypeStartTime(ticketType)}>
        {ticketTypeStartTime(ticketType)}
        {ticketTypeCurrentlyHidden(ticketType) ? (
          <i className="fas fa-eye-slash small ml-2"></i>
        ) : null}
      </span>
    </td>
    <td className="text-muted text-center">
      {ticketType.number_of_tickets_sold}/{ticketType.available}
    </td>
    <td className="text-muted text-center">
      {ticketType.free ? (
        "FREE"
      ) : (
        formatter.format(ticketType.price)
      )}
    </td>
    <td className="text-muted text-center">
      <Popup arrow={false}
             offsetY={5}
             position="bottom right"
             contentStyle={popupContentStyle}
             onOpen={
               (e) => {
                 e.stopPropagation();
                 e.preventDefault();
               }
             }
             trigger={open => (
               <a href="#"
                  onClick={
                    (e) => {
                      e.preventDefault();
                    }
                  }
                  className="text-muted">
                 <i className="fas fa-ellipsis-v"></i>
               </a>
             )} >
        {close => (
          <div className='row text-left'>
            <div className="col-12">
              <ul className="list-group offer-inline-menu">
                <li className={"list-group-item px-3 pb-2 " + (ticketTypeIsSeated(ticketType) ? "pt-2" : "pt-3")}>
                  <a href="#"
                     onClick={
                       (e) => {
                         e.preventDefault();
                         close();
                         fetchTicketTypeForEdit(csrfToken, team, ticketType);
                       }
                     }>
                    <span className="d-inline-block text-center text-muted" style={{width: "28px"}}>
                      <i className="fas fa-pencil fa-fw"></i>
                    </span>
                    <span className="text-dark ml-3">
                      Edit
                    </span>
                  </a>
                </li>
                {!ticketTypeIsSeated(ticketType) ? (
                  <React.Fragment>
                    <li className="list-group-item px-3 py-2">
                      <a href="#"
                         onClick={
                           (e) => {
                             e.preventDefault();
                             close();
                             fetchNewTicketType(csrfToken, team, confirm, ticketType);
                           }
                         }>
                        <span className="d-inline-block text-center text-muted" style={{width: "28px"}}>
                          <i className="far fa-copy fa-fw"></i>
                        </span>
                        <span className="text-dark ml-3">
                          Copy
                        </span>
                      </a>
                    </li>
                    <li className="list-group-item pb-3 px-3 pt-2">
                      <a href="#"
                         onClick={
                           (e) => {
                             e.preventDefault();
                             close();
                             deleteTicketType(csrfToken, team, ticketType);
                           }
                         }
                         className="text-danger">
                        <span className="d-inline-block text-center" style={{width: "28px"}}>
                          <i className="far fa-trash fa-fw"></i>
                        </span>
                        <span className="ml-3">
                          Delete
                        </span>
                      </a>
                    </li>
                  </React.Fragment>
                ) : null}
              </ul>
            </div>
          </div>
        )}
      </Popup>
    </td>
  </tr>
);

TicketTypeRow.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  fetchNewTicketType: PropTypes.func.isRequired,
  ticketType: PropTypes.object.isRequired,
  deleteTicketType: PropTypes.func.isRequired,
  fetchTicketTypeForEdit: PropTypes.func.isRequired,
  provided: PropTypes.object.isRequired,
  snapshot: PropTypes.object.isRequired,
  ticketTypeIsSeated: PropTypes.func.isRequired
};

export default TicketTypeRow;
