import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Popup from "reactjs-popup";

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "206px",
  "padding": "0px"
}

const PageFrame = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  showPageFrame,
  children
}) => {
  return (
    showPageFrame ? (
      <div>
        <div className="row">
          <div className="col-12 col-md">
            <div className="page-title d-flex align-items-center"
                  style={{"marginBottom": "4px"}}>
              <span style={{"fontSize": "20px"}}>
                {confirm.name}
              </span>
              {confirm.calendar_classification === "past" ? (
                <span className="badge badge-secondary mt-1 ml-3 p-2"
                      style={{"background": "#919191", "color": "white", "fontSize": "10px"}}>
                  Past
                </span>
              ) : confirm.calendar_classification === "canceled" ? (
                <span className="badge badge-danger mt-1 ml-3 p-2"
                      style={{"fontSize": "10px"}}>
                  Canceled
                </span>
              ) : confirm.calendar_classification === "on_sale" ? (
                <span className="badge badge-secondary mt-1 ml-3 p-2"
                      style={{"background": "#5f5383", "color": "white", "fontSize": "10px"}}>
                  On Sale
                </span>
              ) : confirm.calendar_classification === "announced" ? (
                <span className="badge badge-secondary mt-1 ml-3 p-2"
                      style={{"background": "#98c648", "color": "white", "fontSize": "10px"}}>
                  Announced
                </span>
              ) : (
                <span className="badge badge-secondary mt-1 ml-3 p-2"
                      style={{"background": "#4c81b9", "color": "white", "fontSize": "10px"}}>
                  Confirmed
                </span>
              )}
            </div>
            <p className='text-muted'>
              {confirm.human_metadata}
            </p>
          </div>
          <div className="col-12 col-md-auto d-flex justify-content-end"
              style={{"paddingRight": "15px"}}>
            <Popup arrow={false}
                  offsetY={10}
                  position="bottom right"
                  contentStyle={popupContentStyle}
                  onOpen={
                    (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }
                  }
                  trigger={open => (
                    <a className="btn btn-primary btn-sm align-self-end"
                      style={{"marginBottom": "16px", "borderRadius": "8px"}}
                      href="#">
                      Actions
                      <i aria-hidden className="fas fa-caret-down pl-2"></i>
                    </a>
                  )} >
            {close => (
              <div className='row text-left'>
                <div className="col-12">
                  <ul className="list-group offer-inline-menu">
                    <li className="list-group-item" style={{"padding": "10px 18px"}}>
                      <a href={`/teams/${team.id}/confirms/${confirm.id}/profit_and_loss.pdf`}
                          className='text-dark'>
                        <i className="far fa-file-pdf fa-fw text-muted" style={{"marginRight": "15px"}}></i>
                        Download PDF
                      </a>
                    </li>
                    <li className="list-group-item" style={{"padding": "10px 18px"}}>
                      <a href={`/teams/${team.id}/confirms/${confirm.id}/profit_and_loss.csv`}
                          className='text-dark'>
                        <i className="far fa-file-csv fa-fw text-muted" style={{"marginRight": "15px"}}></i>
                        Download CSV
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            </Popup>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card no-border shadow-2">
              <div className="card-body" style={{"padding": "15px 15px 200px 15px"}}>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div>{children}</div>
    )
  );
};

PageFrame.propTypes = {
  csrfToken: PropTypes.string,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  confirm: PropTypes.object,
  showPageFrame: PropTypes.bool,
  children: PropTypes.element.isRequired
};

export default PageFrame;
