import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import LinkOfferModalApp from '../../LinkOfferModal/startup/LinkOfferModalApp';

var ReactDOM = require('react-dom');
import Popup from "reactjs-popup";
import OfferModalApp from '../../OfferModal/startup/OfferModalApp';
import OfferV2ModalApp from '../../OfferV2Modal/startup/OfferV2ModalApp';
import OfferV2PreviewApp from '../../OfferV2Preview/startup/OfferV2PreviewApp';
import DataTable from '../../DataTable/components/DataTable';
import { ToastContainer, toast } from 'react-toastify';
import PipelineSlidingPaneApp from '../../PipelineSlidingPane/startup/PipelineSlidingPaneApp';

import $ from 'jquery';
require('datatables.net-bs4');

import {
  FORM_STATE,
  PREVIEW_STATE,
} from '../../OfferModal/constants/offerModalConstants';

const decodeHtml = (html) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

const modalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "800px",
  "padding": "0px"
}

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "250px",
  "padding": "0px"
}

const deleteModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const linkOfferModalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "700px",
  "padding": "0"
}

const OfferManager = ({
  modalIsOpen,
  openModal,
  closeModal,
  offer,
  venuesOptions,
  csrfToken,
  team,
  defaultOffer,
  onInit,
  dataTableTitle,
  dataTableDataSource,
  dataTableOrder,
  dataTableColumns,
  dataTableClassNames,
  dataTableEl,
  dataTableElChanged,
  fetchOfferData,
  deleteOffer,
  acceptOffer,
  offerTemplates,
  currentUser,
  declineOffer,
  fetchPipeline,
  pipeline,
  pipelineChanged,
  venueSelected,
  offerToLink,
  offerToLinkChanged,
  venueOwnerships
}) => {

  useEffect(() => {
    onInit(defaultOffer);
    const params = (new URL(document.location)).searchParams;
    const offerPipelineId = params.get("offer");
    if (offerPipelineId) {
      fetchPipeline(team, offerPipelineId);
    }
  }, [])

  return (
    <div>
      <ToastContainer />
      <div className="datatable-right-header-section">
        <a href="#"
           onClick={(e) => {
             e.preventDefault();
             openModal();
           }}
           className="btn btn-primary">
          <i className="fal fa-plus"></i>
          &nbsp;
          <strong>New Offer</strong>
        </a>
      </div>
      <DataTable title={dataTableTitle}
                 dataSource={dataTableDataSource}
                 columns={dataTableColumns}
                 tableClassNames={dataTableClassNames}
                 colGroup={
                   <colgroup>
                     <col span={1} style={{"width": "50%"}} />
                     <col span={1} style={{"width": "20%"}} />
                     <col span={1} style={{"width": "15%"}} />
                     <col span={1} style={{"width": "10%"}} />
                     <col span={1} style={{"width": "5%"}} />
                   </colgroup>
                 }
                 zeroRecords={"<span class='offer-zero-records-mount-react'></span>"}
                 drawCallback={
                   (settings) => {
                     if(!settings.aoData.length){
                       var tableEl = settings.nTable;
                       var mountReact = tableEl.getElementsByClassName("offer-zero-records-mount-react")[0];

                       ReactDOM.render((
                          <div className='row text-left pt-4'>
                            <div className='col-12'>
                              <div className="card no-border zero-offer-records-card">
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-12 col-lg-8">
                                      <h4 style={{'fontSize': "20px"}}>Create and Send an Offer!</h4>
                                      <p className='text-muted pb-3'>With Opendate, offer creation is super easy.</p>
                                      <ol className="circled-numbers">
                                        <li>
                                          <h5 style={{'fontSize': "14px"}}>Enter your event details like venue, date, and artist</h5>
                                          <p className="text-muted small">Select from existing confirms on your calendar or start fresh. You can also search our database of nearly 170k artists.</p>
                                        </li>
                                        <li>
                                          <h5 style={{'fontSize': "14px"}}>Add your deal terms and any additional info</h5>
                                          <p className="text-muted small">We've added a handful of terms to help inspire you and get you started. Edit, delete, and add your own.</p>
                                        </li>
                                        <li>
                                          <h5 style={{'fontSize': "14px"}}>Save, preview and send</h5>
                                          <p className="text-muted small">When you're ready, send it off to the agent for acceptance. Be sure to save the offer as a template to make it even easier the next time.</p>
                                        </li>
                                      </ol>
                                      <h5 className="py-3" style={{'fontSize': "16px"}}>Get started and create your first offer in Opendate today!</h5>
                                      <a href="#"
                                         onClick={(e) => {
                                           e.preventDefault();
                                           openModal();
                                         }}
                                         className="btn btn-primary">
                                        Create Offer
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                       ), mountReact);
                     }
                   }
                 }
                 rowCallback={
                    (row, data) => {
                      var mountReact = row.getElementsByClassName("offer-popup-mount-react")[0];
                      var rowOffer   = data;
                      var offer      = JSON.parse(decodeHtml(rowOffer.json));

                      var mountArtistReact = row.getElementsByClassName("offer-artist-mount-react")[0];
                      ReactDOM.render((
                        <span>
                          <a href={`/teams/${team.id}/offers/${offer.id}/edit`}
                             className="strong">
                            {offer.artist_or_event_name}
                          </a>
                        </span>
                      ), mountArtistReact);

                      ReactDOM.render((
                        <Popup arrow={false}
                               offsetY={5}
                               position="bottom right"
                               contentStyle={popupContentStyle}
                               onOpen={
                                 (e) => {
                                   e.preventDefault();
                                 }
                               }
                               trigger={open => (
                                 <a href="#">
                                   <i className="fas fa-ellipsis-v"></i>
                                 </a>
                               )} >
                          {close => (
                            <div className='row text-left'>
                              <div className="col-12">
                                <ul className="list-group offer-inline-menu highlight-primary">
                                  {rowOffer.state !== "accepted" && rowOffer.state !== "declined" ? (
                                    <li className="list-group-item">
                                      <a href="#"
                                         className="text-muted"
                                         onClick={
                                           (e) => {
                                             e.preventDefault();
                                             var rowTableEl = row.parentElement.parentElement;

                                             close();
                                             acceptOffer(team, rowOffer.id, csrfToken, rowTableEl);
                                         }
                                      }>
                                        <span className="d-inline-block text-center" style={{width: "28px"}}>
                                          <i className="fas fa-check-circle"></i>
                                        </span>
                                        Mark as Accepted
                                      </a>
                                    </li>
                                  ) : null}
                                  {rowOffer.state !== "accepted" && rowOffer.state !== "declined" ? (
                                    <li className="list-group-item">
                                      <a href="#"
                                         className="text-muted"
                                         onClick={
                                           (e) => {
                                             e.preventDefault();
                                             var rowTableEl = row.parentElement.parentElement;

                                             close();
                                             declineOffer(team, rowOffer.id, csrfToken, rowTableEl);
                                         }
                                      }>
                                        <span className="d-inline-block text-center" style={{width: "28px"}}>
                                          <i className="far fa-times-circle"></i>
                                        </span>
                                        Mark as Declined
                                      </a>
                                    </li>
                                  ) : null}
                                  <li className="list-group-item">
                                    <a href={"/teams/" + team.id + "/offers/" + offer.id + "/preview.pdf"}
                                       target="_blank"
                                       className="text-muted">
                                      <span className="d-inline-block text-center" style={{width: "28px"}}>
                                        <i className="fas fa-download"></i>
                                      </span>
                                      Download PDF
                                    </a>
                                  </li>
                                  <li className="list-group-item">
                                    <a href="#"
                                       onClick={
                                         (e) => {
                                           e.preventDefault();
                                           fetchOfferData(team, rowOffer.id, PREVIEW_STATE);
                                           close();
                                         }
                                       }
                                       className="text-muted">
                                      <span className="d-inline-block text-center" style={{width: "28px"}}>
                                        <i className="fas fa-search"></i>
                                      </span>
                                      Preview
                                    </a>
                                  </li>
                                  <li className="list-group-item">
                                    <a href="#"
                                       onClick={
                                         (e) => {
                                           e.preventDefault();

                                           if(offer.ui_version === 1){
                                             fetchOfferData(team, rowOffer.id, FORM_STATE);
                                           } else {
                                             window.location.href = ("/teams/" + team.id + "/offers/" + offer.id + "/edit");
                                           }

                                           close();
                                         }
                                       }
                                       className="text-muted">
                                      <span className="d-inline-block text-center" style={{width: "28px"}}>
                                        <i className="fas fa-pencil"></i>
                                      </span>
                                      Edit
                                    </a>
                                  </li>
                                  {offer.ui_version === 2 && !offer.calendar_event ? (
                                    <li className="list-group-item">
                                      <a href="#"
                                         onClick={
                                           (e) => {
                                             e.preventDefault();
                                             close();
                                             offerToLinkChanged(offer);
                                           }
                                         }
                                         className="text-muted">
                                        <span className="d-inline-block text-center" style={{width: "28px"}}>
                                          <i className="fas fa-link"></i>
                                        </span>
                                        Link to event
                                      </a>
                                    </li>
                                  ) : null}
                                  <li className="list-group-item delete-offer-li">
                                    <Popup
                                        trigger={
                                          <a href="#" className="text-danger">
                                            <span className="d-inline-block text-center" style={{width: "28px"}}>
                                              <i className="fas fa-trash"></i>
                                            </span>
                                            Delete
                                          </a>
                                        }
                                        modal
                                        contentStyle={deleteModalContentStyle}
                                        closeOnDocumentClick
                                      >
                                      {closeDeletePopup => (
                                        <div>
                                          <div className="row">
                                            <div className="col">
                                              <div className="row">
                                                <div className="col">
                                                  <h5 style={{"marginBottom": "16px"}}>
                                                    <div className="strong">Delete offer?</div>
                                                  </h5>
                                                </div>
                                                <div className="col-xs-auto">
                                                  <a href="#"
                                                     className="text-dark"
                                                     style={{"paddingRight": "15px"}}
                                                     onClick={(e) => {e.preventDefault(); closeDeletePopup();}} >
                                                    <i className="fa fa-times"></i>
                                                  </a>
                                                </div>
                                              </div>
                                              <p>You're about to delete this offer.</p>
                                              <p>This cannot be undone.</p>
                                              <p>Are you sure?</p>
                                              <div className="text-right">
                                                <a href="#"
                                                   onClick={
                                                     (e) => {
                                                       e.preventDefault();
                                                       closeDeletePopup();
                                                     }
                                                   }>
                                                  Cancel
                                                </a>
                                                <a href="#"
                                                   className="btn btn-danger ml-3"
                                                   onClick={
                                                     (e) => {
                                                       e.preventDefault();
                                                       var rowTableEl = row.parentElement.parentElement;

                                                       closeDeletePopup();
                                                       close();
                                                       deleteOffer(team, rowOffer.id, csrfToken, rowTableEl);
                                                     }
                                                   }>
                                                  Yes, delete offer
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                        </Popup>
                      ), mountReact);
                    }
                 }
                 onTableElementSet={
                   (el) => {
                     dataTableElChanged(el);
                   }
                 }
                 order={dataTableOrder} />
      <Popup
        open={modalIsOpen}
        contentStyle={modalContentStyle}
        closeOnDocumentClick={false}
        className={"offer-modal"}
        onClose={() => closeModal(defaultOffer, dataTableEl)}
      >
        {Object.keys(offer).length > 0 && offer.id && offer.ui_version === 1 ? (
          <OfferModalApp offer={offer}
               currentUser={currentUser}
               defaultOffer={defaultOffer}
               csrfToken={csrfToken}
               closeModal={closeModal}
               offerTemplates={offerTemplates}
               dataTableEl={dataTableEl}
               venuesOptions={venuesOptions}
               team={team} />
        ) : Object.keys(offer).length > 0 && offer.id && offer.ui_version === 2 ? (
          <OfferV2PreviewApp offer={offer}
                             currentUser={currentUser}
                             csrfToken={csrfToken}
                             onClose={
                               () => {
                                 closeModal(defaultOffer, dataTableEl);
                               }
                             }
                             onSuccess={
                               (offer) => {
                                 closeModal(defaultOffer, dataTableEl);

                                 toast.success("Offer has been sent.", {
                                   position: toast.POSITION.TOP_CENTER,
                                   draggable: false,
                                   closeOnClick: false,
                                   autoClose: 5000,
                                   hideProgressBar: true
                                 });
                               }
                             }
                             team={team} />
        ) : (
          <OfferV2ModalApp currentUser={currentUser}
                           csrfToken={csrfToken}
                           onClose={
                             () => {
                               closeModal(defaultOffer, dataTableEl)
                             }
                           }
                           venuesOptions={venuesOptions}
                           venueSelected={venueSelected}
                           venueOwnerships={venueOwnerships}
                           team={team} />
        )}
      </Popup>
      <Popup
        open={Object.keys(offerToLink).length > 0}
        contentStyle={linkOfferModalContentStyle}
        closeOnDocumentClick={false}
        className={"offer-modal"}
        onClose={() => {
          offerToLinkChanged({});
        }}
      >
        <LinkOfferModalApp currentUser={currentUser}
                           csrfToken={csrfToken}
                           offer={offerToLink}
                           onClose={
                             () => {
                               offerToLinkChanged({});
                             }
                           }
                           onSuccess={
                             (data) => {
                               $(dataTableEl).DataTable().ajax.reload();
                               offerToLinkChanged({});

                               toast.success("Offer has been updated.", {
                                 position: toast.POSITION.TOP_CENTER,
                                 draggable: false,
                                 closeOnClick: false,
                                 autoClose: 5000,
                                 hideProgressBar: true
                               });
                             }
                           }
                           team={team} />
      </Popup>
      <PipelineSlidingPaneApp pipelineSlidingPane={{
                                pipeline: pipeline,
                                currentUser: currentUser,
                                onClose: () => {
                                  pipelineChanged({});
                                }
                              }} />
    </div>
  )
};

OfferManager.propTypes = {
  modalIsOpen: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  offer: PropTypes.object,
  venuesOptions: PropTypes.array,
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  defaultOffer: PropTypes.object.isRequired,
  onInit: PropTypes.func.isRequired,
  dataTableTitle: PropTypes.string.isRequired,
  dataTableDataSource: PropTypes.string.isRequired,
  dataTableOrder: PropTypes.array,
  dataTableColumns: PropTypes.array.isRequired,
  dataTableClassNames: PropTypes.string,
  dataTableEl: PropTypes.object,
  dataTableElChanged: PropTypes.func.isRequired,
  fetchOfferData: PropTypes.func.isRequired,
  deleteOffer: PropTypes.func.isRequired,
  acceptOffer: PropTypes.func.isRequired,
  offerTemplates: PropTypes.array,
  currentUser: PropTypes.object.isRequired,
  declineOffer: PropTypes.func.isRequired,
  fetchPipeline: PropTypes.func.isRequired,
  pipeline: PropTypes.object,
  pipelineChanged: PropTypes.func.isRequired,
  venueSelected: PropTypes.object,
  offerToLink: PropTypes.object,
  offerToLinkChanged: PropTypes.func.isRequired,
  venueOwnerships: PropTypes.array
};

export default OfferManager;
