import React, { useCallback } from 'react';
import ReactDOM from "react-dom";

import SmsPreview from "./SmsPreview";
import WorkflowDetails from "./WorkflowDetails";
import EmailPreview from "./EmailPreview";
import { useCampaignContext } from "./CampaignContainer";
import { toastError } from "../../../shared/toastHelper";
import ContainerlessTableViewApp from "../../TableView/startup/ContainerlessTableViewApp";
import AhoyMessageOverlay from "../BeefreeTemplates/AhoyMessageOverlay";

const axios = require("axios").default;

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  gap: 20,
  padding: 20,
};

const campaignTitleStyle = {
  color: "#333333",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const startCampaignButtonStyle = {
  backgroundColor: "#1982C4",
  borderRadius: 5,
  color: "white",
  cursor: "pointer",
  fontSize: 12,
  fontWeight: 600,
  height: "fit-content",
  padding: "10px 12px",
  textWrap: "nowrap",
};

const descriptionStyle = {
  color: "#333333",
  fontSize: 12,
  textWrap: "wrap",
};

const columnHeaderStyle = {
  fontSize: 14,
  fontWeight: 600,
  paddingBottom: 10,
};

const backButtonStyle = {
  padding: "25px 12px",
  color: "#1982C4",
  fontWeight: "bold",
  fontSize: "12px",
};

const headerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: 5,
};

const campaignNameStyle = {
  fontSize: 14,
  fontWeight: 600,
};

const descriptionLabelStyle = {
  color: "#333333",
  fontSize: 10,
  opacity: 0.5,
};

const buttonsStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: 10,
};

const gridStyle = {
  display: "grid",
  gap: "20px",
  gridTemplateColumns: "1fr 2fr",
};

const previewContainerStyle = {
  background: "white",
  borderRadius: 5,
  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  gap: 20,
  height: "100%",
  overflowY: "scroll",
  padding: 20,
};

const tableContainerStyle = {
  backgroundColor: "white",
  borderRadius: 10,
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  display: "flex",
  flexDirection: "column",
  margin: "30px 0",
  padding: 20,
};

const CampaignShow = () => {
  const {
    csrfToken,
    team,
    venueOwnership,
    campaign,
    ahoyMessagesDataTableProps,
    setAhoyMessageId,
    previouslySent,
  } = useCampaignContext();

  const startCampaign = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.put("/teams/" + team.id + "/campaigns/" + campaign.id, { state: "active" })
      .then(() => {
        window.location.href = `/teams/${team.id}/campaigns`;
      })
      .catch(_error => toastError("Could not start campaign, please try again"));
  };

  const rowCallback = useCallback((row, data) => {
    const mountLink = row.getElementsByClassName("timestamp-link")[0];
    const id = data.id;
    const timestamp = data.timestamp;

    ReactDOM.render(
      <a href="#" onClick={(e) => { e.preventDefault(); setAhoyMessageId(id); }}>
        {timestamp}
      </a>,
      mountLink
    );
  }, []);

  const hasActions = campaign.campaign_actions.length > 0;

  return (
    <div style={containerStyle}>
      <AhoyMessageOverlay ahoyableContext={useCampaignContext} />
      <div style={headerStyle}>
        <div style={campaignTitleStyle}>
          <div style={headerStyle}>
            <div style={campaignNameStyle}>
              Campaigns / {venueOwnership.venueable.name} / {campaign.campaign_template.name}
            </div>
            <div style={descriptionLabelStyle}>
              DESCRIPTION
            </div>
            <div style={descriptionStyle}>
              {campaign.campaign_template.description}
            </div>
          </div>
          <div style={buttonsStyle}>
            <a href={`/teams/${team.id}/campaigns`} style={backButtonStyle}>
              Back
            </a>
            {campaign.state === "available" ? (
              <div style={startCampaignButtonStyle} onClick={startCampaign}>
                Start Campaign
              </div>
            ) : (
              null
            )}
          </div>
        </div>
      </div>
      <div style={gridStyle}>
        <div>
          <div style={columnHeaderStyle}>
            Details
          </div>
          <WorkflowDetails />
        </div>
        <div>
          <div style={columnHeaderStyle}>
            Preview
          </div>
          <div style={previewContainerStyle}>
            {hasActions ? (
              campaign.campaign_actions.map((action, index) => (
                action.type === "CustomerIOActions::Twilio" ? (
                  <SmsPreview
                    key={index}
                    action={action}
                  />
                ) : (
                  <EmailPreview
                    key={index}
                    action={action}
                  />
                )
              ))
            ) : (
              <div style={{
                color: "#333333",
                fontSize: 16,
              }}>
                Data Collection Only
              </div>
            )}
          </div>
        </div>
      </div>
      {previouslySent ? (
        <div style={tableContainerStyle}>
          <ContainerlessTableViewApp
            {...ahoyMessagesDataTableProps}
            rowCallback={rowCallback}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CampaignShow;
