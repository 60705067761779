/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import {
  VENUE_SELECTED_CHANGED,
  EVENTS_CHANGED,
  CALENDAR_EVENT_CONFLICTS_CHANGED,
  OPEN_CALENDAR_EVENT_CONFLICTS_MODAL,
  CLOSE_CALENDAR_EVENT_CONFLICTS_MODAL,
  USER_UPDATED,
  CALENDAR_EVENT_CONFLICTS_MERGE_CHECKBOX_CLICKED,
  TOGGLE_ON_SALE_ENABLED,
  TOGGLE_ANNOUNCED_ENABLED,
  TOGGLE_CONFIRMED_ENABLED,
  TOGGLE_HOLDS_ENABLED,
  TOGGLE_PAST_EVENTS_ENABLED,
  TOGGLE_CANCELLED_EVENTS_ENABLED,
  CALENDAR_REF_UPDATED,
  CALENDAR_START_DATE_UPDATED,
  MONTH_VIEW_CHANGED,
  DATA_TABLE_FILTERS_CHANGED,
  RELOAD_DATA_TABLE,
  DATA_TABLE_EL_CHANGED,
  SEARCH_TERM_CHANGED,
  CURRENT_TEAM_MEMBERSHIP_CHANGED,
  CHECK_AVAILS_IS_OPEN_CHANGED,
  CHECK_AVAILS_SETTINGS_CHANGED,
  CALENDAR_SYNC_IS_OPEN_CHANGED,
  CLASSIFICATION_TOTALS_CHANGED,
  CHECK_AVAILS_DATES_CHANGED,
  CHECK_AVAILS_CALENDAR_EVENTS_CHANGED,
  CHECK_AVAILS_LOADING_CHANGED
} from '../constants/calendarViewConstants';

export const updateSelectedVenue = (venues, selected) => ({
  type: VENUE_SELECTED_CHANGED,
  venues,
  selected
});

export const fetchCalendarEventsForSelectedVenues = (dispatch, team, selectedVenues) => {
  return (dispatch, getState) => {
    var calendarView = getState().calendarView;

    if(!calendarView){
      return;
    }

    return axios.get("/teams/" + team.id + "/calendar_events", {
        params: {
          venue_ids: selectedVenues.map((s) => s.value),
          start_of_month: calendarView.calendarStartDate
        }
      })
      .then(({ data }) => {
        dispatch(updateAllEvents(data.calendar_events));
        dispatch(classificationTotalsChanged(data.totals));
      })
  };
};

export const updateAllEvents = (events) => ({
  type: EVENTS_CHANGED,
  events
});

export const fetchTeamVenueConflicts = (dispatch, team, selectedVenues, user) => {
  return dispatch => {
    if(!user.can_manage_calendar_event_conflicts){
      return;
    }

    return axios.get("/teams/" + team.id + "/calendar_event_conflicts", {
        params: {
          venue_ids: selectedVenues.map((s) => s.value)
        }
      })
      .then(({ data }) => {
        data.map((calendarEventConflict) => {
          calendarEventConflict.shouldMerge = true;
        });

        dispatch(calendarEventConflictsChanged(data));

        if(data.length > 0){
          dispatch(openCalendarEventConflictsModal());
        }
      })
  }
};

export const onInit = (dispatch, team, selectedVenues, user, dataTableFilters) => {
  dataTableFilters = Object.assign({}, dataTableFilters, {
    venue_ids: selectedVenues.map((s) =>  s.value)
  });

  return dispatch => {
    dispatch(fetchCalendarEventsForSelectedVenues(dispatch, team, selectedVenues));
    dispatch(fetchTeamVenueConflicts(dispatch, team, selectedVenues, user));
    dispatch(dataTableFiltersChanged(dataTableFilters));
  }
};

export const calendarEventConflictsChanged = (calendarEventConflicts) => ({
  type: CALENDAR_EVENT_CONFLICTS_CHANGED,
  calendarEventConflicts
});

export const openCalendarEventConflictsModal = () => ({
  type: OPEN_CALENDAR_EVENT_CONFLICTS_MODAL
});

export const closeCalendarEventConflictsModal = () => ({
  type: CLOSE_CALENDAR_EVENT_CONFLICTS_MODAL
});

export const confirmTeamVenueConflicts = (dispatch, csrfToken, team, calendarEventConflicts, selectedVenues) => {
  var mapping = calendarEventConflicts.map((calendarEventConflict) => {
    return {
      id: calendarEventConflict.id,
      resolution: (calendarEventConflict.shouldMerge ? "merge" : "ignore")
    };
  });

  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/calendar_event_conflicts/confirm", {
        mapping: mapping
      })
      .then(({ data }) => {
        dispatch(fetchCalendarEventsForSelectedVenues(dispatch, team, selectedVenues));
      })
  }
};

export const saveUserCalendarStartDay = (dispatch, csrfToken, calendarStartDay) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/user_settings", {
        user: {
          calendar_start_day: calendarStartDay
        }
      })
      .then(({ data }) => {
        dispatch(updateUser(data));
      })
  };
};

export const updateUser = (user) => ({
  type: USER_UPDATED,
  user
});

export const calendarEventConflictMergeCheckboxClicked = (calendarEventConflict, checked) => ({
  type: CALENDAR_EVENT_CONFLICTS_MERGE_CHECKBOX_CLICKED,
  calendarEventConflict,
  checked
});

export const toggleOnSaleEnabled = (onSaleEnabled) => ({
  type: TOGGLE_ON_SALE_ENABLED,
  onSaleEnabled
});

export const toggleAnnouncedEnabled = (announcedEnabled) => ({
  type: TOGGLE_ANNOUNCED_ENABLED,
  announcedEnabled
});

export const toggleConfirmedEnabled = (confirmedEnabled) => ({
  type: TOGGLE_CONFIRMED_ENABLED,
  confirmedEnabled
});

export const toggleHoldsEnabled = (holdsEnabled) => ({
  type: TOGGLE_HOLDS_ENABLED,
  holdsEnabled
});

export const togglePastEventsEnabled = (pastEventsEnabled) => ({
  type: TOGGLE_PAST_EVENTS_ENABLED,
  pastEventsEnabled
});

export const toggleCancelledEventsEnabled = (cancelledEventsEnabled) => ({
  type: TOGGLE_CANCELLED_EVENTS_ENABLED,
  cancelledEventsEnabled
});

export const calendarRefUpdated = (calendarRef) => ({
  type: CALENDAR_REF_UPDATED,
  calendarRef
})

export const calendarStartDateUpdated = (date) => ({
  type: CALENDAR_START_DATE_UPDATED,
  date
})

export const monthViewChanged = (monthView) => ({
  type: MONTH_VIEW_CHANGED,
  monthView
})

export const dataTableFiltersChanged = (dataTableFilters) => ({
  type: DATA_TABLE_FILTERS_CHANGED,
  dataTableFilters
})

export const reloadDataTable = () => ({
  type: RELOAD_DATA_TABLE
})

export const dataTableElChanged = (el) => ({
  type: DATA_TABLE_EL_CHANGED,
  el
})

export const searchTermChanged = (term) => ({
  type: SEARCH_TERM_CHANGED,
  term
})

export const saveTeamMembershipCalendarState = (dispatch, csrfToken, team, teamMembership, teamMembershipParams) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/teams/" + team.id + "/team_memberships/" + teamMembership.id, {
        team_membership: teamMembershipParams
      })
      .then(({ data }) => {
        dispatch(currentTeamMembershipChanged(data));
      })
  };
};

export const currentTeamMembershipChanged = (currentTeamMembership) => ({
  type: CURRENT_TEAM_MEMBERSHIP_CHANGED,
  currentTeamMembership
})

export const checkAvailsIsOpenChanged = (checkAvailsIsOpen) => ({
  type: CHECK_AVAILS_IS_OPEN_CHANGED,
  checkAvailsIsOpen
})

export const checkAvailsSettingsChanged = (checkAvailsSettings) => ({
  type: CHECK_AVAILS_SETTINGS_CHANGED,
  checkAvailsSettings
})

export const calendarSyncIsOpenChanged = (calendarSyncIsOpen) => ({
  type: CALENDAR_SYNC_IS_OPEN_CHANGED,
  calendarSyncIsOpen
})

export const classificationTotalsChanged = (classificationTotals) => ({
  type: CLASSIFICATION_TOTALS_CHANGED,
  classificationTotals
})

export const checkAvailsDatesChanged = (checkAvailsDates) => ({
  type: CHECK_AVAILS_DATES_CHANGED,
  checkAvailsDates
})

export const checkAvailsCalendarEventsChanged = (checkAvailsCalendarEvents) => ({
  type: CHECK_AVAILS_CALENDAR_EVENTS_CHANGED,
  checkAvailsCalendarEvents
})

export const fetchCheckAvailsCalendarEvents = (dispatch, team, selectedVenues, checkAvailsDates) => {
  return (dispatch, getState) => {
    dispatch(checkAvailsLoadingChanged(true));

    var adjustedStartDate = new Date(checkAvailsDates[0]);
    adjustedStartDate.setDate(adjustedStartDate.getDate() - 14);

    var adjustedEndDate = new Date(checkAvailsDates[1]);
    adjustedEndDate.setDate(adjustedEndDate.getDate() + 14);

    return axios.get("/teams/" + team.id + "/calendar_events", {
        params: {
          venue_ids: selectedVenues.map((s) => s.value),
          start_date: adjustedStartDate,
          end_date: adjustedEndDate
        }
      })
      .then(({ data }) => {
        dispatch(checkAvailsCalendarEventsChanged(data.calendar_events));
      })
      .finally(() => {
        dispatch(checkAvailsLoadingChanged(false));
      });
  };
};

export const checkAvailsLoadingChanged = (checkAvailsLoading) => ({
  type: CHECK_AVAILS_LOADING_CHANGED,
  checkAvailsLoading
})
