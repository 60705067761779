import { combineReducers } from 'redux';
import { DateUtils } from 'react-day-picker';
import {EditorState} from 'draft-js';

import {
  offsetDateForBrowser
} from '../../../shared/timeZoneLogic';

import {
  CONFIRM_CHANGED,
  CALENDAR_EVENT_TITLE_UPDATED,
  CALENDAR_EVENT_ARTISTS_UPDATED,
  CALENDAR_EVENT_TYPE_UPDATED,
  CALENDAR_EVENT_TICKET_FORECAST_CHANGED,
  CALENDAR_EVENT_NOTE_EDITOR_STATE_UPDATED,
  CALENDAR_EVENT_ERRORS_UPDATED,
  CALENDAR_EVENT_FORM_CLOSED,
  HOLD_GROUP_CALENDAR_DATE_CLICKED,
  HOLD_GROUP_HOLD_POSITION_UPDATED,
  HOLD_GROUP_HOLD_REMOVED,
  CONFIRM_START_DATE_UPDATED,
  CONFIRM_START_TIME_UPDATED,
  HOLD_GROUP_HOLD_AVAILABILITY_UPDATED,
  HOLD_GROUP_UPDATED,
  CONFIRM_UPDATED,
  CONFIRM_HOLD_UPDATED,
  VENUE_SELECTED_CHANGED,
  BUYERS_CHANGED,
  PROMOTERS_CHANGED,
  SELECTED_PROMOTER_CHANGED,
  SELECTED_BUYER_CHANGED,
  USER_CHANGED_DOORS_TIME_CHANGED,
  USER_CHANGED_EVENT_END_TIME_CHANGED,
  HOLD_GROUP_DELETION_CHANGED,
  HOLD_GROUP_DELETION_REASON_CHANGED,
  HOLD_GROUP_DELETION_EXPLANATION_CHANGED,
  HOLD_GROUP_DELETION_FOLLOW_UP_CHANGED,
  HOLD_GROUP_DELETION_FOLLOW_UP_AT_CHANGED,
  SUBMITTING_FORM_CHANGED,
  HOLD_GROUP_CHANGED
} from '../constants/eventFormConstants';

const holdGroup = (state = {}, action) => {
  if(typeof action.date !== 'undefined'){
    var actionDate = new Date(offsetDateForBrowser(action.date));
  }

  switch (action.type) {
    case HOLD_GROUP_CHANGED:
      return action.holdGroup;
    case HOLD_GROUP_CALENDAR_DATE_CLICKED:
      var holdGroupEntries = (state.hold_group_entries ? state.hold_group_entries : []);
      var holdGroupEntry   = holdGroupEntries.find((entry) => {
        var startDate = new Date(offsetDateForBrowser(entry.hold.start_time));

        return DateUtils.isSameDay(startDate, actionDate);
      });

      if (holdGroupEntry){
        var newHoldGroupEntries = [...holdGroupEntries].map((entry) => {
          var startDate = new Date(offsetDateForBrowser(entry.hold.start_time));

          if(DateUtils.isSameDay(startDate, actionDate)){
            return Object.assign({}, entry, {_destroy: !entry._destroy});
          } else {
            return entry;
          }
        });

        return Object.assign({}, state, {hold_group_entries: newHoldGroupEntries});
      } else {
        var newHoldGroupEntry   = {hold: {start_time: actionDate, position: 1}};
        var newHoldGroupEntries = [...holdGroupEntries, newHoldGroupEntry];

        return Object.assign({}, state, {hold_group_entries: newHoldGroupEntries});
      }
    case HOLD_GROUP_HOLD_REMOVED:
      var holdGroupEntries = [...state.hold_group_entries].map((holdGroupEntry) => {
        var startDate = new Date(offsetDateForBrowser(holdGroupEntry.hold.start_time));

        if(DateUtils.isSameDay(startDate, actionDate)){
          return Object.assign({}, holdGroupEntry, {_destroy: true});
        } else {
          return holdGroupEntry;
        }
      });

      return Object.assign({}, state, {hold_group_entries: holdGroupEntries});
    case HOLD_GROUP_HOLD_POSITION_UPDATED:
      var holdGroupEntries = [...state.hold_group_entries].map((holdGroupEntry) => {
        var startDate = new Date(offsetDateForBrowser(holdGroupEntry.hold.start_time));

        if(DateUtils.isSameDay(startDate, actionDate)){
          var hold = Object.assign({}, holdGroupEntry.hold, {position: action.position});
          return Object.assign({}, holdGroupEntry, {hold: hold});
        } else {
          return holdGroupEntry;
        }
      });

      return Object.assign({}, state, {hold_group_entries: holdGroupEntries});
    case CALENDAR_EVENT_FORM_CLOSED:
      return {};
    case HOLD_GROUP_HOLD_AVAILABILITY_UPDATED:
      var holdGroupEntries = [...state.hold_group_entries].map((holdGroupEntry) => {
        var startDate = new Date(offsetDateForBrowser(holdGroupEntry.hold.start_time));

        if(DateUtils.isSameDay(startDate, actionDate)){
          var holdAvailability = action.holdAvailability;

          var defaultTopHold = action.team.default_top_hold;
          var firstAvailable = holdAvailability.slice(defaultTopHold-1, -1).find((availity) => {
            return availity.hold_title === null;
          });

          if(holdGroupEntry._destroy) {
            var hold = Object.assign({}, holdGroupEntry.hold)
          } else {
            var hold = Object.assign({}, holdGroupEntry.hold, {
              position: firstAvailable.position,
              hold_availability: holdAvailability
            });
          }

          return Object.assign({}, holdGroupEntry, {hold: hold});
        } else {
          return holdGroupEntry;
        }
      });

      return Object.assign({}, state, {hold_group_entries: holdGroupEntries});
    case HOLD_GROUP_UPDATED:
      var holdGroupEntries = action.holdGroup.hold_group_entries.map((holdGroupEntry) => {
        var startDate = new Date(offsetDateForBrowser(holdGroupEntry.hold.start_time));
        var hold = Object.assign({}, holdGroupEntry.hold, {start_time: startDate});

        return Object.assign({}, holdGroupEntry, {hold: hold});
      });

      return Object.assign({}, action.holdGroup, {hold_group_entries: holdGroupEntries});
    default:
      return state;
  }
};

const confirm = (state = {}, action) => {
  switch (action.type) {
    case CONFIRM_CHANGED:
      return action.confirm;
    case CONFIRM_START_DATE_UPDATED:
      return Object.assign({}, state, {start_time: action.date});
    case CONFIRM_START_TIME_UPDATED:
      return Object.assign({}, state, {start_time: action.time});
    case CALENDAR_EVENT_FORM_CLOSED:
      return {};
    case CONFIRM_UPDATED:
      var startTime = new Date(action.confirm.start_time);

      return Object.assign({}, action.confirm, {
        start_time: startTime
      });
    case HOLD_GROUP_UPDATED:
      var firstHold = action.holdGroup.hold_group_entries[0].hold;
      var startDate = new Date(firstHold.start_time);
      var hold = Object.assign({}, firstHold, {start_time: startDate});

      return Object.assign({}, action.confirm, {hold: hold});
    case CONFIRM_HOLD_UPDATED:
      return Object.assign({}, state, {hold: action.hold});
    default:
      return state;
  }
};

const calendarEventTitle = (state = "", action) => {
  switch (action.type) {
    case CALENDAR_EVENT_TITLE_UPDATED:
      return action.title;
    case CALENDAR_EVENT_FORM_CLOSED:
      return "";
    case CALENDAR_EVENT_ARTISTS_UPDATED:
      if(action.userChangedCalendarEventTitle){
        return state;
      } else {
        return (action.artists || []).map((option) => {
          return option.label;
        }).join(" & ");
      }
    default:
      return state;
  }
};

const calendarEventArtists = (state = [], action) => {
  switch (action.type) {
    case CALENDAR_EVENT_ARTISTS_UPDATED:
      return action.artists;
    case CALENDAR_EVENT_FORM_CLOSED:
      return [];
    default:
      return state;
  }
};

const calendarEventType = (state = "HoldGroup", action) => {
  switch (action.type) {
    case CALENDAR_EVENT_TYPE_UPDATED:
      return action.calendarEventType;
    case CALENDAR_EVENT_FORM_CLOSED:
      return "HoldGroup";
    default:
      return state;
  }
};

const calendarEventTicketForecast = (state = 0, action) => {
  switch (action.type) {
    case CALENDAR_EVENT_TICKET_FORECAST_CHANGED:
      return action.ticketForecast;
    default:
      return state;
  }
};

const calendarEventNoteEditorState = (state = EditorState.createEmpty(), action) => {
  switch (action.type) {
    case CALENDAR_EVENT_NOTE_EDITOR_STATE_UPDATED:
      return action.noteEditorState;
    case CALENDAR_EVENT_FORM_CLOSED:
      return EditorState.createEmpty();
    default:
      return state;
  }
};

const calendarEventErrors = (state = [], action) => {
  switch (action.type) {
    case CALENDAR_EVENT_ERRORS_UPDATED:
      return action.errors;
    case CALENDAR_EVENT_FORM_CLOSED:
      return [];
    default:
      return state;
  }
};

const userChangedCalendarEventTitle = (state = false, action) => {
  switch (action.type) {
    case CALENDAR_EVENT_FORM_CLOSED:
      return false;
    case CALENDAR_EVENT_TITLE_UPDATED:
      return true;
    default:
      return state;
  }
};

const holdGroupDates = (state = [], action) => {
  switch (action.type) {
    case HOLD_GROUP_HOLD_REMOVED:
      return state.filter(date =>
        !DateUtils.isSameDay(date, action.date)
      );
    case HOLD_GROUP_CALENDAR_DATE_CLICKED:
      if (action.selected){
        return state.filter(date =>
          !DateUtils.isSameDay(date, action.date)
        );
      } else {
        return [...state, action.date];
      }
    case CALENDAR_EVENT_FORM_CLOSED:
      return [];
    case HOLD_GROUP_UPDATED:
      return action.holdGroup.hold_group_entries.map((holdGroupEntry) => {
        return new Date(offsetDateForBrowser(holdGroupEntry.hold.start_time));
      });
    default:
      return state;
  }
};

const formTitle = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const formButtonLabel = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const venuesOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const venueSelected = (state = {}, action) => {
  switch (action.type) {
    case VENUE_SELECTED_CHANGED:
      if (action.selected === null){
        return {};
      }

      var foundVenue = action.venues.find((venue) => {
        return venue.id === action.selected.value;
      })

      return Object.assign({}, foundVenue);
    default:
      return state;
  }
};

const updateSelectedVenue = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const calendarStartDate = (state = new Date(), action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const submitEventForm = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const onClose = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const calendarRef = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const onSuccess = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const selectedPromoter = (state = {}, action) => {
  switch (action.type) {
    case CALENDAR_EVENT_FORM_CLOSED:
      return {};
    case SELECTED_PROMOTER_CHANGED:
      return action.promoter;
    default:
      return state;
  }
};

const buyers = (state = [], action) => {
  switch (action.type) {
    case BUYERS_CHANGED:
      return action.buyers;
    default:
      return state;
  }
};

const promoters = (state = [], action) => {
  switch (action.type) {
    case PROMOTERS_CHANGED:
      return action.promoters;
    default:
      return state;
  }
};


const selectedBuyer = (state = {}, action) => {
  switch (action.type) {
    case CALENDAR_EVENT_FORM_CLOSED:
      return {};
    case SELECTED_BUYER_CHANGED:
      return action.buyer;
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const holdGroupDeletion = (state = {}, action) => {
  switch (action.type) {
    case CALENDAR_EVENT_FORM_CLOSED:
      return {};
    case HOLD_GROUP_DELETION_CHANGED:
      return action.holdGroupDeletion;
    case HOLD_GROUP_DELETION_REASON_CHANGED:
      return Object.assign({}, state, {
        reason: action.reason,
        explanation: ""
      });
    case HOLD_GROUP_DELETION_EXPLANATION_CHANGED:
      return Object.assign({}, state, {explanation: action.explanation});
    case HOLD_GROUP_DELETION_FOLLOW_UP_CHANGED:
      return Object.assign({}, state, {
        followUp: action.followUp,
        followUpAt: null
      });
    case HOLD_GROUP_DELETION_FOLLOW_UP_AT_CHANGED:
      return Object.assign({}, state, {followUpAt: action.followUpAt});
    default:
      return state;
  }
};

const submittingForm = (state = false, action) => {
  switch (action.type) {
    case SUBMITTING_FORM_CHANGED:
      return action.submittingForm;
    default:
      return state;
  }
};

const doorsTimeDefault = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const eventEndTimeDefault = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const userChangedDoorsTime = (state = false, action) => {
  switch (action.type) {
    case USER_CHANGED_DOORS_TIME_CHANGED:
      return action.userChangedDoorsTime;
    default:
      return state;
  }
};

const userChangedEventEndTime = (state = false, action) => {
  switch (action.type) {
    case USER_CHANGED_EVENT_END_TIME_CHANGED:
      return action.userChangedEventEndTime;
    default:
      return state;
  }
};

const onVenueChange = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const eventFormReducer = combineReducers({
  holdGroup,
  confirm,
  calendarEventTitle,
  calendarEventArtists,
  calendarEventType,
  calendarEventTicketForecast,
  calendarEventNoteEditorState,
  calendarEventErrors,
  userChangedCalendarEventTitle,
  holdGroupDates,
  formTitle,
  formButtonLabel,
  csrfToken,
  team,
  venuesOptions,
  venueSelected,
  updateSelectedVenue,
  calendarStartDate,
  submitEventForm,
  onClose,
  calendarRef,
  onSuccess,
  selectedPromoter,
  buyers,
  promoters,
  selectedBuyer,
  currentUser,
  holdGroupDeletion,
  submittingForm,
  doorsTimeDefault,
  eventEndTimeDefault,
  userChangedDoorsTime,
  userChangedEventEndTime,
  onVenueChange
});

export default eventFormReducer;
