import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Popup from "reactjs-popup";

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "260px",
  "padding": "0px"
}

const deleteModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const ConfirmActionMenu = ({
  confirm,
  csrfToken,
  team,
  currentUser,
  deleteConfirm,
  cancelConfirm,
  showAdvanceSendChanged,
  confirmInvitationChanged,
  uncancelConfirm,
  mayCancelConfirm,
  mayUncancelConfirm
}) => {
  return (
    <Popup arrow={false}
          offsetY={5}
          position="bottom right"
          contentStyle={popupContentStyle}
          onOpen={
            (e) => {
              e.stopPropagation();
              e.preventDefault();
            }
          }
          trigger={open => (
            <a className="btn btn-primary btn-sm align-self-end"
                style={{"marginBottom": "16px", "borderRadius": "8px"}}
                href="#">
              Actions
              <i className="fas fa-caret-down pl-2"></i>
            </a>
          )} >
      {close => (
        <div className='row text-left'>
          <div className="col-12">
            <ul className="list-group offer-inline-menu">
              {currentUser.can_create_invitation ? (
                <li className="list-group-item">
                  <a href={"#"}
                    onClick={
                      (e) => {
                        e.preventDefault();
                        confirmInvitationChanged({team: team});
                      }
                    }
                    className="text-muted">
                    <span className="d-inline-block text-center" style={{width: "28px"}}>
                      <i className="fas fa-share"></i>
                    </span>
                    Share this Page
                  </a>
                </li>
              ) : null}
              {currentUser.can_create_show_advance_send ? (
                <li className="list-group-item">
                  <a href="#"
                    onClick={
                      (e) => {
                        e.preventDefault();
                        showAdvanceSendChanged({calendar_event_id: confirm.id});
                      }
                    }
                    className="text-muted">
                    <span className="d-inline-block text-center" style={{width: "28px"}}>
                      <i className="fas fa-paper-plane"></i>
                    </span>
                    Send Show Advance
                  </a>
                </li>
              ) : null}
              <li className="list-group-item">
                <a href={"/teams/" + team.id + "/confirms/" + confirm.id + "/print_show_details.pdf"}
                  target={"_blank"}
                  className="text-muted">
                  <span className="d-inline-block text-center" style={{width: "28px"}}>
                    <i className="fas fa-print"></i>
                  </span>
                  Print Show Advance
                </a>
              </li>
              {confirm.calendar_classification !== "canceled" && currentUser.can_cancel_calendar_event && mayCancelConfirm ? (
                <li className="list-group-item">
                  <Popup
                      trigger={
                        <a href="#" className="text-muted">
                          <span className="d-inline-block text-center" style={{width: "28px"}}>
                            <i className="far fa-calendar-times"></i>
                          </span>
                          Cancel Event
                        </a>
                      }
                      modal
                      onOpen={
                        (e) => {
                          e.preventDefault();
                        }
                      }
                      contentStyle={deleteModalContentStyle}
                      closeOnDocumentClick
                    >
                    {closeDeletePopup => (
                      <div>
                        <div className="row">
                          <div className="col">
                            <div className="row">
                              <div className="col">
                                <h5 style={{"marginBottom": "16px"}}>
                                  <strong>Cancel this event?</strong>
                                </h5>
                              </div>
                              <div className="col-xs-auto">
                                <a href="#"
                                  className="text-dark"
                                  style={{"paddingRight": "15px"}}
                                  onClick={(e) => {e.preventDefault(); close();}} >
                                  <i className="fa fa-times"></i>
                                </a>
                              </div>
                            </div>
                            <p>Are you sure you want to cancel this event in Opendate?</p>
                            <div className="text-right">
                              <a href="#"
                                onClick={
                                  (e) => {
                                    e.preventDefault();
                                    close();
                                  }
                                }>
                                No thanks
                              </a>
                              <a href="#"
                                className="btn btn-danger ml-3"
                                onClick={
                                  (e) => {
                                    e.preventDefault();
                                    close();
                                    closeDeletePopup();
                                    cancelConfirm(csrfToken, team, confirm);
                                  }
                                }>
                                Yes, cancel
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                </li>
              ) : null}
              {confirm.calendar_classification === "canceled" && currentUser.can_cancel_calendar_event && mayUncancelConfirm ? (
                <li className="list-group-item">
                  <Popup
                      trigger={
                        <a href="#" className="text-muted">
                          <span className="d-inline-block text-center" style={{width: "28px"}}>
                            <i className="far fa-calendar"></i>
                          </span>
                          Uncancel Event
                        </a>
                      }
                      modal
                      onOpen={
                        (e) => {
                          e.preventDefault();
                        }
                      }
                      contentStyle={deleteModalContentStyle}
                      closeOnDocumentClick
                    >
                    {closeDeletePopup => (
                      <div>
                        <div className="row">
                          <div className="col">
                            <div className="row">
                              <div className="col">
                                <h5 style={{"marginBottom": "16px"}}>
                                  <strong>Uncancel this event?</strong>
                                </h5>
                              </div>
                              <div className="col-xs-auto">
                                <a href="#"
                                  className="text-dark"
                                  style={{"paddingRight": "15px"}}
                                  onClick={(e) => {e.preventDefault(); close();}} >
                                  <i className="fa fa-times"></i>
                                </a>
                              </div>
                            </div>
                            <p>Are you sure you want to uncancel this event?</p>
                            <div className="text-right">
                              <a href="#"
                                onClick={
                                  (e) => {
                                    e.preventDefault();
                                    close();
                                  }
                                }>
                                No thanks
                              </a>
                              <a href="#"
                                className="btn btn-danger ml-3"
                                onClick={
                                  (e) => {
                                    e.preventDefault();
                                    close();
                                    closeDeletePopup();
                                    uncancelConfirm(csrfToken, team, confirm);
                                  }
                                }>
                                Yes, uncancel
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                </li>
              ) : null}
              {currentUser.can_destroy_calendar_event ? (
                <li className="list-group-item delete-offer-li"
                    style={
                      confirm.calendar_classification === "canceled" ? (
                        {"borderTop": "none"}
                      ) : (
                        {}
                      )
                    }>
                  <Popup
                      trigger={
                        <a href="#" className="text-danger">
                          <span className="d-inline-block text-center" style={{width: "28px"}}>
                            <i className="fas fa-trash"></i>
                          </span>
                          Remove
                        </a>
                      }
                      modal
                      onOpen={
                        (e) => {
                          e.preventDefault();
                        }
                      }
                      contentStyle={deleteModalContentStyle}
                      closeOnDocumentClick
                    >
                    {closeDeletePopup => (
                      <div>
                        <div className="row">
                          <div className="col">
                            <div className="row">
                              <div className="col">
                                <h5 style={{"marginBottom": "16px"}}>
                                  <strong>Delete confirm?</strong>
                                </h5>
                              </div>
                              <div className="col-xs-auto">
                                <a href="#"
                                  className="text-dark"
                                  style={{"paddingRight": "15px"}}
                                  onClick={(e) => {e.preventDefault(); close();}} >
                                  <i className="fa fa-times"></i>
                                </a>
                              </div>
                            </div>
                            <p>{"Remove confirm from your Opendate account?"}</p>
                            <div className="text-right">
                              <a href="#"
                                onClick={
                                  (e) => {
                                    e.preventDefault();
                                    close();
                                  }
                                }>
                                Cancel
                              </a>
                              <a href="#"
                                className="btn btn-danger ml-3"
                                onClick={
                                  (e) => {
                                    e.preventDefault();
                                    close();
                                    closeDeletePopup();
                                    deleteConfirm(csrfToken, team, confirm);
                                  }
                                }>
                                Remove
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      )}
    </Popup>
  );
};

ConfirmActionMenu.propTypes = {
  confirm: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  deleteConfirm: PropTypes.func.isRequired,
  cancelConfirm: PropTypes.func.isRequired,
  showAdvanceSendChanged: PropTypes.func.isRequired,
  confirmInvitationChanged: PropTypes.func.isRequired,
  uncancelConfirm: PropTypes.func.isRequired,
  mayCancelConfirm: PropTypes.bool,
  mayUncancelConfirm: PropTypes.bool
};

export default ConfirmActionMenu;
