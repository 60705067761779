import PropTypes from "prop-types";
import React from "react";
import UseCSV from "@usecsv/react";
var _ = require('lodash');

const axios = require("axios").default;

const CSVImporter = ({
  csrfToken,
  team,
  importerKey,
  type,
  renderAnchor,
  onClose = () => {},
}) => {
  const bulkDataFromRows = (rows) => {
    return rows.map((row) => {
      var attributeData = {};
  
      Object.keys(row.data).map((key) => {
        attributeData[key] = row.data[key].value;
      });
  
      return {
        index: row.row,
        data: attributeData
      }
    });
  };

  const validateRows = (uploadId, rows) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.post("/teams/" + team.id + "/csv_import_rows/bulk_validate", {
        use_csv_upload_id: uploadId,
        type: type,
        csv_import_rows: {
          bulk: bulkDataFromRows(rows)
        }
      })
      .then(({ data }) => {
        return data;
      });
  };

  const debouncedValidateRows = _.debounce((uploadId, rows) => {
    validateRows(uploadId, rows);
  }, 100);

  const validateRow = (uploadId, row) => {
    return validateRows(uploadId, [row]);
  };
  
  const createRows = (uploadId, rows) => {
    var bulkData = rows.map((row) => {
      var index = row.row;
      
      delete row.row;
      
      return {
        index: index,
        data: row
      };
    });

    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.post("/teams/" + team.id + "/csv_import_rows/bulk_create", {
        use_csv_upload_id: uploadId,
        type: type,
        csv_import_rows: {
          bulk: bulkData
        }
      })
      .then(({ data }) => {
        return {errors: data};
      })
      .catch((error) => {
        alert("There was an error creating the rows. Please try again.");
      });
  };

  const useCSVTheme = {
    "global": {
      "backgroundColor": "#FFFFFF",
      "primaryTextColor": "#333333",
      "secondaryTextColor": "rgba(51,51,51,.5)",
      "successColor": "#8AC926",
      "warningColor": "#FF595E"
    },
    "buttons": {
      "primary": {
        "backgroundColor": "#1982C4",
        "color": "#FFFFFF",
        "border": "none",
        "borderRadius": "8px",
        "boxShadow": "none",
        ":hover": {
          "backgroundColor": "#156ca2",
          "color": "#FFFFFF"
        }
      },
      "secondary": {
        "backgroundColor": "#F4F9FB",
        "color": "#1982C4",
        "border": "none",
        "borderRadius": "8px",
        "boxShadow": "none",
        ":hover": {
          "backgroundColor": "#1982C4",
          "color": "#FFFFFF"
        }
      }
    }
  };

  return (
    <UseCSV importerKey={importerKey}
            theme={useCSVTheme}
            onRecordsInitial={({uploadId, rows}) => debouncedValidateRows(uploadId, rows)}
            onRecordEdit={({uploadId, row}) => validateRow(uploadId, row)}
            onData={(data) => createRows(data.uploadId, data.rows)}
            onClose={() => onClose() }
            render={(openModal) => renderAnchor(openModal)}
    />
  );
};

CSVImporter.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  importerKey: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  renderAnchor: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default CSVImporter;
