import React from "react";

import CampaignCardChart from "./CampaignCardChart";

const chartsContainerStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(140px, 140px))",
  gap: "5px",
  justifyContent: "center",
  padding: "5px",
  width: "100%",
};

const CampaignCardCharts = ({
  campaign
}) => {
  const { campaign_metrics } = campaign;
  const anyDelivered = campaign_metrics.delivered_count > 0;
  
  return (
    <div style={chartsContainerStyle}>
      <CampaignCardChart
        data={campaign_metrics.delivered}
        label="Delivered"
        value={campaign_metrics.delivered_count}
      />
      <CampaignCardChart
        data={campaign_metrics.opened}
        label="Opened"
        value={
          anyDelivered
            ? `${(campaign_metrics.opened_count / campaign_metrics.delivered_count * 100).toFixed(1)}%`
            : "0%"
          }
      />
      <CampaignCardChart
        data={campaign_metrics.clicked}
        label="Clicked"
        value={
          anyDelivered
            ? `${(campaign_metrics.clicked_count / campaign_metrics.delivered_count * 100).toFixed(1)}%`
            : "0%"
          }
      />
      <CampaignCardChart
        data={campaign_metrics.converted}
        label="Converted"
        value={
          anyDelivered
            ? `${(campaign_metrics.converted_count / campaign_metrics.delivered_count * 100).toFixed(1)}%`
            : "0%"
          }
      />
    </div>
  );
};

export default CampaignCardCharts;
