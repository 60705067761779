import { combineReducers } from 'redux';
import {
  BASIC_INFO_MENU_ITEM,
  ACTIVE_MENU_ITEM_CHANGED,
  CONFIRM_CHANGED,
  UPDATE_CONFIRM,
  PERFORMER_REMOVED,
  PERFORMER_ADDED,
  PERFORMER_UPDATED,
  PERFORMER_DRAGGED,
  PAGE_DETAIL_CREATED,
  PAGE_DETAIL_REMOVED,
  PAGE_DETAIL_CHANGED,
  PAGE_DETAIL_DRAGGED,
  TICKETING_ADMISSION_MENU_ITEM,
  ACTIVE_TICKETING_MENU_ITEM_CHANGED,
  TICKET_TYPE_FOR_FORM_CHANGED,
  TICKET_TYPE_DELETED,
  TICKET_TYPE_ADDED,
  TICKET_TYPE_UPDATED,
  TICKET_TYPE_DRAGGED,
  TICKET_TYPES_CHANGED,
  PROMO_CODE_FOR_FORM_CHANGED,
  PROMO_CODE_DELETED,
  PROMO_CODE_ADDED,
  PROMO_CODE_UPDATED,
  MAIN_EVENT_IMAGE_LOADING_CHANGED,
  ADD_ONS_CHANGED,
  ADD_ON_FOR_FORM_CHANGED,
  ADD_ON_ADDED,
  ADD_ON_DELETED,
  ADD_ON_UPDATED,
  ADD_ON_DRAGGED,
  STATEMENT_DESCRIPTOR_SUFFIX_CHANGED,
  ARTIST_BIOS_CHANGED,
  PAGE_DETAIL_TEMPLATES_CHANGED,
  PAGE_DETAIL_TEMPLATE_DELETED,
  PAGE_DETAIL_TEMPLATE_CHANGED,
  PAGE_DETAIL_TEMPLATE_ADDED,
  EXAMPLE_TICKET_CHANGED,
  IS_LOADING_DEFAULT_TICKET_TYPES_CHANGED,
  IS_EDITING_ADD_ONS_INTRO_CHANGED,
  ADD_ONS_INTRO_EDITOR_STATE_CHANGED,
  IS_LOADING_DEFAULT_ADD_ONS_CHANGED,
  IS_EDITING_ADD_ONS_ALERT_MESSAGE_CHANGED,
  ADD_ONS_ALERT_MESSAGE_EDITOR_STATE_CHANGED,
  REQUIRE_PER_TICKET_CUSTOM_CHARGE_TYPE_ADDED,
  REQUIRE_PER_TICKET_CUSTOM_CHARGE_TYPE_CHANGED,
  REQUIRE_PER_TICKET_CUSTOM_CHARGE_TYPE_DELETED,
  WARN_BEFORE_LEAVING_CHANGED,
  WARN_BEFORE_LEAVING_OPEN_CHANGED,
  WARN_BEFORE_LEAVING_CALLBACK_CHANGED,
} from '../constants/confirmTicketingConstants';

import {EditorState} from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const confirm = (state = {}, action) => {
  switch (action.type) {
    case CONFIRM_CHANGED:
      return action.confirm;
    case UPDATE_CONFIRM:
      return action.confirm;
    default:
      return state;
  }
};

const activeMenuItem = (state = BASIC_INFO_MENU_ITEM, action) => {
  switch (action.type) {
    case ACTIVE_MENU_ITEM_CHANGED:
      return action.activeMenuItem;
    default:
      return state;
  }
};

const venuesOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const performers = (state = [], action) => {
  switch (action.type) {
    case PERFORMER_REMOVED:
      return state.filter((p) => p.id !== action.performer.id);
    case PERFORMER_ADDED:
      return [...state, action.performer];
    case PERFORMER_UPDATED:
      return [...state].map((performer) => {
        if(performer.id === action.performer.id){
          return action.performer;
        } else {
          return performer;
        }
      });
    case PERFORMER_DRAGGED:
      var startIndex = action.result.source.index;
      var endIndex = action.result.destination.index;

      var stateCopy = [...state];
      var [removed] = stateCopy.splice(startIndex, 1);
      stateCopy.splice(endIndex, 0, removed);

      return stateCopy.map((performer, index) =>
        Object.assign({}, performer, {position: (index + 1)})
      );
    default:
      return state;
  }
};

const pageDetails = (state = [], action) => {
  switch (action.type) {
    case PAGE_DETAIL_CREATED:
      return [...state, action.pageDetail];
    case PAGE_DETAIL_REMOVED:
      return state.filter((pd) => pd.id !== action.pageDetail.id);
    case PAGE_DETAIL_CHANGED:
      return [...state].map((pageDetail) => {
        if(pageDetail.id === action.pageDetail.id){
          return action.pageDetail;
        } else {
          return pageDetail;
        }
      });
    case PAGE_DETAIL_DRAGGED:
      var startIndex = action.result.source.index;
      var endIndex = action.result.destination.index;

      var stateCopy = [...state];
      var [removed] = stateCopy.splice(startIndex, 1);
      stateCopy.splice(endIndex, 0, removed);

      return stateCopy.map((pageDetail, index) =>
        Object.assign({}, pageDetail, {position: (index + 1)})
      );
    default:
      if (state.some((pd) => typeof(pd.body) === "string")){
        return [...state].map((pageDetail) => {
          if(typeof(pageDetail.body) === "string"){
            var editorState = stateFromHTML(pageDetail.body);

            return Object.assign({}, pageDetail, {
              body: EditorState.createWithContent(editorState)
            });
          } else {
            return pageDetail;
          }
        });
      } else {
        return state;
      }
  }
};

const imageCalendarEventAttachments = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const activeTicketingMenuItem = (state = TICKETING_ADMISSION_MENU_ITEM, action) => {
  switch (action.type) {
    case ACTIVE_TICKETING_MENU_ITEM_CHANGED:
      return action.menuItem;
    default:
      return state;
  }
};

const ticketTypeForForm = (state = {}, action) => {
  switch (action.type) {
    case TICKET_TYPE_FOR_FORM_CHANGED:
      return action.ticketType;
    default:
      return state;
  }
};

const ticketTypes = (state = [], action) => {
  switch (action.type) {
    case TICKET_TYPE_DELETED:
      return state.filter((tt) => tt.id !== action.ticketType.id);
    case TICKET_TYPE_ADDED:
      return [...state, action.ticketType];
    case TICKET_TYPE_UPDATED:
      return [...state].map((tt) => {
        if(tt.id === action.ticketType.id){
          return action.ticketType;
        } else {
          return tt;
        }
      });
    case TICKET_TYPE_DRAGGED:
      var startIndex = action.result.source.index;
      var endIndex = action.result.destination.index;

      var stateCopy = [...state];
      var [removed] = stateCopy.splice(startIndex, 1);
      stateCopy.splice(endIndex, 0, removed);

      return stateCopy.map((ticketType, index) =>
        Object.assign({}, ticketType, {position: (index + 1)})
      );
    case TICKET_TYPES_CHANGED:
      return action.ticketTypes;
    default:
      return state;
  }
};

const mainEventImageLoading = (state = false, action) => {
  switch (action.type) {
    case MAIN_EVENT_IMAGE_LOADING_CHANGED:
      return action.mainEventImageLoading;
    default:
      return state;
  }
};

const rootUrl = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const addOns = (state = [], action) => {
  switch (action.type) {
    case ADD_ON_DRAGGED:
      var startIndex = action.result.source.index;
      var endIndex = action.result.destination.index;

      var stateCopy = [...state];
      var [removed] = stateCopy.splice(startIndex, 1);
      stateCopy.splice(endIndex, 0, removed);

      return stateCopy.map((addOn, index) =>
        Object.assign({}, addOn, {position: (index + 1)})
      );
    case ADD_ON_UPDATED:
      return [...state].map((ao) => {
        if(ao.id === action.addOn.id){
          return action.addOn;
        } else {
          return ao;
        }
      });
    case ADD_ON_DELETED:
      return state.filter((ao) => ao.id !== action.addOn.id);
    case ADD_ON_ADDED:
      return [...state, action.addOn];
    case ADD_ONS_CHANGED:
      return action.addOns;
    default:
      return state;
  }
};

const addOnForForm = (state = {}, action) => {
  switch (action.type) {
    case ADD_ON_FOR_FORM_CHANGED:
      return action.addOn;
    default:
      return state;
  }
};

const statementDescriptorSuffix = (state = "", action) => {
  switch (action.type) {
    case STATEMENT_DESCRIPTOR_SUFFIX_CHANGED:
      return action.statementDescriptorSuffix;
    default:
      return state;
  }
};

const maxDescriptorLength = (state = 0, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const artistBios = (state = {}, action) => {
  switch (action.type) {
    case ARTIST_BIOS_CHANGED:
      return action.artistBios;
    default:
      return state;
  }
};

const pageDetailTemplates = (state = [], action) => {
  switch (action.type) {
    case PAGE_DETAIL_TEMPLATES_CHANGED:
      return action.pageDetailTemplates;
    case PAGE_DETAIL_TEMPLATE_DELETED:
      return state.filter((pdt) => pdt.id !== action.pageDetailTemplate.id);
    case PAGE_DETAIL_TEMPLATE_ADDED:
      return [...state, action.pageDetailTemplate];
    default:
      return state;
  }
};

const pageDetailTemplate = (state = {}, action) => {
  switch (action.type) {
    case PAGE_DETAIL_TEMPLATE_CHANGED:
      return action.pageDetailTemplate;
    default:
      return state;
  }
};

const exampleTicket = (state = {}, action) => {
  switch (action.type) {
    case EXAMPLE_TICKET_CHANGED:
      return action.exampleTicket;
    default:
      return state;
  }
};

const seatingCharts = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const isLoadingDefaultTicketTypes = (state = false, action) => {
  switch (action.type) {
    case IS_LOADING_DEFAULT_TICKET_TYPES_CHANGED:
      return action.isLoadingDefaultTicketTypes;
    default:
      return state;
  }
};

const addOnSeatingCharts = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const isEditingAddOnsIntro = (state = false, action) => {
  switch (action.type) {
    case IS_EDITING_ADD_ONS_INTRO_CHANGED:
      return action.isEditingAddOnsIntro;
    default:
      return state;
  }
};

const addOnsIntroEditorState = (state = EditorState.createEmpty(), action) => {
  switch (action.type) {
    case ADD_ONS_INTRO_EDITOR_STATE_CHANGED:
      return action.addOnsIntroEditorState;
    default:
      return state;
  }
};

const isLoadingDefaultAddOns = (state = false, action) => {
  switch (action.type) {
    case IS_LOADING_DEFAULT_ADD_ONS_CHANGED:
      return action.isLoadingDefaultAddOns;
    default:
      return state;
  }
};

const isEditingAddOnsAlertMessage = (state = false, action) => {
  switch (action.type) {
    case IS_EDITING_ADD_ONS_ALERT_MESSAGE_CHANGED:
      return action.isEditingAddOnsAlertMessage;
    default:
      return state;
  }
};

const addOnsAlertMessageEditorState = (state = {}, action) => {
  switch (action.type) {
    case ADD_ONS_ALERT_MESSAGE_EDITOR_STATE_CHANGED:
      return action.addOnsAlertMessageEditorState;
    default:
      return state;
  }
};

const buyers = (state = [], action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const promoters = (state = [], action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const requirePerTicketCustomChargeTypes = (state = [], action) => {
  switch(action.type) {
    case REQUIRE_PER_TICKET_CUSTOM_CHARGE_TYPE_ADDED:
      return [...state, action.requirePerTicketCustomChargeType];
    case REQUIRE_PER_TICKET_CUSTOM_CHARGE_TYPE_CHANGED:
      return [...state].map((cct) => {
        if(cct.id === action.requirePerTicketCustomChargeType.id){
          return action.requirePerTicketCustomChargeType;
        } else {
          return cct;
        }
      });
    case REQUIRE_PER_TICKET_CUSTOM_CHARGE_TYPE_DELETED:
      return state.filter((cct) => cct.id !== action.requirePerTicketCustomChargeType.id);
    default:
      return state;
  }
};

const showActivities = (state = [], action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const manualTaxes = (state = [], action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const warnBeforeLeaving = (state = false, action) => {
  switch(action.type) {
    case WARN_BEFORE_LEAVING_CHANGED:
      return action.warnBeforeLeaving;
    default:
      return state;
  }
};

const warnBeforeLeavingOpen = (state = false, action) => {
  switch(action.type) {
    case WARN_BEFORE_LEAVING_OPEN_CHANGED:
      return action.warnBeforeLeavingOpen;
    default:
      return state;
  }
};

const warnBeforeLeavingCallback = (state = () => {}, action) => {
  switch(action.type) {
    case WARN_BEFORE_LEAVING_CALLBACK_CHANGED:
      return action.warnBeforeLeavingCallback;
    default:
      return state;
  }
};

const feeRuleSets = (state = [], action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const confirmTicketingReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  confirm,
  activeMenuItem,
  venuesOptions,
  performers,
  pageDetails,
  imageCalendarEventAttachments,
  activeTicketingMenuItem,
  ticketTypeForForm,
  ticketTypes,
  mainEventImageLoading,
  rootUrl,
  addOns,
  addOnForForm,
  statementDescriptorSuffix,
  maxDescriptorLength,
  artistBios,
  pageDetailTemplates,
  pageDetailTemplate,
  exampleTicket,
  seatingCharts,
  isLoadingDefaultTicketTypes,
  addOnSeatingCharts,
  isEditingAddOnsIntro,
  addOnsIntroEditorState,
  isLoadingDefaultAddOns,
  isEditingAddOnsAlertMessage,
  addOnsAlertMessageEditorState,
  buyers,
  promoters,
  requirePerTicketCustomChargeTypes,
  showActivities,
  manualTaxes,
  warnBeforeLeaving,
  warnBeforeLeavingOpen,
  warnBeforeLeavingCallback,
  feeRuleSets
});

export default confirmTicketingReducer;
