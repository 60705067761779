import React from "react";
import { LineChart, Line } from "recharts";

const chartContainerStyle = {
  alignItems: "baseline",
  display: "flex",
  flexDirection: "row",
  marginLeft: "5px",
};

const summaryContainerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  marginLeft: "5px",
};

const summaryHeaderStyle= {
  fontSize: "18px",
  fontWeight: "bold",
};

const summaryLabelStyle = {
  color: "#5C5C5C",
  fontSize: "12px",
  fontWeight: "lighter",
};

const CampaignCardChart = ({
  data,
  label,
  value,
}) => {
  const formattedData = data.map(value => {
    return {
      value: value,
    }
  });

  return (
    <div style={chartContainerStyle}>
      <div>
        <LineChart width={50} height={20} data={formattedData}>
          <Line
            animationDuration={500}
            type="monotone"
            dataKey="value"
            stroke="#94C12B"
            strokeWidth={1}
            dot={false}
          />
        </LineChart>
      </div>
      <div style={summaryContainerStyle}>
        <div style={summaryHeaderStyle}>
          {value}
        </div>
        <div style={summaryLabelStyle}>
          {label}
        </div>
      </div>
    </div>
  );
};

export default CampaignCardChart;
