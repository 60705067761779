import React, { useState } from "react";
import { ToastContainer } from "react-toastify";

import ContainerlessTableViewApp from "../../TableView/startup/ContainerlessTableViewApp";

const titleStyle = {
  color: "#333333",
  fontSize: 16,
  fontWeight: 600,
  marginBottom: 20,
};

const tabContainerStyle = {
  display: "flex",
  flexDirection: "row",
  gap: 5,
};

const tabStyle = {
  background: "transparent",
  color: "#666666",
  border: "none",
  borderRadius: 5,
  fontSize: 12,
  fontWeight: 700,
  padding: "5px 10px",
};

const selectedTabStyles = {
  background: "#DFF1FB",
  color: "#1982C4",
};

const containerStyle = {
  backgroundColor: "white",
  borderRadius: 10,
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  display: "flex",
  flexDirection: "column",
  margin: "20px 0",
  padding: 20,
};

const SegmentsDashboard = ({
  data_table_props,
}) => {
  const [selectedTab, setSelectedTab] = useState("Upcoming Events");

  const handleTab = (e, tab) => {
    e.preventDefault();
    setSelectedTab(tab);
  };

  const { dataSource, ...dataTableProps } = data_table_props;
  const tabDataSource = `${dataSource}?tab=${selectedTab}`;

  const defaultOrder = selectedTab === "Upcoming Events" ?
    [[0, "asc"]] :
    [[0, "desc"]];

  return (
    <div>
      <ToastContainer />
      <div style={titleStyle}>Segments</div>
      <div style={tabContainerStyle}>
        <button
          onClick={(e) => handleTab(e, "Upcoming Events")}
          style={{
            ...tabStyle,
            ...(selectedTab === "Upcoming Events" && selectedTabStyles),
          }}
        >
          Upcoming Events
        </button>
        <button
          onClick={(e) => handleTab(e, "Past Events")}
          style={{
            ...tabStyle,
            ...(selectedTab === "Past Events" && selectedTabStyles),
          }}
        >
          Past Events
        </button>
      </div>
      <div style={containerStyle}>
        <ContainerlessTableViewApp
          key={selectedTab}
          dataSource={tabDataSource}
          order={defaultOrder}
          {...dataTableProps}
        />
      </div>
    </div>
  );
};

export default SegmentsDashboard;
