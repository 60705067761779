import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import Tippy from '@tippyjs/react';

import VerticalInsure from './VerticalInsure';
import { createTheme } from "@vertical-insure/web-components/legacy";

import TotalsTable from './TotalsTable';

import CustomFieldValueFields from './../../CustomFieldValues/components/CustomFieldValueFields';
import {canSubmitCustomFieldValue} from './../../CustomFieldValues/actions/customFieldValuesActionCreators';

import MaskedInput from 'react-text-mask'
import {PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';
var _ = require('lodash');
const axios = require("axios").default;

// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function validateZip(zip) {
  const re = /^\d{5}(-\d{4})?$/;
  return re.test(String(zip));
}

const checkIfEmailAtTicketLimit = (csrfToken, confirm, ticketReservation, ticketTypes, setMaxTicketsPerEmailReached) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

  var hasValidEmail = (
    ticketReservation.email
      && ticketReservation.email.length > 0
      && validateEmail(ticketReservation.email)
  );

  if(!hasValidEmail){
    setMaxTicketsPerEmailReached(false);
    return;
  }

  axios.get(`/confirms/${confirm.id}/web_orders/lookup_ticket_count_by_email`, {
    params: {
      email: ticketReservation.email
    }
  })
  .then(({data}) => {
    var owned = data;
    var inCart = ticketTypes.reduce((sum, tt) => {
      return sum + parseInt(tt.quantity || 0);
    }, 0);

    setMaxTicketsPerEmailReached(
      (owned + inCart) > confirm.max_tickets_per_email
    )
  });
};
const debouncedCheckIfEmailAtTicketLimit = _.debounce(checkIfEmailAtTicketLimit, 500);

const checkIfConfirmEmailMatches = (ticketReservation, ticketReservationChanged) => {
  var confirmEmailDoesNotMatch = (
    ticketReservation
      && ticketReservation.email
      && ticketReservation.email.length > 0
      && ticketReservation.confirm_email
      && ticketReservation.confirm_email.length > 0
      && ticketReservation.email !== ticketReservation.confirm_email
  );

  var updated = Object.assign({}, ticketReservation, {confirmEmailDoesNotMatch: confirmEmailDoesNotMatch});
  ticketReservationChanged(updated);
};
const debouncedCheckIfConfirmEmailMatches = _.debounce(checkIfConfirmEmailMatches, 500);
var debouncedAutoSaveTicketReservation;

const handleVerticalInsureEvent = (
  event,
  insuranceOfferState,
  insuranceOfferStateChanged,
  ticketInsurance,
  csrfToken,
  confirm,
  ticketReservation,
  registerTicketInsurance,
  deleteTicketInsurance
) => {
  var selectedState = event.detail.selectedState;

  if(selectedState === "accepted"){
    registerTicketInsurance(csrfToken, confirm, ticketReservation, event.detail.quote);
  } else {
    deleteTicketInsurance(csrfToken, confirm, ticketInsurance);
  }

  insuranceOfferStateChanged(selectedState);
};
const debouncedHandleVerticalInsureEvent = _.debounce(handleVerticalInsureEvent, 300);

function geoCodeContactZip(zipCode, saveGeoCodedStateCallback) {
  var geocoder = new google.maps.Geocoder();

  var geocodingRequest = {
    address: zipCode,
    componentRestrictions: {
      country: 'US'
    }
  };

  geocoder.geocode(geocodingRequest, function(results, status) {
    if (status === google.maps.GeocoderStatus.OK) {
      if (results.length > 0) {
        var addressComponents = results[0].address_components;
        var component = addressComponents.find((ac) => {
          return ac.types.includes("administrative_area_level_1");
        });

        if(component){
          saveGeoCodedStateCallback(component.short_name);
        } else {
          saveGeoCodedStateCallback(null);
        }
      } else {
        // No results found
        saveGeoCodedStateCallback(null);
      }
    } else {
      // Geocoding failed due to status
      saveGeoCodedStateCallback(null);
    }
  });
}

const setContactStateCode = (csrfToken, confirm, ticketReservation, saveGeoCodedStateCode, contactZip) => {
  var validZip = (
    contactZip
      && contactZip.length > 0
      && validateZip(contactZip)
  );

  var callback = (stateCode) => {
    saveGeoCodedStateCode(csrfToken, confirm, ticketReservation, stateCode);
  };

  if(validZip){
    geoCodeContactZip(contactZip, callback);
  } else {
    callback(null);
  }
};
const debouncedSetContactStateCode = _.debounce(setContactStateCode, 500);

const CheckoutForm = ({
  team,
  csrfToken,
  confirm,
  ticketReservation,
  deleteTicketReservation,
  stripePromise,
  ticketReservationChanged,
  stripeError,
  stripeErrorChanged,
  updateTicketReservation,
  rootUrl,
  stripePaymentRequired,
  ticketTypes,
  promoCode,
  discountedTicketTypePrice,
  isPlacingOrder,
  isPlacingOrderChanged,
  addOns,
  customFieldValues,
  customFieldValueChanged,
  postToParent,
  seatsIOSelectedObjects,
  autoSaveTicketReservation,
  userContext,
  discountedAddOnPrice,
  saveGeoCodedStateCode,
  verticalInsureClientID,
  registerTicketInsurance,
  insuranceOfferState,
  insuranceOfferStateChanged,
  ticketInsurance,
  deleteTicketInsurance,
  hasInsuranceQuote,
  hasInsuranceQuoteChanged
}) => {
  const zipCodeRef = useRef(null);
  const [maxTicketsPerEmailReached, setMaxTicketsPerEmailReached] = useState(false);

  var stripe;
  var elements;

  if(stripePaymentRequired(ticketReservation)){
    stripe = useStripe();
    elements = useElements();
  }

  const [venueAgreements, setVenueAgreements] = useState(confirm.venue_ownership.venue_agreements.map((agreement) => {
    return {
      ...agreement,
      accepted: false
    }
  }));

  const zipCodeIsValid = (ticketReservation) => {
    return (
      ticketReservation.contact_zip
        && ticketReservation.contact_zip.length > 0
        && validateZip(ticketReservation.contact_zip)
    );
  }

  const canCheckout = (ticketReservation, isPlacingOrder, maxTicketsPerEmailReached) => {
    var stripeRequired = stripePaymentRequired(ticketReservation);

    return (
      (!stripeRequired || (stripeRequired && stripe && elements))
        && Object.keys(ticketReservation).length > 0
        && ticketReservation.token
        && ticketReservation.token.length > 0
        && ticketReservation.first_name
        && ticketReservation.first_name.length > 0
        && ticketReservation.last_name
        && ticketReservation.last_name.length > 0
        && ticketReservation.email
        && ticketReservation.email.length > 0
        && validateEmail(ticketReservation.email)
        && ticketReservation.email === ticketReservation.confirm_email
        && !isPlacingOrder
        && customFieldValues.every((cfv) => canSubmitCustomFieldValue(cfv)
        && venueAgreements.every((agreement) => agreement.accepted)
        && !maxTicketsPerEmailReached
      )
    );
  }

  const confirmStripePayment = (returnUrl) => {
    stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl
      },
      redirect: "if_required"
    })
    .then(function(result) {
      if (result.error) {
        isPlacingOrderChanged(false);
        stripeErrorChanged(result.error);
      } else {
        postToParent("opendateOrderPlaced", {});
        window.location.replace(returnUrl);
      }
    });
  };

  const handleSubmit = async (event) => {
    isPlacingOrderChanged(true);
    stripeErrorChanged({});

    var completeUrl = (
      rootUrl + "confirms/" + confirm.id + "/ticket_reservations/" + ticketReservation.token + "/complete"
    );

    updateTicketReservation(csrfToken, confirm, ticketReservation, (ticketReservation) => {
      if(stripePaymentRequired(ticketReservation)){
        confirmStripePayment(completeUrl);
      } else {
        postToParent("opendateOrderPlaced", {});
        window.location.replace(completeUrl);
      }
    }, customFieldValues);
  };

  const canShowInsurance = (verticalInsureClientID, confirm, ticketReservation) => {
    return (
      verticalInsureClientID
        && verticalInsureClientID.length > 0
        && confirm.offers_ticket_insurance
        && ticketReservation.contact_zip
        && ticketReservation.contact_zip.length > 0
        && validateZip(ticketReservation.contact_zip)
        && ticketReservation.contact_state_code
        && ticketReservation.contact_state_code.length > 0
        && ticketReservation.amount_for_vertical_insure
        && ticketReservation.amount_for_vertical_insure > 0
    );
  };

  const memoVerticalInsure = useMemo(() => {
    return (
      <VerticalInsure clientId={verticalInsureClientID}
                      eventStartDate={confirm.iso_start_date}
                      eventEndDate={confirm.iso_end_date}
                      state={ticketReservation.contact_state_code}
                      postalCode={ticketReservation.contact_zip}
                      insurableAmount={ticketReservation.amount_for_vertical_insure}
                      data-unique-id={ticketReservation.id}
                      theme={
                        JSON.parse(
                          createTheme({
                            colors: {
                              primary: "#1982C4",
                              secondary: "#1982C4"
                            },
                            fonts: {
                              main: "'Open Sans', Ariel, sans-serif",
                              title: "'Open Sans', Ariel, sans-serif",
                              titleSize: "18px",
                              promotional: "'Open Sans', Ariel, sans-serif",
                              promotionalSize: "16px",
                            },
                            components: {
                              container: {
                                padding: "24px"
                              }
                            }
                          })
                        )
                      }
                      onChange={
                        (event) => {
                          debouncedHandleVerticalInsureEvent(
                            event,
                            insuranceOfferState,
                            insuranceOfferStateChanged,
                            ticketInsurance,
                            csrfToken,
                            confirm,
                            ticketReservation,
                            registerTicketInsurance,
                            deleteTicketInsurance
                          );
                        }
                      }
                      onQuoteChange={
                        (event) => {
                          hasInsuranceQuoteChanged(true);

                          debouncedHandleVerticalInsureEvent(
                            event,
                            insuranceOfferState,
                            insuranceOfferStateChanged,
                            ticketInsurance,
                            csrfToken,
                            confirm,
                            ticketReservation,
                            registerTicketInsurance,
                            deleteTicketInsurance
                          );
                        }
                      }
                      onOfferStateChange={
                        (event) => {
                          debouncedHandleVerticalInsureEvent(
                            event,
                            insuranceOfferState,
                            insuranceOfferStateChanged,
                            ticketInsurance,
                            csrfToken,
                            confirm,
                            ticketReservation,
                            registerTicketInsurance,
                            deleteTicketInsurance
                          );
                        }
                      }
                      participantType="SPECTATOR" />
    );
  }, [
    verticalInsureClientID,
    ticketReservation,
    confirm,
    insuranceOfferState,
    insuranceOfferStateChanged,
    ticketInsurance,
    csrfToken,
    confirm,
    registerTicketInsurance,
    deleteTicketInsurance
  ]);

  useEffect(() => {
    debouncedAutoSaveTicketReservation = _.debounce((csrfToken, confirm, ticketReservation) => {
      autoSaveTicketReservation(csrfToken, confirm, ticketReservation);
    }, 250);
  }, []);

  useEffect(() => {
    if(Object.keys(userContext).length > 0){
      debouncedCheckIfEmailAtTicketLimit(csrfToken, confirm, ticketReservation, ticketTypes, setMaxTicketsPerEmailReached);
    }
  }, []);

  return (
    <form onSubmit={
            (e) => {
              e.preventDefault();

              var element = zipCodeRef.current.inputElement;
              var isZipCodeValid = zipCodeIsValid(ticketReservation);

              if(isZipCodeValid){
                element.classList.remove('highlight');
              } else {
                element.classList.add('highlight');
                element.focus();
              }

              if(!canCheckout(ticketReservation, isPlacingOrder, maxTicketsPerEmailReached) || !isZipCodeValid){
                return false;
              }

              handleSubmit(e);
            }
          }>
      <div className="row">
        <div className="col-12">
          <p style={{
               "fontSize": "16px",
               "marginBottom": "12px"
             }}>
            <strong>Contact Info</strong>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-row mb-3">
            <div className="col">
              <input type="text"
                     placeholder="First name"
                     style={{"border": "1px solid #e6e6e6", "fontSize": "14px"}}
                     value={ticketReservation.first_name || ""}
                     disabled={userContext.access_token}
                     onChange={
                       (e) => {
                         var updated = Object.assign({}, ticketReservation, {first_name: e.target.value});
                         ticketReservationChanged(updated);
                         debouncedAutoSaveTicketReservation(csrfToken, confirm, updated);
                       }
                     }
                     className="form-control" />
            </div>
            <div className="col">
              <input type="text"
                     placeholder="Last name"
                     style={{"border": "1px solid #e6e6e6", "fontSize": "14px"}}
                     value={ticketReservation.last_name || ""}
                     disabled={userContext.access_token}
                     onChange={
                       (e) => {
                         var updated = Object.assign({}, ticketReservation, {last_name: e.target.value});
                         ticketReservationChanged(updated);
                         debouncedAutoSaveTicketReservation(csrfToken, confirm, updated);
                       }
                     }
                     className="form-control" />
            </div>
          </div>
          <div className="form-row">
            <div className="col-6">
              <input type="email"
                     placeholder="Email address"
                     style={{"border": "1px solid #e6e6e6", "fontSize": "14px"}}
                     value={ticketReservation.email || ""}
                     disabled={userContext.access_token}
                     onChange={
                       (e) => {
                         var updated = Object.assign({}, ticketReservation, {email: e.target.value});

                         ticketReservationChanged(updated);
                         debouncedCheckIfConfirmEmailMatches(updated, ticketReservationChanged);
                         debouncedCheckIfEmailAtTicketLimit(csrfToken, confirm, updated, ticketTypes, setMaxTicketsPerEmailReached);
                         debouncedAutoSaveTicketReservation(csrfToken, confirm, updated);
                       }
                     }
                     className={"form-control " + (ticketReservation.confirmEmailDoesNotMatch || maxTicketsPerEmailReached ? "highlight" : "")} />
            </div>
            <div className="col-6">
              <input type="email"
                     placeholder="Confirm email"
                     style={{"border": "1px solid #e6e6e6", "fontSize": "14px"}}
                     value={ticketReservation.confirm_email || ""}
                     disabled={userContext.access_token}
                     onChange={
                       (e) => {
                         var updated = Object.assign({}, ticketReservation, {confirm_email: e.target.value});

                         ticketReservationChanged(updated);
                         debouncedCheckIfConfirmEmailMatches(updated, ticketReservationChanged);
                       }
                     }
                     className={"form-control " + (ticketReservation.confirmEmailDoesNotMatch ? "highlight" : "")} />
            </div>
            {maxTicketsPerEmailReached ? (
              <div className="col-12">
                <p className='mb-0 mt-1 text-danger small'>
                  <strong>{`This event has a limit of ${confirm.max_tickets_per_email} tickets per purchaser.`}</strong>
                </p>
              </div>
            ) : null}
          </div>
          <div className="form-row mt-3">
            <div className="col-6">
              <MaskedInput type="text"
                     placeholder="ZIP (required)"
                     guide={false}
                     ref={zipCodeRef}
                     mask={[/\d/,/\d/,/\d/,/\d/,/\d/]}
                     style={{"border": "1px solid #e6e6e6", "fontSize": "14px"}}
                     value={ticketReservation.contact_zip || ""}
                     onChange={
                       (e) => {
                         var contactZip = e.target.value;
                         var updated = Object.assign({}, ticketReservation, {
                           contact_zip: contactZip,
                           contact_state_code: null
                         });

                         ticketReservationChanged(updated);
                         deleteTicketInsurance(csrfToken, confirm, ticketInsurance);
                         hasInsuranceQuoteChanged(false);

                         if(zipCodeIsValid(updated)){
                           zipCodeRef.current.inputElement.classList.remove('highlight');
                         }

                         debouncedSetContactStateCode(csrfToken, confirm, updated, saveGeoCodedStateCode, contactZip);
                         debouncedAutoSaveTicketReservation(csrfToken, confirm, updated);
                       }
                     }
                     className="form-control" />
            </div>
            <CustomFieldValueFields team={team}
                              csrfToken={csrfToken}
                              customFieldValues={customFieldValues.filter((cfv) => cfv.type !== "CustomFieldValues::Address")}
                              customFieldValueChanged={customFieldValueChanged}
                              confirm={confirm} />
          </div>
        </div>
      </div>
      <CustomFieldValueFields team={team}
                              csrfToken={csrfToken}
                              customFieldValues={customFieldValues.filter((cfv) => cfv.type === "CustomFieldValues::Address")}
                              customFieldValueChanged={customFieldValueChanged}
                              confirm={confirm} />
      {canShowInsurance(verticalInsureClientID, confirm, ticketReservation) ? (
        <div className="row">
          <div className={"col-12 " + (hasInsuranceQuote ? "" : "d-none")}
               style={{"marginTop": "23px"}}>
            {memoVerticalInsure}
          </div>
        </div>
      ) : null}
      {stripePaymentRequired(ticketReservation) ? (
        <div className="row"
             style={{"marginTop": "23px"}}>
          <div className="col-12">
            <p className="mb-0 mb-3"
               style={{"fontSize": "16px"}}>
              <strong>Payment method</strong>
            </p>
          </div>
          <div className="col-12">
            {Object.keys(stripeError).length > 0 ? (
              <div className="alert alert-danger border-0 text-white"
                   style={{"background": "#FF4C00"}}
                   role="alert">
                {stripeError.message}
              </div>
            ) : null}
            <PaymentElement />
          </div>
        </div>
      ) : null}
      <div className="row"
           style={{"marginTop": "20px", "marginBottom": "20px"}}>
        <div className="col-12">
          <div className="custom-control custom-checkbox custom-checkbox-table">
            <input type="checkbox"
                   className="custom-control-input"
                   checked={ticketReservation.subscribe_to_venue || false}
                   onChange={
                     (e) => {
                       var updated = Object.assign({}, ticketReservation, {subscribe_to_venue: e.target.checked});
                       ticketReservationChanged(updated);
                     }
                   }
                   id="order-subscribe-to-venue" />
            <label className="custom-control-label"
                   style={{"fontSize": "14px"}}
                   htmlFor="order-subscribe-to-venue">
              Keep me posted about events and news from {confirm.venue.name}
            </label>
          </div>
        </div>
      </div>
      {venueAgreements.map((venueAgreement) => {
        return (
          <div 
            className="row"
            style={{"marginTop": "20px", "marginBottom": "20px"}}
            key={venueAgreement.id}
          >
            <div className="col-12">
              <div className="custom-control custom-checkbox custom-checkbox-table">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={venueAgreement.accepted}
                  onChange={
                    (e) => {
                      setVenueAgreements((current) => {
                        return current.map((currentAgreement) => {
                          currentAgreement.id === venueAgreement.id &&
                            (currentAgreement.accepted = e.target.checked);

                          return currentAgreement;
                        }
                      )});
                    }
                  }
                  id={`order-venue-agreement-${venueAgreement.id}`}
                />
                <label className="custom-control-label"
                      style={{"fontSize": "14px"}}
                      htmlFor={`order-venue-agreement-${venueAgreement.id}`}>
                  <div dangerouslySetInnerHTML={{ __html: venueAgreement.body }} />
                </label>
              </div>
            </div>
          </div>
        )
      })}
      <div className="row d-md-none">
        <div className="col"
             style={{"marginBottom": "15px"}}>
          <TotalsTable ticketTypes={ticketTypes}
                       promoCode={promoCode}
                       ticketReservation={ticketReservation}
                       addOns={addOns}
                       seatsIOSelectedObjects={seatsIOSelectedObjects}
                       discountedAddOnPrice={discountedAddOnPrice}
                       confirm={confirm}
                       ticketInsurance={ticketInsurance}
                       discountedTicketTypePrice={discountedTicketTypePrice} />
        </div>
      </div>
      <div className="row"
           style={{"paddingTop": "5px"}}>
        <div className="col d-flex align-items-center">
          <p className="mb-0"
             style={{"fontSize": "14px", "color": "#686868"}}>
            Powered by
            <a href="https://www.opendate.io/"
               target="_blank">
              <img src="/opendate_logo.png"
                   className="ml-2"
                   style={{"width": "88px"}} />
            </a>
          </p>
        </div>
        <div
          className="col text-right"
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {!isPlacingOrder ? (
            <a href="#"
               style={{"marginRight": "30px"}}
               onClick={
                 (e) => {
                   e.preventDefault();
                   postToParent("opendateOrderCanceled", {});
                   deleteTicketReservation(csrfToken, confirm, ticketReservation);
                 }
               }>
              Cancel
            </a>
          ) : null}
          <Tippy content="Checkout agreements must be accepted" disabled={venueAgreements.every((agreement) => agreement.accepted)}>
            <div>
              <button type="submit"
                      disabled={!canCheckout(ticketReservation, isPlacingOrder, maxTicketsPerEmailReached)}
                      className="btn btn-danger">
                {isPlacingOrder ? (
                  <React.Fragment>
                    <img src="/uploading-loading.gif"
                        className="mr-1"
                        style={{width: "16px"}} />
                    <strong>Placing Order...</strong>
                  </React.Fragment>
                ) : (
                  <strong>Place Order</strong>
                )}
              </button>
            </div>
          </Tippy>
        </div>
      </div>
    </form>
  );
};

CheckoutForm.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  ticketReservation: PropTypes.object,
  deleteTicketReservation: PropTypes.func.isRequired,
  stripePromise: PropTypes.object,
  ticketReservationChanged: PropTypes.func.isRequired,
  stripeError: PropTypes.object,
  stripeErrorChanged: PropTypes.func.isRequired,
  updateTicketReservation: PropTypes.func.isRequired,
  rootUrl: PropTypes.string.isRequired,
  stripePaymentRequired: PropTypes.func.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  promoCode: PropTypes.object,
  discountedTicketTypePrice: PropTypes.func.isRequired,
  isPlacingOrder: PropTypes.bool,
  isPlacingOrderChanged: PropTypes.func.isRequired,
  addOns: PropTypes.array,
  customFieldValues: PropTypes.array,
  customFieldValueChanged: PropTypes.func.isRequired,
  postToParent: PropTypes.func.isRequired,
  seatsIOSelectedObjects: PropTypes.array,
  autoSaveTicketReservation: PropTypes.func.isRequired,
  userContext: PropTypes.object,
  discountedAddOnPrice: PropTypes.func.isRequired,
  saveGeoCodedStateCode: PropTypes.func.isRequired,
  verticalInsureClientID: PropTypes.string,
  registerTicketInsurance: PropTypes.func.isRequired,
  insuranceOfferState: PropTypes.string,
  insuranceOfferStateChanged: PropTypes.func.isRequired,
  ticketInsurance: PropTypes.object,
  deleteTicketInsurance: PropTypes.func.isRequired,
  hasInsuranceQuote: PropTypes.bool,
  hasInsuranceQuoteChanged: PropTypes.func.isRequired
};

export default CheckoutForm;
