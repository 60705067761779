import React, { useEffect, useState } from "react";
import { useBroadcastEditContext } from "./BroadcastEditContainer";
import BroadcastSidebar from "./BroadcastSidebar";
import SegmentFilters from "./SegmentFilters";

import Flatpickr from "react-flatpickr";
import Select from "react-select";

import { offsetDateFromTimeZone, offsetDateToTimezone } from "../../../shared/timeZoneLogic";

var moment = require('moment-timezone');

const backButtonStyle = {
  padding: "12px 12px",
  color: "#1982C4",
  fontWeight: "bold",
};

const labelStyle = {
  color: "#808080",
  fontSize: 14,
  marginBottom: 2,
};

const timeZonePickerStyle = {
  border: "1px solid #ccc",
  borderRadius: "4px",
  padding: "8px",
  width: "100%",
};

const flatPickrFormat = "n/j/y at h:iK"

const ReviewAndScheduleTab = () => {
  const {
    team,
    tab,
    setTab,
    broadcast,
    beefreeToken,
    beefreeUid,
    template,
    setTemplate,
    subject,
    updateBroadcast,
    setSubject,
    sendDomain,
    scheduledSend,
    setScheduledSend,
    timeZone,
    setTimeZone,
    selectedSendDomain,
    setSelectedSendDomain,
    fetchAllFansCount,
    fetchSegmentFilteredFansCount,
    recipientsCount,
    setRecipientsCount,
    checkTemplateHasContent,
    templateHasContent,
    scheduledAt,
    setScheduledAt,
    scheduleBroadcast,
  } = useBroadcastEditContext();

  useEffect(() => {
    checkTemplateHasContent();

    if(broadcast.recipient_type === "all_fans") {
      fetchAllFansCount();
    } else if(broadcast.recipient_type === "conditional") {
      if(broadcast.segment_type === "existing") {
        fetchSegmentFilteredFansCount();
      } else {
        setRecipientsCount("--");
      }
    }
  }, []);

  const timeZones = moment.tz.names().map(tz => {
    return { value: tz, label: tz };
  });

  const showWarning = (
    !templateHasContent
      || recipientsCount === "--"
  );

  const canSchedule = (
    templateHasContent
      && recipientsCount !== "--"
      && scheduledAt
      && timeZone
  );

  return (
    <BroadcastSidebar>
      <div className="mt-3 ml-5">
        <h3 style={{ fontSize: "20px" }}>
          <strong>
            Review & Schedule
          </strong>
        </h3>
        <p className="">Verify that everything is set up correctly.</p>
        <div className="w-100">
          <div className="w-100">
            <div className="w-100 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                {recipientsCount === "--" ? (
                  <i className="mr-2 far fa-circle-x text-danger" />
                ) : (
                  <i className="mr-2 far fa-circle-check text-success" />
                )}
                <strong>
                  Recipients
                </strong>
              </div>
              <button
                className="btn btn-outline-primary"
                onClick={() => setTab("recipients")}
              >
                Edit
              </button>
            </div>
            {recipientsCount === "--" ? (
              <p>No recipients have been selected. Click the edit button to get started.</p>
            ) : (
                <p>Your broadcast will be sent to people who match this condition, a total of <strong>{recipientsCount}</strong> people.</p>
            )}
            <SegmentFilters editable={false} showAddButton={false} />
          </div>
          <hr className="my-4" style={{ borderColor: "#ccc" }} />
          <div className="w-100">
            <div className="w-100 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <i className="mr-2 far fa-circle-check text-success" />
                <strong>
                  From
                </strong>
              </div>
              <button
                className="btn btn-outline-primary"
                onClick={() => setTab("from")}
              >
                Edit
              </button>
            </div>
            <p>Your newsletter will be sent from:</p>
            <p>
              <strong>
                Name
              </strong>
              <br />
              {sendDomain.name}
            </p>
            <p>
              <strong>
                Email
              </strong>
              <br />
              {sendDomain.full_from_address}
            </p>
          </div>
          <hr className="my-4" style={{ borderColor: "#ccc" }} />
          <div className="w-100">
            <div className="w-100 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                {templateHasContent && subject !== null ? (
                  <i className="mr-2 far fa-circle-check text-success" />
                ) : (
                  <i className="mr-2 far fa-circle-x text-danger" />
                )}
                <strong>
                  Content
                </strong>
              </div>
              <button
                className="btn btn-outline-primary"
                onClick={() => templateHasContent || subject !== null ? setTab("content") : setTab("content-select")}
              >
                Edit
              </button>
            </div>
            <p>The following email will send to recipients.</p>
            <div
              onClick={() => templateHasContent || subject !== null ? setTab("content") : setTab("content-select")}
              className="p-3 d-flex align-items-center rounded-lg"
              style={{
                maxWidth: "400px",
                border: "1px solid #e6e6e6",
                cursor: "pointer",
              }}
            >
              <i className="mr-3 far fa-envelope" />
              <strong>
                {broadcast.name}
              </strong>
              {!templateHasContent ? (
                <span
                  className="ml-3 px-2 py-1"
                  style={{
                    fontSize: "12px",
                    backgroundColor: "#e6e6e6"
                  }}
                >
                  <strong>No content</strong>
                </span>
              ) : null}
              {subject === null ? (
                <span
                  className="ml-3 px-2 py-1"
                  style={{
                    fontSize: "12px",
                    backgroundColor: "#e6e6e6"
                  }}
                >
                  <strong>No subject</strong>
                </span>
              ) : null}
            </div>
            {!templateHasContent ? (
              <div
                className="px-3 py-2 text-danger rounded-lg mt-3"
                style={{
                  color: "#8a0005",
                  backgroundColor: "#ffdbdc"
                }}
              >
                Add content to your broadcast.
              </div>
            ) : null}
            {subject === null ? (
              <div
                className="px-3 py-2 text-danger rounded-lg mt-3"
                style={{
                  color: "#8a0005",
                  backgroundColor: "#ffdbdc"
                }}
              >
                Add a subject to your broadcast.
              </div>
            ) : null}
          </div>
        </div>
        <hr className="my-4" style={{ borderColor: "#ccc" }} />
        <h3 className="mt-2" style={{ fontSize: "20px" }}>
          <strong>
            Schedule
          </strong>
        </h3>
        <p>When should we send this email?</p>
        <div className="row">
          <div className="col-4">
            <div className="mb-3">
              <div className="d-flex justify-content-between" style={labelStyle}>
                <span>Date / Time</span>
                <span>Required</span>
              </div>
              <div className="flatpickr-date-input px-2 d-flex align-items-center">
                <div className="pr-2" style={{
                  fontSize: "14px",
                  color: "#1982C4",
                }}>
                  <i className="far fa-calendar"></i>
                </div>
                <Flatpickr
                  key={`${timeZone}`}
                  options={{
                    enableTime: true,
                    dateFormat: flatPickrFormat,
                    minDate: new Date(),
                    timeZone: timeZone,
                  }}
                  placeholder={"Select date"}
                  value={scheduledAt}
                  onClose={dates => {
                    setScheduledAt(offsetDateToTimezone(dates[0], timeZone));
                  }}
                />
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-between" style={labelStyle}>
                <span>Timezone</span>
                <span>Required</span>
              </div>
              <Select
                style={timeZonePickerStyle}
                options={timeZones}
                value={timeZones.find(option => option.value === timeZone)}
                onChange={option => setTimeZone(option.value)}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.value}
              />
            </div>
          </div>
          {showWarning ? (
            <div className="col-4">
              <div
                className="p-3 rounded-lg"
                style={{
                  maxWidth: "400px",
                  backgroundColor: "#eff7fd",
                }}
              >
                <strong>
                  We're not able to send just yet...
                </strong>
                <p>Review and fix your newsletter before sending.</p>
              </div>
            </div>
          ) : null}
        </div>
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "250px" }}
        >
          <a
            href="#"
            style={backButtonStyle}
            onClick={e => {
              e.preventDefault();
              setTab("content-select")
            }}
          >
            Back
          </a>
          <button
            disabled={!canSchedule}
            type="button"
            className="ml-2 btn btn-primary"
            onClick={e => {
              e.preventDefault();

              scheduleBroadcast(() => {
                window.location = `/teams/${team.id}/broadcasts/${broadcast.id}/edit?tab=content`;
              });
            }}
          >
            Schedule
          </button>
        </div>
      </div>
    </BroadcastSidebar>
  );
};

export default ReviewAndScheduleTab;
